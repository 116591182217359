@import "../../assets/app.scss";

.events-main-container {
    .events-title {
        h2 {
            color: $jclub-grey;
            text-align: center;
            font-size: 24px;
            font-family: 'Times New Roman', Times, serif;
            letter-spacing: 2px;
            margin: 0px;
            padding: 0px;
            padding: 90px 0px 50px 0px;

            @include mobile {
                padding: 50px 0px 0px 0px;
                font-size: 20px;
            }
        }
    }
}

.upcoming-events {
    @include mobile {
        padding: 2rem 1rem 2rem 1rem;
    }

    padding: 0rem 5rem 5rem 5rem;

    .slick-prev {
        @include mobile {
            left: -30px;
        }

        // left: 12px;
        // top: 35%;

        img {
            width: 30px;
        }
    }

    .slick-next {
        @include mobile {
            right: -30px;
        }

        // top: 35%;
        right: -31px;

        img {
            width: 30px;
            transform: scaleX(-1);
        }
    }

    .upcoming-events-slider {
        padding: 20px 5px;
      
        @include mobile {
          padding: 0px 5px 5px 5px;
        }
      
        @include tablet {
          padding: 20px 5px;
        }
      
        .profile {
          .blog-title {
            h3 {
              @include mobile {
                font-size: 14px !important;
              }
      
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
      
          .cursor-none {
            cursor: auto;
          }
      
          .events-bg-image {
            width: 100%;
      
            @include mobile {
              height: 300px;
            }
      
            @include tablet {
              height: 300px;
            }
      
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
      
          .card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 0px;
            border-bottom: 2px solid #c8d0d3;
            border-left: 2px solid #c8d0d3;
            border-right: 2px solid #c8d0d3;
      
            @include mobile {
              border: none;
            }
      
            .mobile-title {
              color: $jclub-navy;
              text-align: center;
              font-size: 10px;
              font-weight: 700;
              background: #fff;
              width: max-content;
              padding: 0.5rem 2rem;
              margin: -22px auto 2rem;
      
              @include mobile {
                padding: 13px 0px 0px 0px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
      
              @include tablet {
                padding: 7px 0px 0px 0px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
      
              margin: 0px;
            }
      
            .title {
              margin: 0;
              font-weight: 600;
              text-align: center;
              font-family: Arial, Helvetica, sans-serif;
              color: $jclub-navy;
              background: white;
              padding: 9px 10px;
              font-size: 16px;
              letter-spacing: 0.5px;
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              height: 60px;
              -webkit-box-orient: vertical;
              @include mobile{
                height: 55px;
              }
            }
      
            .border-bottom {
              width: 13%;
              margin: auto;
              padding: 0px;
              border-bottom: 2px solid #c8d0d3 !important;
              position: relative;
              bottom: 15px;
            }
      
            @include mobile {
              .mobile_final_description {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
      
              .desktop_final_description {
                display: none;
              }
            }
      
            @include tablet {
              .mobile_final_description {
                display: none;
              }
      
              .desktop_final_description {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
      
            .description {
              text-align: center;
              font-family: Arial, Helvetica, sans-serif;
              color: $jclub-navy;
              font-size: 13px;
              padding: 0px 20px 0px 20px;
              letter-spacing: 0.3px;
              line-height: 1.6;
              height: 45px;
            
      
              @include mobile {
                height: 40px;
                padding: 0px 20px 0px 20px !important;
              }
      
              @include tablet {
                height: 45px;
              }
            }
      
            .main-link {
              margin-top: auto; /* Ensures the button is at the bottom */
              padding: 0px 10px 7px 10px;
      
              .info-link,
              .info-link-single,
              .book-link {
                width: 100%;
                display: inline-block;
                padding: 0px;
                margin: 0px;
                text-align: center;
                font-weight: 600;
                font-family: Arial;
                border-top: 2px solid #c8d0d3;
      
                p {
                  padding: 0px;
                  margin: 0px;
                  position: relative;
                  top: 3px;
                  padding: 4px 0px;
                }
              }
            }
          }
        }
      }
      

}


@media screen and (min-width:300px) and (max-width:1100px) {

    .personal-slider-main {
        .personal-slider {
            padding: 0.5em;
        }

        //  padding: 0px !important;
    }

}

@media screen and (min-width:300px) and (max-width:821px) {
    .personal-trainer-main {
        padding: 55px 35px 30px 35px !important;
    }

    .personal-trainer {
        .title {
            font-size: 18px !important;
        }

        .filter-label {
            font-size: 14px !important;
        }
    }

    .mobile-filter-adjust {
        text-align: center;
        padding: 0px 0px 34px 0px;

        select {
            margin: 15px 0px 35px 0px !important;
            width: 35% !important;
            height: 24px !important;
        }
    }
}