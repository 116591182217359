@import "../../assets/app.scss";
.member{
    // max-width: 850px;
    width: 100%;
    // border-bottom: 1px solid #e7e7e9;
    // border-top: 1px solid #e7e7e9;
    font-family: 'Times New Roman', Times, serif;
    .member-button{
        width: 100%;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        border: none;
        background-color: transparent;
        background: #0E2538;
        color: $jclub-grey;
        line-height: 2rem;
        letter-spacing: 1px;
        font-size: 20px;
        cursor: pointer;
        // padding: 18px 0;
        .Button-Title{
            display: flex;
            justify-content: center;
            width: inherit;
            font-size: 16px;
        }
        .arrow-icon-down{
            color: $jclub-grey;
            font-size: 30px;
            @include mobile{
                font-size: 20px;
            }
        }
        .arrow-icon-up{
            color: $jclub-grey;
            font-size: 30px;
            transform: rotate(180deg);
            @include mobile{
                font-size: 20px;
            }
        }
        &::after {
            // content: url(../../images/icon-arrow-down.svg);
            color:darkblue;
            // margin-right: 17px;
            transform: rotate(180deg);
          }
        @include mobile{
            font-size: 15px;
        }
    }
    .font-weight-bold {
        // font-weight: 700;
        &::after {
            // content: url(../../images/icon-arrow-down.svg);
            // margin-right: 17px;
            transform: rotate(0deg);
          }
    }
    .member-desc{
        // margin-left: -40px;
        // padding-right: 17px;
        margin-top: 0;
        margin-bottom: 0;
        height: 0;
        overflow: hidden;
        transition: all .2s ease-out;
        .star-info{
            text-align: center;
            font-size: 12px;
            margin-bottom: 0;
            margin-top: 10px;
        }
    }
    .show-description {
        height: auto;
        margin-bottom: 15px;
        border-top: 1px solid #e7e7e9;
        .odd-item{
            background: #abb8bd8c;
        }
        .item-gradient{
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 10px;
            .item-gradient-title{
                background-image: linear-gradient($jclub-grey, white);
                width: -webkit-fill-available;
                text-align: center;
                font-size: 16px;
                padding-top: 20px;
                padding-bottom: 20px;
                padding-right:20px;
                // font-size: medium;
            }
            .item-gradient-list-title{
                margin-bottom: 0;
                padding-right:20px;
            }
        }
        .member-item{
            display: grid;
            grid-template-columns: 0.95fr 1fr;
            align-items: center;
            line-height: 2rem;
            padding:1px 6px;
            // padding: 0px 30px 0px 0px;
            @include mobile{
                padding-right: 0px;
                line-height: 1.5rem;
            }
            @include tablet{
                padding-right: 40px;
                line-height: 1.5rem;
                grid-template-columns: 1fr 1fr;
            }
            @include desktop{
                padding-right: 82px;
                line-height: 2rem;
                grid-template-columns: 1fr 1fr;
            }
            .member-item-title{
                font-size: 14px;
                letter-spacing: 0.2px;
                font-family: Arial, Helvetica, sans-serif;
                @include mobile{
                    font-size: 12px;
                    letter-spacing: 0px;
                }
            }
            .member-item-list{
                display: flex;
                justify-content: space-between;
                @include mobile{
                    padding: 0px 30px;
                }

                .circle-fill{
                    background: darkgrey;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    border: 2px solid ;
                }
                .circle-empty{
                    background: transparent;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    border: 2px solid ;
                }
            }
        }
    }
}