/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
@media (min-width: 1440px) {
  .col-xll-4 {
    flex: 0 0 25%;
    max-width: 25%; } }

@media (max-width: 767px) {
  .mobile-previous-events {
    display: block; }
  .desktop-events-filter {
    display: none; } }

@media (min-width: 768px) {
  .mobile-previous-events {
    display: none; }
  .desktop-events-filter {
    display: block; } }

.previous-events-main-container {
  padding: 55px 31px 46px 31px; }
  @media (max-width: 767px) {
    .previous-events-main-container {
      padding: 0px 31px 46px 31px; } }
  @media (max-width: 767px) {
    .previous-events-main-container .previous-events-filter {
      padding: 20px 0px 35px 0px; } }
  .previous-events-main-container .previous-events-filter .title {
    color: #0E2538;
    text-align: center;
    font-size: 24px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .previous-events-main-container .previous-events-filter .title {
        font-size: 20px; } }
    @media (min-width: 768px) {
      .previous-events-main-container .previous-events-filter .title {
        font-size: 24px; } }
  .previous-events-main-container .previous-events-filter .filters .filter-label {
    font-size: 14px; }
    @media (max-width: 767px) {
      .previous-events-main-container .previous-events-filter .filters .filter-label {
        font-size: 12px; } }
    @media (min-width: 768px) {
      .previous-events-main-container .previous-events-filter .filters .filter-label {
        font-size: 14px; } }
  .previous-events-main-container .previous-events-filter .filters span {
    color: #0E2538;
    font-weight: 600; }
  .previous-events-main-container .previous-events-filter .filters select {
    display: inline;
    height: 24px;
    padding: 0px 7px;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 400;
    box-shadow: none;
    color: #abb8bd; }
    @media (min-width: 992px) {
      .previous-events-main-container .previous-events-filter .filters select {
        width: 35%; } }
    @media (min-width: 1200px) {
      .previous-events-main-container .previous-events-filter .filters select {
        width: 30%; } }

.no-filter-date {
  margin: auto; }

.mobile-previous-events {
  text-align: center; }
  .mobile-previous-events .title {
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px;
    color: #0E2538;
    text-transform: uppercase; }
  .mobile-previous-events .filter-label {
    color: #0E2538; }
    @media (max-width: 767px) {
      .mobile-previous-events .filter-label {
        padding: 10px 0px 5px 0px; } }
    @media (min-width: 768px) {
      .mobile-previous-events .filter-label {
        padding: 10px 0px; } }
  .mobile-previous-events .select-date {
    color: #abb8bd;
    width: 65%;
    margin: auto;
    border-radius: 8px;
    height: 34px;
    box-shadow: none;
    font-size: 13px; }

.previous-all-events {
  padding: 2rem 5rem 2rem 5rem; }
  @media (max-width: 767px) {
    .previous-all-events {
      padding: 0px; } }
  .previous-all-events .previous-all-events-slider {
    padding: 20px 5px; }
    @media (max-width: 767px) {
      .previous-all-events .previous-all-events-slider {
        padding: 20px 5px 5px 5px; } }
    @media (min-width: 768px) {
      .previous-all-events .previous-all-events-slider {
        padding: 20px 5px; } }
    .previous-all-events .previous-all-events-slider .profile .events-bg-image {
      width: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover; }
      @media (max-width: 767px) {
        .previous-all-events .previous-all-events-slider .profile .events-bg-image {
          height: 145px; } }
      @media (min-width: 768px) {
        .previous-all-events .previous-all-events-slider .profile .events-bg-image {
          height: 190px; } }
    .previous-all-events .previous-all-events-slider .profile .card-body {
      padding: 0px;
      border-bottom: 2px solid #c8d0d3;
      border-left: 2px solid #c8d0d3;
      border-right: 2px solid #c8d0d3; }
      @media (max-width: 767px) {
        .previous-all-events .previous-all-events-slider .profile .card-body {
          border: none; } }
      .previous-all-events .previous-all-events-slider .profile .card-body .mobile-title {
        color: #0E2538;
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        margin: 0px; }
        @media (max-width: 767px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .mobile-title {
            padding: 13px 0px 0px 0px;
            display: block; } }
        @media (min-width: 768px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .mobile-title {
            padding: 7px 0px 0px 0px;
            display: none; } }
      .previous-all-events .previous-all-events-slider .profile .card-body .title {
        margin: 0;
        font-weight: 600;
        text-align: center;
        margin: 0;
        font-family: Arial, Helvetica, sans-serif;
        color: #0E2538;
        background: white;
        padding: 9px 0px;
        position: relative;
        top: -18px;
        left: 19%;
        font-size: 12px;
        letter-spacing: 0.5px;
        width: 61%; }
      .previous-all-events .previous-all-events-slider .profile .card-body .border-bottom {
        width: 13%;
        margin: auto;
        padding: 0px;
        border-bottom: 2px solid #c8d0d3 !important;
        position: relative;
        bottom: 15px; }
      @media (max-width: 767px) {
        .previous-all-events .previous-all-events-slider .profile .card-body .mobile_final_description {
          display: block; }
        .previous-all-events .previous-all-events-slider .profile .card-body .desktop_final_description {
          display: none; } }
      @media (min-width: 768px) {
        .previous-all-events .previous-all-events-slider .profile .card-body .mobile_final_description {
          display: none; }
        .previous-all-events .previous-all-events-slider .profile .card-body .desktop_final_description {
          display: block; } }
      .previous-all-events .previous-all-events-slider .profile .card-body .description {
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        color: #0E2538;
        font-size: 13px;
        padding: 0px 20px 0px 20px;
        letter-spacing: 0.3px;
        line-height: 1.6;
        height: 60px; }
        @media (max-width: 767px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .description {
            height: 40px; } }
        @media (min-width: 768px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .description {
            height: 60px; } }
      .previous-all-events .previous-all-events-slider .profile .card-body .events {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
        color: #abb8bd;
        text-align: center;
        letter-spacing: 0.5px;
        font-size: 15px;
        text-transform: uppercase;
        padding: 12px 0px 0px 0px; }
      .previous-all-events .previous-all-events-slider .profile .card-body .events-showdate {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
        color: #abb8bd;
        text-align: center;
        letter-spacing: 0.5px;
        font-size: 15px;
        text-transform: uppercase;
        padding: 12px 0px 0px 0px; }
        @media (max-width: 767px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .events-showdate {
            display: none; } }
        @media (min-width: 768px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .events-showdate {
            display: block; } }
        @media (max-width: 767px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .events-showdate {
            padding: 12px 0px 0px 0px; } }
        @media (min-width: 768px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .events-showdate {
            padding: 12px 0px 12px 0px; } }
      .previous-all-events .previous-all-events-slider .profile .card-body .main-link {
        padding: 0px 10px 7px 10px; }
        .previous-all-events .previous-all-events-slider .profile .card-body .main-link .info-link {
          width: 100%;
          display: inline-block;
          padding: 0px;
          margin: 0px;
          text-align: center;
          font-weight: 600;
          font-family: Arial;
          border-top: 2px solid #c8d0d3; }
          .previous-all-events .previous-all-events-slider .profile .card-body .main-link .info-link p {
            padding: 0px;
            margin: 0px;
            position: relative;
            top: 3px;
            padding: 4px 0px; }
        .previous-all-events .previous-all-events-slider .profile .card-body .main-link .book-link {
          display: inline-block;
          padding: 0px;
          margin: 0px;
          text-align: center;
          font-weight: 600;
          font-family: Arial;
          border-top: 2px solid #c8d0d3 !important; }
          .previous-all-events .previous-all-events-slider .profile .card-body .main-link .book-link p {
            padding: 0px;
            margin: 0px;
            position: relative;
            top: 3px;
            padding: 4px 0px; }
      @media (max-width: 767px) {
        .previous-all-events .previous-all-events-slider .profile .card-body .title {
          display: block; }
        .previous-all-events .previous-all-events-slider .profile .card-body .border-bottom {
          display: block; }
        .previous-all-events .previous-all-events-slider .profile .card-body .description {
          font-size: 11px;
          letter-spacing: 0;
          line-height: 1.3;
          padding: 3px 8px 0px 8px; }
        .previous-all-events .previous-all-events-slider .profile .card-body .date {
          display: none; }
        .previous-all-events .previous-all-events-slider .profile .card-body .main-link {
          padding: 0px 10px 7px 10px; }
          .previous-all-events .previous-all-events-slider .profile .card-body .main-link .info-link {
            border-top: none; }
            .previous-all-events .previous-all-events-slider .profile .card-body .main-link .info-link p {
              padding: 0px 0px;
              font-size: 10px;
              top: 0px;
              color: #abb8bd; } }

@media screen and (min-width: 300px) and (max-width: 821px) {
  .mobile-filter-adjust-event {
    text-align: center;
    padding: 0px 0px 34px 0px; } }
  @media screen and (min-width: 300px) and (max-width: 821px) and (min-width: 768px) {
    .mobile-filter-adjust-event {
      padding: 0px 0px 15px 0px; } }
  @media screen and (min-width: 300px) and (max-width: 821px) and (min-width: 992px) {
    .mobile-filter-adjust-event {
      padding: 0px 0px 34px 0px; } }

@media screen and (min-width: 300px) and (max-width: 821px) {
    .mobile-filter-adjust-event select {
      width: 35% !important;
      height: 24px !important; } }
    @media screen and (min-width: 300px) and (max-width: 821px) and (min-width: 768px) {
      .mobile-filter-adjust-event select {
        margin: 15px 0px 0px 0px !important; } }
    @media screen and (min-width: 300px) and (max-width: 821px) and (min-width: 992px) {
      .mobile-filter-adjust-event select {
        margin: 15px 0px 35px 0px !important; } }
