@import "../../assets/app.scss";
.news-info-section{
    @include desktopsmall{
        zoom: 0.850;
     }
    @include desktop{
        zoom: 0.850;
     }
    @include desktoplarge{
        zoom: 1;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #0d2233;
    padding: 2.5rem 0 2.5rem 0;
}
.news-info-title{
    font-size: 24px;
    text-transform: uppercase;
    color: $jclub-grey;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 4rem 4rem 1rem 4rem;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px;
}
.news-home{
    background: #abb8bd6e;
    padding: 90px 0px;
  .container-fluid{
    width: 90%; 
    .left-banner{
        padding: 0px 8px 0px 0px;
        .news-banner{
            width: 100%;
            @include tablet{
                height: 340px;
            }
            @include desktopsmall{
                height: 500px;
            }
            @include desktop{
                height: 570px; 
            }
            @include desktoplarge{
                height: 625px;
            }
            background-repeat: no-repeat;
            background-position: bottom;
            transition: all 1000ms ease;
            background-size: cover;
        }
        .description{
             position: relative;
             @include tablet{
                top: 75%;
             }
             @include desktopsmall{
                 top: 80%;
             }
             @include desktoplarge{
                top: 81%;
            }
             top: 81%;
            
             .title{
                text-align: center;
                color: white;
                @include tablet{
                    font-size: 14px;
                }
                @include desktopsmall{
                    font-size: 17px;
                }
                @include desktop{
                    font-size: 18px;
                }
                @include desktoplarge{
                    font-size: 21px;
                }
                letter-spacing: 1px;
                text-transform: uppercase;
                font-family: 'Times New Roman', Times, serif;
            }
            .date{
                font-size: 12px;  
                color: white;    
                text-align: center;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 600;
            }
        }

    }
    .right-banner{
        padding: 0px 0px 0px 5px;
        .news-banner{
            width: 100%;
            @include tablet{
                height: 165px;
            }
            @include desktopsmall{
                height: 244px;
            }
            @include desktopsmall{
                height: 244px;
            }
            @include desktop{
                height: 280px; 
            }
            @include desktoplarge{
                height: 307px;
            }
            background-repeat: no-repeat;
            background-position: center center;
            transition: all 1000ms ease;
            background-size: cover;
        }
        .description{
            position: relative;
            @include tablet{
                top: 50%;
            }
            @include desktopsmall{
                 top: 64%;
            }
            @include desktoplarge{
                top: 64%;
            }
            .title{
                text-align: center;
                color: white;
                @include tablet{
                    font-size: 14px;
                }
                @include desktopsmall{
                    font-size: 17px;
                }
                @include desktop{
                    font-size: 18px;
                }
                @include desktoplarge{
                    font-size: 21px;
                }
                letter-spacing: 1px;
                text-transform: uppercase;
                font-family: 'Times New Roman', Times, serif;
            }
            .date{
                font-size: 12px;  
                color: white;    
                text-align: center;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 600;
            }
        }
        
        .second-image-padding{
            padding: 10px 15px;
        }
    }
  }  
}