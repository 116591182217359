@import "../../assets/app.scss";

.policy-container{
    padding: 4em 4em;
    @include mobile{
        padding: 1.5em;
    }
    @include tablet{
        padding: 4em 4em;
    }
    background-color: $jclub-navy;
     .main-description{
        font-family: 'Times New Roman', Times, serif;
        color: $jclub-grey;
        .child-head{
            padding: 15px 0px;
        }
         .title{
            color: $jclub-grey;
            font-family: 'Times New Roman', Times, serif;
            letter-spacing: 2.3px;
            font-size: 22px;
            text-transform: uppercase;
            a{
                color: $jclub-grey;
                text-decoration: none;
            }
        }
        .description{
            color: white;
            letter-spacing: 0.5px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 16px;
            line-height: 24px;
            .specify-text{
                text-decoration: underline;
            }
        }
        .description-list{
            padding: 0px;
            list-style: none;

            li{
                margin: 0;
                margin-bottom: 0.5em;
                padding-left: 1.5em;
                position: relative;
                color: white;
                font-size: 16px;
                font-family: Arial, Helvetica, sans-serif;
                
                &:after {
                  content: '';
                  height: .4em;
                  width: .4em;
                  background: white;
                  display: block;
                  position: absolute;
                  transform: rotate(45deg);
                  top: 5px;
                  left: 0;
                  font-size: 22px;
                }
            }
         }
    }
}



 #CookieReportsAccordion{
     width: 102%;
     #wscrAccordionInjectContent{
        span{
           color: $jclub-grey !important;
           font-size: 12px !important;
        }
       a{
           color: $jclub-grey !important;
           font-size: 12px;
           span{
               color: $jclub-grey !important;
           }
       }
    }
     #CookieReportsAccordionSections{
            .CookieReportsAccordionHeading{
                outline: none;
            }
            .wscrSectionCount{
                min-width: 25px;
                @include mobile{
                    min-width: 12px;
                }
            }
            .wscrSectionTitle{
                padding: 0px;
                .wscrSectionTitleText{
                    color:$jclub-navy;
                    letter-spacing: 1.5px;
                    font-size: 19px;
                    @include mobile{
                        font-size: 12px;
                    }
                    @include tablet{
                        font-size: 18px;
                    }
                    @include desktop{
                        font-size: 19px;
                    }
                    cursor: pointer;
                    font-family: 'Times New Roman', Times, serif;
                    text-transform: uppercase;
                }
            }
            .wscrSectionTitleConsent{
               .wscrSwitchParts{
                    border: 2px solid #0E2538;
                    .wscrSwitchLabel:before {
                        padding-left: 10px;
                        background-color: white;
                        color: #0E2538;
                        border: none;
                    }
                    .wscrSwitchLabel:after {
                        content: "\2716";
                        padding-right: 10px;
                        background-color: white;
                        text-align: right;
                        color: #0E2538;
                    }
               } 
                .wscrSwitchToggle{
                    background: #0E2538;
                }
            }
            dd{
                .wscrSectionDescription{
                    font-family: Arial, Helvetica, sans-serif;
                    padding: 11px 14px;
                    color: white;
                    font-size: 14px; 
                    p{
                        font-size: 14px;
                        font-family: Arial, Helvetica, sans-serif;
                        color: white;
                        letter-spacing: 1px;
                        line-height: 1.5;                    
                    }
                }
                .CookieReportsAccordionCookies{
                     p{
                        text-align: center;
                        padding: 0px 0px 18px 0px;
                        font-size: 14px;
                        font-family: Arial, Helvetica, sans-serif;
                        color: white;
                        letter-spacing: 1px;
                        line-height: 1.5;                    
                    }
                }
                .CookieReportsAccordionCookies{
                    table{
                        th{
                            border-bottom: 1px solid #ccc;
                            font-size: 14px;
                            font-family: Arial, Helvetica, sans-serif;
                            text-transform: uppercase;
                        }
                        td{
                            font-family: Arial, Helvetica, sans-serif;
                            font-size: 14px;
                            @include mobile{
                                    font-size: 10px;
                            }
                            @include tablet{
                                font-size: 14px;
                            }                        
                        }
                        .wscrField-description{
                            font-size: 12px;
                            @include mobile{
                                padding: 10px 0px 20px 0px;
                            }
                            @include tablet{
                                padding: 10px 10px 20px 10px;
                            }
                            .wscrCompanyPrivacy{
                                li{
                                    a{
                                        color: $jclub-grey;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        

    }
}

@include mobile{
    .wscrSwitch{
        zoom: 0.7;
        position: relative;
        left: 29px;
    }
}