@import "../../assets/app.scss";

.location-slider{
    @include desktopsmall{
        zoom: 0.880;
     }
    @include desktop{
        zoom: 0.880;
     }
    @include desktoplarge{
        zoom: 1;
    }      
            .accordion {
                width: 100%;
                height: 550px;
                overflow: hidden;
            }
            
            .accordion ul {
                width: 100%;
                display: table;
                table-layout: fixed;
                margin: 0;
                padding: 0;
            }

            .accordion ul li {
                display: table-cell;
                vertical-align: bottom;
                position: relative;
                width: 16.666%;
                height: 550px;
                background-repeat: no-repeat;
                background-position: center center;
                transition: all 1000ms ease;
                background-size: cover;
            }
            
           
            
            .accordion ul li div a {
                display: block;
                height: 550px;
                // width: 100%;
                position: relative;
                z-index: 3;
                // vertical-align: bottom;
                padding: 15px 20px;
                box-sizing: border-box;
                color: #fff;
                text-decoration: none;
                font-family: Open Sans, sans-serif;
                transition: all 200ms ease;
            }
            
            .accordion ul li div a * {
                opacity: 0;
                margin: 0;
            }
            
            .accordion ul li div a {
                .slider-description{
                    font-family: "Times New Roman", Times, serif;
                    text-overflow: clip;
                    text-transform: uppercase;
                    margin-bottom: 2px;
                    top: 40%;
                    position: relative;
                    .title{
                        font-size: 24px;
                        text-align: center;
                        letter-spacing: 2.5px;
                    }
                    .discover-more{
                        background: $jclub-grey;
                        padding: 4px 8px;
                        text-align: center;
                        border-radius: 6px;
                        text-transform: uppercase;
                        font-size: 16px;
                    }
                    .discover-more-button{
                        text-align: center;
                    }
                }
            }
            
            .accordion ul li div a p {
                top: 160px;
                font-size: 13.5px;
            }
            
            
            .accordion ul:hover li { width: 12%; }
            
            .accordion ul:hover li:hover { width: 60%; }
            
            .accordion ul:hover li:hover a { background: rgba(2,6,9, 0.8); }
            
            .accordion ul:hover li:hover a * {
                opacity: 1;
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }

            @media screen and (max-width: 600px) {

                .accordion ul li a { 
                    opacity: 1;
                    -webkit-transform: translateX(0);
                    transform: translateX(0);
                    background: rgba(2,6,9, 0.6);
                    .discover-more-button{
                        display: none;
                    }
                }
                .accordion ul:hover li:hover a { background: rgba(2,6,9, 0.6); }
                .accordion ul:hover li:hover a * {
                    opacity: 1;
                    -webkit-transform: translateX(0);
                    transform: translateX(0);
                }

                .accordion ul li div a * {
                    opacity: 1;
                    margin: 1;
                }
            
                    .accordion { height: auto; }
            
                    .accordion ul li,
                    .accordion ul li:hover,
                    .accordion ul:hover li,
                    .accordion ul:hover li:hover {
                        position: relative;
                        display: table;
                        table-layout: fixed;
                        width: 100%;
                        -webkit-transition: none;
                        transition: none;
                    }
            }
}