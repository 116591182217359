@import "../../assets/app.scss";
.Bredcrumb{
    display: flex;
    background: #0d2233;
    .list{
    display: inline-flex; 
    margin-left: 0.25rem; 
    align-items: center;
    list-style: none; 
    margin-bottom: 0px;
    @include mobile{
        padding-left: 1rem;
        flex-wrap: wrap;

    }
    }
    .list-center{
        display: inline-flex; 
        align-items: center; 
    }
    .list-icon{
        color: #9CA3AF; 
        width: 1rem; 
        height: 1rem; 
    }
    .list-icon-home{ 
        color: #9CA3AF; 
        width: 1rem; 
        height: 1rem; 
    }
    .list-title{
        margin-left: 0.25rem; 
        color: #6B7280; 
        font-size: 0.875rem;
        line-height: 1.25rem; 
        font-weight: 500; 
        text-decoration: none;
    }
    .list-title:hover{
        color: #6B7280;
    }
    .list-title-last{
        margin-left: 0.25rem; 
        color: #6B7280; 
        font-size: 0.875rem;
        line-height: 1.25rem; 
        font-weight: 500; 
        text-decoration: none;
        margin:0px;
    }
}