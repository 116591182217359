@import "../../assets/app.scss";
.campaign-home{
    background: #abb8bd6e;
    @include mobile{
        padding: 40px 0px 40px 0px;
    }
    @include tablet{
        padding: 70px 0px 70px 0px;
    }
  .container-fluid{
    width: 90%; 
    .left-banner{
        // padding: 0px 8px 0px 0px;
        .campaigns-banner{
            width: 100%;
            @include mobile{
                margin: 0px 0px 30px 0px;
            }
            @include tablet{
                margin: 0px 0px 50px 0px;
            }
            @include desktopsmall{
                margin: 0px 0px 67px 0px;
            }
            @include smallmobile{
                height: 190px;
            }
            @include mobile{
                height: 220px;
            }
            @include tablet{
                height: 340px;
            }
            @include desktopsmall{
                height: 500px;
            }
            @include desktop{
                height: 570px; 
            }
            @include desktoplarge{
                height: 625px;
            }
            background-position: 0%;
            background-repeat: no-repeat;
            transition: all 1000ms ease;
            background-size: cover;
        }
        .description{
             position: relative;
             @include tablet{
                top: 75%;
             }
             @include desktopsmall{
                 top: 80%;
             }
             @include desktoplarge{
                top: 81%;
            }
             top: 81%;
            
             .title{
                text-align: center;
                color: white;
                @include smallmobile{
                    font-size: 9px;
                }
                @include mobile{
                    font-size: 10px;
                }
                @include tablet{
                    font-size: 14px;
                }
                @include desktopsmall{
                    font-size: 17px;
                }
                @include desktop{
                    font-size: 18px;
                }
                @include desktoplarge{
                    font-size: 21px;
                }
                letter-spacing: 1px;
                text-transform: uppercase;
                font-family: 'Times New Roman', Times, serif;
            }
            .date{
                font-size: 12px;  
                color: white;    
                text-align: center;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 600;
            }
        }

    }
    .right-banner{
        padding: 0px 0px 0px 5px;
        .campaigns-banner{
            width: 100%;
           
            @include tablet{
                height: 165px;
            }
            @include desktopsmall{
                height: 244px;
            }
            @include desktopsmall{
                height: 244px;
            }
            @include desktop{
                height: 280px; 
            }
            @include desktoplarge{
                height: 307px;
            }
            background-repeat: no-repeat;
            background-position: center center;
            transition: all 1000ms ease;
            background-size: cover;
        }
        .description{
            position: relative;
            @include tablet{
                top: 50%;
            }
            @include desktopsmall{
                 top: 64%;
            }
            @include desktoplarge{
                top: 64%;
            }
            .title{
                text-align: center;
                color: white;
                @include tablet{
                    font-size: 14px;
                }
                @include desktopsmall{
                    font-size: 17px;
                }
                @include desktop{
                    font-size: 18px;
                }
                @include desktoplarge{
                    font-size: 21px;
                }
                letter-spacing: 1px;
                text-transform: uppercase;
                font-family: 'Times New Roman', Times, serif;
            }
            .date{
                font-size: 12px;  
                color: white;    
                text-align: center;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 600;
            }
        }
        
        .second-image-padding{
            padding: 10px 15px;
        }
    }
  }  
}