@import "../../assets/app.scss";

.dining-main-container{
    padding: 80px 50px 75px 50px;
    @include mobile{
        padding: 80px 0px 75px 0px;
    }
    @include tablet{
        padding: 80px 50px 75px 50px;
    }
    .findout-more{
        border-radius: 6px;
        background: $jclub-grey;
        color: $jclub-navy;
        border: 1px solid $jclub-grey;
        width: 48%;
        max-width: 150px;
        text-transform: uppercase;
        font-size: 13px;
        @include tablet{
            font-size: 11px;
            padding: 3px 0px;
            width: 40%;
            margin: 10px 0px 0px 0px;
       }
       @include desktopsmall{
        font-size: 12px;
        padding: 5px 0px;
        width: 48%;
        margin: 0;
       }
       @include desktop{
        font-size: 13px;
        width: 48%;
       }
        font-weight: 600;
        padding: 6px 0px;
        box-shadow: none;
    }

    .banner-container{
        text-align: center;
        .slick-slider{
            .slick-dots {
                bottom: 5px;
                z-index: 9;
                li {
                    margin:0px;
                    &.slick-active{
                        button{
                          &::before{
                            color: white;
                            font-size: 9px;
                            opacity: 1;
                          }
                        }
                      }
                }
                li button {
                    background: transparent;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    height: 9px;
                    line-height: 0;
                    opacity: 1;
                    padding: 0;
                    width: 9px;
                }
                li button:before {
                    color: transparent;
                    line-height: 11px;
                    opacity: 1;
                    width: 8px;
                }
            }
        } 
     }
     .dinning-description{
         .list-items{
            padding: 80px 15px 50px 15px;
            padding: 25px 0px 0px 0px;
              table{
                  margin: 0px;
                 .thead-light{
                     th{
                        background-color: $jclub-navy;
                        color: $jclub-grey;
                        font-family: 'Times New Roman', Times, serif;
                        font-weight: 500;
                        font-size: 20px;
                        padding: 3px 6px;
                        letter-spacing: 0.5px;
                        border-top: 1px solid #abb8bd;
                        border-bottom: 1px solid #abb8bd;
                        text-transform: uppercase;
                     }
                 }
                 tbody{
                     tr{
                        td{
                            width: 30%;
                        }
                     }
                 }
                 tbody tr:nth-of-type(even) {
                    background-color: $jclub-table-bg;
                     font-size: 14px;
                    td{
                        padding: 0.32rem 0.35rem;
                        font-family: Arial, Helvetica, sans-serif;
                    } 
                }
                tbody tr:nth-of-type(odd) {
                    background-color: unset !important;
                    font-size: 14px;
                     td{
                        padding: 0.32rem 0.35rem;
                       font-family: Arial, Helvetica, sans-serif;
                   }
                }
             }
         }
     }
}