@import  "./variables.scss";

/*mixins declared below*/
@mixin smallmobile {
    @media (min-width: 360px ) and (max-width: 376px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$xs-max-width}) {
        @content;
    }
}

@mixin tablet { 
    @media (min-width: #{$sm-min-width}) {
        @content;
    }
}

@mixin desktopsmall {
    @media (min-width: #{$md-min-width}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$lg-min-width}) {
        @content;
    }
}

@mixin desktoplarge {
    @media (min-width: #{$xlg-min-width}) { 
        @content;
    }
}
@mixin desktopxtralarge {
    @media (min-width: #{$xxlg-min-width}) { 
        @content;
    }
}

@mixin times-new-roman{
    font-family: 'Times New Roman', Times, serif;
}


//***** all the font sizes *****//
@mixin tfarrow-largest-106 {
    font-size: 50px;
	letter-spacing: normal;
    line-height: 1;

    @include tablet {
        font-size: 70px;
    }

    @include desktopsmall {
        font-size: 75px;
    }

    @include desktop {
        font-size: 96px;
        font-weight: 500;
    }
}

@mixin tfarrow-larger-94 {
    font-size: 40px;
	letter-spacing: normal;
    line-height: 1;

    @include tablet {
        font-size: 58px;
    }

    @include desktopsmall {
        font-size: 68px;
    }

    @include desktop {
        font-size: 94px;
    }
}

@mixin tfarrow-large-65 {
    font-size: 34px;
	letter-spacing: normal;
    line-height: 1;
    font-weight: 500;

    @include tablet {
        font-size: 55px;
    }

    @include desktopsmall {
        font-size: 60px;
    }

    @include desktop {
        font-size: 65px;
        font-weight: 500;
    }
}

@mixin tfarrow-normal-47 {
    font-size: 34px;
    line-height: 1;

    @include desktopsmall {
        font-size: 41px;
    }

    @include desktop {
        font-size: 47px;
    }
}

@mixin tfarrow-small-32 {
    font-size: 24px;
    line-height: 1.1;
    font-weight: 500;
    @include tablet {
        font-size: 26px;
    }

    @include desktopsmall {
        font-size: 28px;
    }

    @include desktop {
        font-size: 32px;
    }
}

@mixin tfarrow-smaller-17 {
    font-size: 17px;
    line-height: 1.2;
    font-weight: 500;
}

@mixin tfarrow-smaller-21 {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    @include tablet {
        font-size: 20px;
    }

    @include desktopsmall {
        font-size: 21px;
    }
}
@mixin tfarrow-smaller-14 {
    font-size: 14px;
    line-height: 1.3;
    font-weight: 500;
    @include tablet {
        font-size: 18px;
    }

    @include desktopsmall {
        font-size: 21px;
    }
}

@mixin gilroy-largest-18 {
    font-size: 14px;
	font-weight: 600;
	letter-spacing: 2px;
	line-height: 1;
	text-transform: uppercase;

    @include desktopsmall {
        font-size: 16px;
    }
	
    @include desktop {
        font-size: 18px;
    }	
}

@mixin gilroy-regular-18 {
    font-size: 16px;
	font-weight: 400;
	line-height: 1.5;

    @include desktopsmall {
        font-size: 17px;
    }
	
    @include desktop {
        font-size: 18px;
    }	
}

@mixin gilroy-bold-18 {
    font-size: 16px;
	font-weight: 600;
	line-height: 1.5;

    @include desktopsmall {
        font-size: 17px;
    }
	
    @include desktop {
        font-size: 18px;
    }	
}

@mixin gilroy-small-16 {
    font-size: 14px;
	font-weight: 400;
	line-height: 1.4;

    @include tablet {
        font-size: 15px;
		line-height: 1.6;
    }
	
    @include desktop {
        font-size: 16px;
		line-height: 1.8;
    }	
}

@mixin gilroy-smaller-14 {
    font-size: 12px;
	font-weight: 600;
	line-height: 1.2;

    @include desktopsmall {
        font-size: 14px;
        line-height: 1.5;
        font-weight: 600;
    }
}

@mixin gilroy-smaller-14-2 {
    font-size: 12px;
	font-weight: 600;
	letter-spacing: 2px;
	line-height: 1;
	text-transform: uppercase;

    @include desktopsmall {
        font-size: 14px;
    }
}

@mixin top-bottom-animation{
    .cssanimation, .cssanimation span {
        animation-duration: 1s;
        animation-fill-mode: both;
    }
    .cssanimation span { display: inline-block }
    .fadeInBottom { animation-name: fadeInBottom }
    @keyframes fadeInBottom {
        from {
            opacity: 0;
            transform: translateY(100%);
        }
        to { opacity: 1 }
    }
}