@import "../../assets/app.scss";

.error-page-wrapper {
  background-image: url("../../assets/errorpagebg.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  position: relative;
  height: 100vh;
  width: 100%;
  padding-top: 30%;
  // margin: 20px;
  color: $jclub-navy;
  @include tablet{
    padding-top: 15%;
  }
  @include desktop{
    padding-top: 15%;
  }
  h1 {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-family: SometimesTimes;
    font-size: 65px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    background-color: white;
    border-radius: 50px;
    @include desktop {
      width: 65%;
    }

    @include tablet {
      font-size: 65px;
    }
    @include mobile {
      font-size: 42px;
    }
  }

  p {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-top: 15px;
    font-family: FSMeridian;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    background-color: white;
    @include desktop {
      margin-top: 22px;
      width: 62.5%;
    }
    @include tablet {
      font-size: 23px;
    }
  }

  a {
    margin: 25px auto;
    display: block;
    padding: 8px 0;
    width: 80%;
    border: 1px solid #fff;
    color: $jclub-navy;
    text-align: center;
    text-transform: uppercase;
    background-color: #fff;
    font-family: FSMeridian;
    @include desktop {
      width: 22%;
    }
  }
}
