@import "../../assets/app.scss";
@include mobile{
.testimonial-main{
    
    padding: 0px 0px 70px 0px;

        .mobile-offer-card-wrapper{
            padding: 0px;
            background-color: #fff;
            font-size: 14px;
            .offer-title{
                color: $jclub-grey;
                font-size: 16px;
                letter-spacing: 1px;
            }
            .offer-description{
                padding:20px !important;
            }
            .slick-prev{
                left: -10px !important;
                img{
                    left: 24px;
                    position: relative;
                }
            }
            .slick-next{
                right: -10px !important;
                img{
                    right: 24px;
                    position: relative;
                }
            }
            
        }

}
}