@import "../../assets/app.scss";

.offer-detail-main-container{
 
    .left-background-image{
        height:93vh;
        @include tablet{
            height: 64vh;
        }
        @include desktopsmall{
            height: 90vh;
        }
        @include desktop{
            height: 90vh;
        }
        @include desktoplarge{
            height: 93vh;
        }
        @include mobile{
            height: 35vh;
        }
        background-repeat: no-repeat;
        background-position: center center;
        transition: all 1000ms ease;
        background-size: cover;
    }
    .right-background-event{
        padding: 5em 4em 2em 5em;
        @include tablet{
            padding: 1em 2em 1em 2em;
        }
        @include desktop{
            padding: 2em 2em 2em 3em;
        }
        background: $jclub-grey;
        @include mobile{
            padding: 36px 36px;
            background: none;
        }
        .event-title{
            font-family: 'Times New Roman', Times, serif;
            font-size: 26px;
            letter-spacing: 2px;
            color: $jclub-navy;
            @include mobile{
                text-align: center;
            }
        }
        .show-mobile-location{
            display: none;
            @include mobile{
                display: block;
                text-align: center;
                font-family: Arial;
                font-weight: 600;
                letter-spacing: 1px;
                padding: 9px 0px;
            }
        }
        .event-location-time{
            padding: 15px 0px 18px 0px;
            @include mobile{
                padding: 0px;
            }
            p{
                color: $jclub-navy;
                font-size: 14px;
                font-weight: 600;
                font-family: Arial, Helvetica, sans-serif;
                padding: 0px;
                margin: 0;
                letter-spacing: 0.6px;
                @include mobile{
                    display: none;
                }
            }
        }
        .event-description{
            @include tablet{
                max-height: 46vh;
                overflow-y: auto;
            }
            @include desktopsmall{
                max-height: 55vh;
                overflow-y: auto;
            }
            @include desktop{
                max-height: 58vh;
                overflow-y: auto;
            }
            @include desktoplarge{
                max-height: 63vh;
                overflow-y: auto;
            }
            
            &::-webkit-scrollbar {
            width: 5px;
            }
            &::-webkit-scrollbar-track {
            background: #f1f1f1;
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
            }
            
            &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5px;
            }
            .readmore{
                background: $jclub-navy;
                color: $jclub-grey;
                padding: 5px 19px;
                border-radius: 6px;
                font-size: 14px;
                box-shadow: none;
            }

            P{
                color: $jclub-navy;
                @include mobile{
                    padding: 0px;
                }
                @include tablet{
                    padding: 0px;
                }
               

                font-size: 14px;
                font-family: Arial, Helvetica, sans-serif;
                letter-spacing: 0.5px;
            }
        }
        .show-mobile-detail{
            display: none;
            @include mobile{
                     display: block;
                    padding: 0;
                    margin: 0;
                    font-size: 14px;
                    font-family: Arial;
                    font-weight: 600;
                    text-align: center;
                    p{
                        padding: 2px 0px;
                        margin: 0;
                        color: $jclub-navy;
                    }
              }
        }
        .booknow-button{
            padding: 20px 0px;
            @include mobile{
                text-align: center;
            }
            .btn{
                background:$jclub-timebg;
                color: $jclub-grey;
                border-radius: 0px;
                color: $jclub-grey;
                font-weight: 600;
                box-shadow: none;
                padding: 7px 27px;
             }
        }
    }
}







