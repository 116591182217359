@import "../../assets/app.scss";
@import "../../assets/variables.scss";

.not-found-title {
    font-family: TFArrow-Medium;
    font-size: 32px;
    line-height: normal;
    color: #7c6f55;
    margin-top: 20px;
    @include mobile{
      font-size: 22px;
      font-weight: 500;
      line-height: 1.18;
    }
  }