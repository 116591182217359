.right-column-article{
    .card-title{
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        font-family: Arial, Helvetica, sans-serif;
    }
    .card-text{
        font-size: 14px;
        letter-spacing: .5px;
    }
    .readmore{
        font-size: 14px;
        letter-spacing: .5px;

    }
}