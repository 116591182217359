@import "../../assets/app.scss";
@include mobile{
    .arrow-settings-otherclub{
        display: block;
    }
}
@include tablet{
    .arrow-settings-otherclub{
        display: none;
    }
}

@include desktopsmall{
    .arrow-settings-otherclub{
        display: none;
    }
}


.otherclub-container{
    background:white;
    @include mobile{
        padding: 50px 50px 25px 50px;
    }
    @include tablet{
        padding: 50px 50px 50px 50px;
    }
    @include desktopsmall{
        padding: 50px 50px 50px 50px;
    }
    .hotel-card{
        @include tablet{
            padding: 0px 40px;
        }
        @include desktopsmall{
            padding: 0px 60px;
        }
        @include desktop{
            align-items: center;
            display: flex !important;
            flex-direction: column;
        }
    }

    @include desktopsmall{
        .slick-slider{
            padding: 0em 2em;
        }
    }
    @include desktop{
        .slick-slider{
            padding: 0em 2em;
        }
    }
    @include desktoplarge{
        .slick-slider{
            padding: 0em 6em;
        }
    }
    .otherclubs-title{
        text-transform: uppercase;
        text-align: center;
        @include mobile{
            font-size: 22px;
        }
        @include tablet{
            font-size: 24px;
        }
        letter-spacing: 1.5px;
        margin: 50px 0px 60px 0px;
        @include mobile{
            margin: 20px 0px 40px 0px;
        }
        @include tablet{
            margin: 50px 0px 60px 0px;
        }
        color: $jclub-navy;
        font-family: 'Times New Roman', Times, serif;
        @include desktop{
        font-size: 24px;
        letter-spacing: 2.5px;
        }
    }
    .top-div{
        display: flex;
        justify-content: space-between;
        min-width: 250px;
        align-items: center;
        .middle{
            width: 100%;
            height: 300px;
            @include tablet{
                width: 400px;
                height: 300px;
            }
            @include desktop{
                width: 400px;
                height: 300px;
            }
            .club-image{
                object-fit: cover;
                width: 100%;
            }
        }
    }
   
    .arrow-settings-otherclub{
        .common-row-relative{
            position: relative;
            top: -380px;
        }
        .common-relative.text-left{
             position: relative;
             left: -50px;
        }
        .common-relative.text-right{
            position: relative;
            right: -50px;
       }
        .left-arrow{
            width: 30px;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            // position: relative;
            // top: calc(-400px - 30px);
            // left: -30px;
         }
        .right-arrow{
            width: 30px;
            // position: absolute;
            // top: calc(-400px - 30px);
            // right: -30px;
            // margin: 0 0 0 auto;
            // display: block;
         }
    }

    
    .slick-prev {
        margin-top: -114px;
        margin-left: -10px;
        z-index: 1;
        transform: rotate(180deg);
        img{
            width: 100%;
            height: 100%;
        }
    }

    .slick-list {
        // margin-top: -114px;
        // margin-left: -10px;
        // z-index: 1;
        // transform: rotate(180deg);
        // img{
        //     width: 100%;
        //     height: 100%;
        // }
    }
     
    .slick-next {
        z-index: 1;
        margin-top: -115px;
        margin-right: -10px;
        transform: rotate(0deg);
        img{
            width: 100%;
            height: 100%;
        }
    }
    .slick-slide img {
        width: 100%;
        height: 100%;
    }
    .bottom-div{
          font-family:'Times New Roman', Times, serif;
          text-align: center;
          @include tablet{
            max-width: 400px;
          }
          @include desktop{
            align-items: center;
            display: flex;
            flex-direction: column;
            max-width: 400px;
          }
          div{
            margin: 20px 0px 0px 0px;
              align-items: center;
          }
          .hotel-title{
            font-size: 14px;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            padding: 19px 0px 0px 0px;
          }
          .hotel-description{
             font-size: 13px;
             margin: 3px 67px;
             font-family: Arial, Helvetica, sans-serif;
             align-items: center;
             @include mobile{
                height: auto;
                margin: auto !important;
             }
             @include tablet{
                height: 75px;
                margin: 3px 10px;
             }
             @include desktopsmall{
                height: auto;
                margin: 3px 67px;
             }
             @include mobile{
                margin: 0px 50px;
             }
          }
          .link{
            font-size: 14px;
            font-family: Arial, Helvetica, sans-serif;
            // a{
                text-decoration: underline;
                color: $jclub-grey;
            // }
          }
    }
}