@import "../../../assets/app.scss";

.article {
    @include tablet {
        padding: 40px 40px;
    }

    @include desktopsmall {
        padding: 40px 40px;
    }

    @include desktop {
        padding: 60px 60px;
    }

    @include desktoplarge {
        padding: 80 80px;
    }

    .article-title {
        @include tablet {
            font-size: 25px;
        }

        @include desktopsmall {
            font-size: 27px;
        }

        @include desktop {
            font-size: 32px;
        }

        letter-spacing: 2px;
        width: 100%;
        font-family: 'Times New Roman',
        Times,
        serif;
        margin-bottom: 40px;
    }

    .article-image {
        width: 100%;
        height: 400px;
        object-fit: cover;
        object-position: center;
    }

    .article-description {
        font-family: Arial, Helvetica, sans-serif;
        width: 100%;
        margin-top: 30px;

        @include mobile {
            font-size: 13px;
        }

        @include desktopsmall {
            font-size: 15px;
        }
    }

    .right-column-article {
        .col-md-4 {
            .card-img-top {
                height: 300px;
                width: 100%;
                object-fit: cover;
            }
        }

        @include tablet {
            padding-top: 50px;
        }

        @include desktopsmall {
            padding-top: 50px;
        }

        @include desktop {
            padding-top: 100px;
        }

        .row {
            gap: 1rem;
        }

        .col-md-4 {
            padding-left: 0;
            padding-right: 0;

        }

        .card-title {
            font-size: 14px;
            font-weight: 700;
        }

        .related {
            font-family: 'Times New Roman', Times, serif;
            font-size: 20px;
            letter-spacing: 2px;
            margin-bottom: 20px;
        }
    }
}