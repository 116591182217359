@import "../../../assets/app.scss";
.newsletter{
    background-size: cover;
    min-height: 100px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.newslettermobileview-title{
    color:#abb8bd;
   //  padding: 20px;
    text-align: center;
    padding-bottom: 20px;
   //  margin: 16px;
    /* color: #0e2538; */
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    font-family: "Times New Roman", Times, serif;
    letter-spacing: 1px;
}
.newsletter-mobileview{
   @include mobile{
      background-repeat: no-repeat;
      background-position: 15% center;
      zoom: 0.8;
      @media screen and (min-width:576px) and (max-width:768px) {
         zoom: 0.9;
      }
      .container{
         padding: 0px 16% 0px 9%;
         @media screen and (min-width:576px) and (max-width:768px) {
            padding:0px;
         }
      }
   }
     input{
        background: transparent;
        color: #abb8bd;
        border: 1px solid;
        border-radius: 9px;
        font-size: 13px;
        box-shadow: none;
     }
      input::placeholder{
         letter-spacing: 0.8px;
         opacity: 1;
      }
      input:focus{
        background: transparent;
        color: #abb8bd;
        border: 2px solid;
        border-radius: 9px;
        font-size: 13px;
        box-shadow: none;
     }
  
      button{
        background-color: #abb8bd;
        color: #0e2538;
        padding: 5px 55px;
        border-radius: 7px;
        font-weight: 500;
        box-shadow: none;
        font-size: 14px;
        height: calc(1.5em + 0.75rem + 2px);
        text-align: center;
       }   
      .lastname-padding{
         padding: 0px 15px 0px 7px;
      }
      .firstname-padding{
         padding: 0px 0px 0px 15px;
      } 
      .submit-button-newsletter{
         color:#abb8bd;
         text-align: center;
      }
      .error{
         color: rgb(220, 53, 69);
         font-size: 10px;
         padding-left: 12px;
        }
        .spinner {
         margin-right: 5px;
         animation: spin infinite 5s linear;
     
         /*You can increase or decrease the timer (5s) to 
          increase or decrease the speed of the spinner*/
       }
     
       @keyframes spin {
         from {
           transform: rotate(0deg);
         }
         to {
           transform: rotate(360deg);
         }
       }
}