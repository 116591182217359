@import "../../assets/app.scss";
.contact-container{
    background: #abb8bd6e;
    padding: 70px 25px 50px 25px;
    color: $jclub-navy;
    @include desktop{
        background:$jclub-navy;
        color: $jclub-grey;
        // padding: 100px 150px;
        padding: 100px 150px 30px 150px;
    }

    .title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1{
            text-transform: uppercase;
            font-size: 24px;
            @include smallmobile{
                font-size: 20px;
            }
            font-family: 'Times New Roman', Times, serif;
            color: #0d2233d1;
            @media screen and (max-width:350px) {
                font-size: 20px;
            }
            @include desktop{
                letter-spacing: 2.3px;
                color: $jclub-grey;
            }
        }
        p{
            font-size: 18px;
        }
    }

    .main-wrapper{
        margin: 30px 0px;
        position:relative;
        padding: 12px;
        .location-map{
            width: 100%;
            height: 400px;
            background: black;
            margin-left: auto;
            margin-right: auto;
            iframe{
                width: inherit;
                height: inherit;
                border: none;
                text-decoration: none;
            }
        }
        .contact-list{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            color: $jclub-navy;
            .club-address{
                ul{
                    list-style: none;
                    font-size: 20px;                
                }
            }
            .club-contact{
                ul{
                    list-style: none;
                    font-size: 20px;                
                }
            }
        }
    }
    @include desktop{
        #map:before,#map>:first-child:before{
            position:absolute;
            width:180px; height: 180px;
            border-color:$jclub-grey; /* or whatever colour */
            border-style:solid; /* or whatever style */
            content: ' ';
        }
        #map>:first-child:before{
            width:45%; height: 180px;
        }
        #map:before{top:0;left:0;border-width: 12px 0 0 12px}
        #map>:first-child:before{bottom:0;right:0;border-width: 0 12px 12px 0}
    }
    .desktop-sub-info{
        display: none;
        p{
            margin: 0px 3px;
            color: white;
        }
        .get-direction{
            font-weight:500;
            margin: 0px 3px;
        }
        @include desktop{
            display: flex;
            justify-content: center;
            font-size: 14px;
        }
    }
    .sub-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        p{
            margin-bottom: 5px;
            
        }
        .get-direction{
            font-weight: 500;
            margin-top: 20px;
        }
        @include desktop{
            display: none;
        }
    }
}