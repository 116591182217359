@import "../../../assets/app.scss";

@include mobile {
    .article {
        display: none;
    }

    .news-mobile-article {
        display: block;
        padding: 40px 20px;

        .image {
            padding: 24px;

            img {
                object-fit: cover;
                height: 213px;
                width: 300px;
            }
        }

        .contents {
            color: $jclub-navy;

            .title {
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
                text-transform: uppercase;
                font-size: 17px;
            }

            .description {
                font-size: 15px;
                text-align: center;
            }
        }
    }

    .arrow-settings {
        color: $jclub-navy;
        font-size: 14px;
        font-weight: 600;

        .left {

            float: left;

            .left-arrow {
                width: 20px;
            }
        }

        .right {
            float: right;

            .right-arrow {
                width: 20px;
            }
        }
    }
}

@include tablet {
    .news-mobile-article {
        display: none;
    }

    .article {
        display: block;
    }
}