.sitemap-container {
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial";
  margin-top: 80px;

  a {
    color: #06c;
    text-decoration: none;
  }
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 20px;
  }
  .grid-item {
    /*border: 1px solid rgba(0, 0, 0, 0.8);*/
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    text-align: left;
  }
  .title-container {
    display: flex;
    justify-content: left;
    border-bottom: 1px solid grey;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 30px;
  }
  .title {
    color: black;
    font-size: 20px;
    padding-bottom: 20px;
    text-decoration: none;
  }
  .list-wrapper {
    list-style: none;
    text-align: left;
    padding: 0px;
  }
  .list-indiviual {
    padding: 0px 17px 7px 0px;
  }
  .list-indiviual:hover {
    text-decoration-line: underline;
  }
  .list-title {
    color: black;
    /* padding: 0px 17px 0px 7px; */
    font-size: 20px;
    text-decoration: none;
  }
}
