@import "../../assets/app.scss";

.contact-us-main{
    background: $jclub-navy;
    .small-description{
        font-size: 24px;
        text-transform: uppercase;
        color: $jclub-grey;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        // padding: 4rem 4rem 1rem 4rem;
        font-family: 'Times New Roman', Times, serif;
        letter-spacing: 2px;
    }
    .address-rows{
        .column{
         background-color: transparent;
        @include mobile{
            padding: 0px 12px 20px 12px;  
        } 
        @include tablet{
            padding: 0px 12px 20px 12px;
        }
        @include desktopsmall{
            padding: 12px 12px;
        }
        @include desktop{
            padding: 20px 16px;
        }
         .card{
               background-color: transparent;   
               border: 1px solid $jclub-grey;
         }
        .hotel-name{
             font-family: 'Times New Roman', Times, serif;
             font-size: 20px;
             color: $jclub-grey;
             letter-spacing: 1px;
        }
        .address{
            height: 50px;
        }
        .phone{
           @include desktopsmall{
            margin-top: 25px;
           }
            svg{
                width: 20px;
            }
        }
        .clock{
            svg{
                width: 20px;
            }
        }
        .email{
            svg{
                width: 20px;
            }
        }
        .direction{
            svg{
                width: 20px;
            }
            a{
                text-decoration: underline;
            }
        }
      }
    }
}