
.newsletter-desktopview{
      input{
       color: #abb8bd;
       border: 1px solid;
       border-radius: 9px;
       font-size: 12px;
       padding: 5px 0.75rem;
       height: auto;
      }
      input::placeholder{
        opacity: 0.5;
        letter-spacing: 0.8px;
      }
      input:focus{
         color: #abb8bd;
         border: 1px solid;
         border-radius: 9px;
         box-shadow:none;
      }
      button{
        background-color: #abb8bd;
        color: #0e2538;
        // padding: 5px 55px;
        // border-radius: 10px;
        padding: 2px 33px;
        border-radius: 9px;
        font-weight: 500;
        box-shadow: none;
        font-size: 14px;
        // height: calc(1.5em + 0.75rem + 2px);
       }
       .lastname-padding{
          padding: 0px 15px 0px 7px;
       }
       .firstname-padding{
          padding: 0px 0px 0px 15px;
       }

       .error{
        color: rgb(220, 53, 69);
        font-size: 12px;
        padding-left: 12px;
       }
   }

.newsletter{
    // height: 250px;
    // background-color: #0d2233;
    width:100%;
    height: 100%;
    // justify-content: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    

 
    .newsletter-title{
        // margin: 6px 16px;
        margin: 6px 4px;
        color: #0e2538;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        font-family: "Times New Roman", Times, serif;
        letter-spacing: 1px;
    }
    .myform{
      display: contents;
    }
    .spinner {
      margin-right: 5px;
      animation: spin infinite 5s linear;
  
      /*You can increase or decrease the timer (5s) to 
       increase or decrease the speed of the spinner*/
    }
  
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    .newsletter-form{
      display: flex;
      flex-direction: column;
        .newsletter-row-1{
            // display: flex;
            // flex-direction: row;
            // justify-content: center;
          input{
            padding: 5px 5px;
            border-radius:10px;
            border: 1px solid darkgrey;
            margin:5px;
            font-size: 15px;
            background: transparent;
            max-width: calc(100% - 60px);
          }
          input:focus{
            outline: none;
          }
          input::-webkit-input-placeholder {
              padding-left: 5px;
              font-size: 15px;
              color: darkgrey;
            }
        }
        .newsletter-row-2{
          // display: flex;
          // flex-direction: row;
          // justify-content: flex-start;
        input{
          padding: 5px 5px;
          border-radius:10px;
          border: 1px solid darkgrey;
          margin:5px;
          font-size: 15px;
          background: transparent;
        }
        input:focus{
          outline: none;
        }
        input::-webkit-input-placeholder {
            padding-left: 5px;
            font-size: 15px;
            color: darkgrey;
          }
      }
      .newsletter-row-3{
        // display: flex;
        // flex-direction: row;
        // justify-content: center;
      input{
        padding: 5px 5px;
        border-radius:10px;
        border: 1px solid darkgrey;
        margin:5px;
        font-size: 15px;
        background: transparent;
      }
      input[type=submit] {
        background-color: darkgrey;
        width:100px;
      }
      input:focus{
        outline: none;
      }
      input::-webkit-input-placeholder {
          padding-left: 5px;
          font-size: 15px;
          color: darkgrey;
        }
    }
    }
}
