@import "../../assets/app.scss";

.membership-main{

    .slick-prev{
        left: -31px !important;
    }
    .slick-next{
        right: -31px !important;
    }

    @include desktopsmall{
        .mobile-view{
            display: none;
        }
        .desktop-view{
            display: block;
        }
    }

    @include desktoplarge{
        .mobile-view{
            display: none;
        }
        .desktop-view{
            display: block;
        }
    }

    @include desktopxtralarge{
        .mobile-view{
            display: none;
        }
        .desktop-view{
            display: block;
        }
    }

    @include tablet{
            // .mobile-view{
            //     display: none;
            // }
            // .desktop-view{
            //     display: block;
            // }
            // .membership-overview .overview-paragraph{
            //     width: 160%;
            // }
            // .membership-overview .membership-stage1-title {
            //     height: 252px;
            // }
    }

    @media screen and (min-width:768px) and (max-width:1200px){

            .membership-overview .overview-paragraph{
                // width: 160%;
            }
            .membership-overview .membership-stage1-title {
                height: 252px;
            }
            .membership-overview .overview-paragraph .border-pad .paragraph{
                font-size: 14px;
            }
            .membership-overview .overview-paragraph {
                position: relative;
                top: 32px;
            }

    }


    @include mobile{
        .mobile-view{
            // display: block;
            display: none;
        }
        .desktop-view{
            display: none;
        }
    }

}

 

.membership-overview{
    // padding:100px 0px;
    padding: 4rem 150px;
    @include tablet{
        padding: 4rem 50px;
    }
    @include desktop{
        padding: 6rem 150px 0rem 150px;
    }
    .slick-slider{
        .slick-dots {
            bottom: 5px;
            z-index: 9;
            li {
                margin:0px;
                &.slick-active{
                    button{
                      &::before{
                        color: white;
                        font-size: 9px;
                        opacity: 1;
                      }
                    }
                  }
            }
            li button {
                background: transparent;
                border: 2px solid #fff;
                border-radius: 50%;
                height: 9px;
                line-height: 0;
                opacity: 1;
                padding: 0;
                width: 9px;
            }
            li button:before {
                color: transparent;
                line-height: 11px;
                opacity: 1;
                width: 8px;
            }
        }
    } 
    
     .banner-img{
         position: relative;
         max-width: 90%;
        //  object-fit: cover;
        //  height: 570px;
     }
     .membership-stage1-title{
        color: $jclub-facilities-title;
        font-family: 'Times New Roman', Times, serif;
        background: $jclub-facilities;
        position: absolute;
        left: -39px;
        top: 50px;
        // height: 470px;
        height: 325px;
        @include tablet{
            left: 0px;
            top: 0px;
        }
        @include desktop{
            left: -39px;
            top: 50px;
        }
        .membership-main-title{
            transform: rotate(-90deg);
            position: relative;
            top: 125px;
            left: -168px;
            letter-spacing: 4px;
            z-index: 9;
            @include tablet{
                top: 125px;
                left: -146px;
                font-size: 35px;
            }
            @include desktop{
                // top: 125px;
                top: 120px;
                left: -168px;
                font-size: 50px;
            }
        }
     }

     .findout-more{
        @include desktop{
             padding: 10px 0px 0px 0px;
        }
         .btn{
        border-radius: 7px;
        background: $jclub-grey;
        color: $jclub-navy;
        border: 1px solid $jclub-grey;
        width: 48%;
        max-width: 200px;
        text-transform: uppercase;
        font-size: 13px;
        @include tablet{
            font-size: 11px;
            padding: 3px 0px;
            width: 53%;
            margin: 10px 0px 0px 0px;
       }
       @include desktopsmall{
        font-size: 12px;
        padding: 5px 0px;
        width: 60%;
        margin: 0;
        max-width: 180px;
       }
       @include desktop{
        font-size: 13px;
        width: 48%;
        max-width: 200px;
       }
        font-weight: 600;
        padding: 6px 0px;
        box-shadow: none;
        }
    }

     .overview-paragraph{
        position: relative;
        top: 70px;
        right: 145px;    
        width: 170%;
        @include desktopsmall{
            width: 140%;
        }
        background: white;
        padding: 14px 14px;
        .border-pad{
            padding: 7px 11px;
            border: 1px solid #ABB8BD;
            padding: 25px 35px;
            .paragraph{
                font-family: Arial, Helvetica, sans-serif;
                font-size: 14px;
                @include desktopsmall{
                    font-size: 12px;
                }
                @include desktop{
                    font-size: 14px;
                }
                color: rgba(0, 0, 0, 0.85);
                letter-spacing: 1px;
            }
            .member-title{
                font-family: 'Times New Roman', Times, serif;
                text-transform: uppercase;
                letter-spacing: 3px;
                @include desktopsmall{
                    font-size: 1.3em;
                }
            }
        }
     }
 }