.facilities{

    .slick-prev{
        left: -31px !important;
    }
    .slick-next{
        right: -31px !important;
        img{
            transform: scaleX(-1);
        }
    }
}