@import "./../../assets/app.scss";

.jclub-faq-container {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   img{
    width: 100%;
    height: 100%;
   }
   .linear-gradient-container {
      pointer-events: none;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
      height: 100%;
      min-height: 850px;
      background: linear-gradient(transparent ,#0f2538, #0f2538);
  }
  .jclub-faq-top-container{
   display: flex;
   align-self: center;
   position: relative;
   padding-bottom: 20px;
  }
   .jbc-title{
      width: 50%;
      align-self: center;
      margin-top: 30px;
      font-size: 18px;
      color: white;
      font-family: jumeirah-font2;
      position: relative;
      letter-spacing: 3px;
      font-weight: 600;
     
   }
   .faq-title{
      font-size: 25px;
      color: #abb8bd;
      text-transform: uppercase;
      display: flex;
      padding: 20px 0px 20px 0px;
      justify-content: center;
      font-family: 'Times New Roman', Times, serif;
      letter-spacing: 2px;
   }
}