.slick-slider{
    .slick-arrow.slick-next {
        display: block;
        height:auto;
        padding-right: 0 !important;
        width: 30px ;
        height: 30px;
        z-index: 1;
    }
    .slick-next:before{
        content: none !important;
    }
    .slick-arrow.slick-prev{
        display: block;
        height:auto;
        width: 30px ;
        height: 30px;
        z-index: 1;
    }
    .slick-prev:before{
        content: none !important;
    }
    
} 


