@import "../../assets/app.scss";
 
.navbar-container{
 
        .navlist{
            list-style: none;
            margin: 0;
            padding-left: 0;
        }
        .navitem {
            color: #fff;
            display: block;
            float: left;
            padding: 1rem;
            position: relative;
            text-decoration: none;
            transition-duration: 0.5s;
         }


        .navitem a {
            color: #fff;
        }
        
        .navitem:hover {
            cursor: pointer;
        }
        .navlist{

            .menulist{
                .secondlist{
                    display: none;
                    width: 220px;
                    background: #102638;
                    position: absolute;
                    top: 40px;
                    font-size: 14px;
                    list-style-type: none;
                    padding: 10px;
                    text-align: left;
                }
                .secondlist-color{
                    top: 60px;
                }

                        a,
                        a:after,
                        a:before {
                            transition: all .5s;
                        }
                        a:hover {
                            color: white;
                            text-decoration: none;
                        }                         
                        a {
                            position: relative;
                        }
                        a:after{
                            position: absolute;
                            bottom: -2px;
                            left: 0;
                            right: 0;
                            margin: auto;
                            width: 0%;
                            content: '.';
                            color: transparent;
                            background: white;
                            height: 2px;
                            border-radius: 10px;
                        }
                        a:hover:after {
                            width: 100%;
                        }                  
                        a:after {
                            text-align: left;
                            content: '.';
                            margin: 0;
                            opacity: 0;
                        }
                        a:hover {
                            color: white;
                            z-index: 1;
                        }
                        a:hover:after {
                            z-index: -10;
                            animation: fill 1s forwards;
                            -webkit-animation: fill 1s forwards;
                            -moz-animation: fill 1s forwards;
                            opacity: 1;
                        }  
                        
            }
             
            .menulist:hover .secondlist{
                display: block;
            }

            .selected:after,
            .selected:before {
                transition: all .5s !important;
            }
            .selected:hover {
                color: white !important;
                text-decoration: none !important;
            }

             /* stroke */
             
            .selected {
                position: relative !important;
            }
            .selected:after{
                position: absolute !important;
                bottom: -2px !important;
                left: 0 !important;
                right: 0 !important;
                margin: auto !important;
                width: 0% !important;
                content: '.';
                color: transparent !important;
                background: white !important;
                height: 2px !important;
                border-radius: 10px !important;
            }
            .selected:after {
                width: 100% !important;
            }
            .selected:after {
                text-align: left !important;
                content: '.';
                margin: 0 !important;
                opacity: 0 !important;
            }
            .selected {
                color: white !important;
                z-index: 1 !important;
            }
            .selected:after {
                z-index: -10 !important;
                animation: fill 1s forwards !important;
                -webkit-animation: fill 1s forwards !important;
                -moz-animation: fill 1s forwards !important;
                opacity: 1 !important;
            } 
          
                
        }
        
        .navlist .navitem .navlist {
            visibility: hidden;
            opacity: 0;
            min-width: 5rem;
            position: absolute;
            transition: all 0.5s ease;
            margin-top: 1rem;
            left: -60px;
            display: none;
        }
        
        .navlist .navitem:hover > .navlist,
        .navlist .navitem .navlist:hover {
            margin-top: 160px;
            visibility: visible;
            opacity: 1;
            display: block;
        }
        
        .navlist .navitem .navlist .navitem {
            clear: both;
            // background: #0d223378;
            min-width: 300px;
            font-size: 14px;
            padding: 11px;
        }
        .navlist .navitem .navlist .navitem:hover {
             background: #0d2233c9;
         }
         a.box-border{
             font-weight: 600;
         }
         a.join-now{
             font-weight: 600;
         }
         a.box-border:hover {
            background: #fff;
            color: black !important;
            transition: all 0.7s ease;
         }

         
}

.navigation__item{
    .dropdown-toggle:after{
        border-top: none;
        border-right: none;
        border-bottom:none;
        border-left: none;
    }
    .dropdown-toggle-down::after{
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: .3em solid transparent;
        border-left: 0.3em solid transparent;
        position: relative;
        top: 6px;
        right: 5px;
        margin-left: 8px;
    }
    .dropdown-toggle-up::after{
        border-top: 0.3em solid transparent;
        border-right: 0.3em solid transparent;
        border-bottom: 0.3em solid;
        border-left: 0.3em solid transparent;
    }
}

.navbar-transparent{
    height:100px;
    margin-top:-100px;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:1rem;
    position:sticky;
    top:0;
    z-index: 10;
    color: #abb8bd;
    background-color: transparent;
    transition: 0.8s all ease;
    
    .navigation__icon,
    .navigation__icon::before,
    .navigation__icon::after {
        display: inline-block;
        width: 2.2rem;
        @include mobile{
            width: 2rem;
        }
        height: 2px;
        background-color: white;
    }

    @media screen and (max-width:960px){
        transition: 0.8s all ease;
    }

    .navbar-container{
        display:flex;
        justify-content:flex-start;
        height:80px;
        z-index:1;
        width:100%;
        padding: 0px 8px;
        // max-width:1100px;

        .logo{
            // color:#01bf71;
            justify-self:flex-start;
            cursor:pointer;
            font-size:1.5rem;
            display:flex;
            align-items:center;
            margin-left:0px;
            font-weight:bold;
            text-decoration:none;
            @include desktop{
                padding-left: 40px;
            }

            .logo-image{
                width: 170px;
                height: 170px;
                @include desktopsmall{
                    width: 155px;
                    height: 155px;
                }
                @include desktop{
                    width: 160px;
                    height: 160px;
                }
                @include desktoplarge{
                    width: 170px;
                    height: 170px;
                }
                // height: 150px;
                margin-top: 50%;
                transition: 0.8s all ease;
            }
        }

        .mobile-icon{
            display:none;
         }

        .navlist{
            display:flex;
            align-items:center;
            list-style:none;
            text-align:center;
            font-family: 'Times New Roman', Times, serif;
             margin: 5px 40px 0px auto;
             @include mobile{
                margin: 5px 22px 0px auto;
             }
             
            .navitem{
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 0 0.7rem;
                height: 100%;
                cursor: pointer;
                height:48px;
                color: whitesmoke;
                text-transform: uppercase;
                letter-spacing: 1px;
                .box-border{
                    color: #fff;
                    border: 1px solid #fff;
                    padding: 0px 5px;
                    text-decoration: none;
                }
                @include desktopsmall{
                    font-size: 14px;
                    padding: 0 0.5rem;
                }
                @include desktop{
                    font-size: 14px;
                    padding: 0 0.5rem;
                    font-weight: 400;
                }
                @include desktoplarge{
                    font-size: 16px;
                    padding: 0 0.7rem;
                }
            }
        }
    }
}

.burger-menu-li{
    width: 39px;
}

.whats-app-icon{
    position: fixed; 
    bottom: 1.25rem; 
    z-index: 20; 
    height: 3rem; 
    left: 1.4rem;
}

.navbar-black{
    height:60px;
    @include mobile{
        height:60px;
    }
    @include tablet{
        height:60px; 
    }
    margin-top:-60px;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:1rem;
    position:sticky;
    top:0;
    z-index: 10;
    color: #abb8bd;
    background-color: $jclub-navy;

    .navigation__icon,
    .navigation__icon::before,
    .navigation__icon::after {
        display: inline-block;
        width: 2.2rem;
        @include mobile{
            width: 1.7rem;
        }
        height: 2px;
        background-color: $jclub-grey;
    }

    .navigation__button{
        top: 0.5rem;
    }

 
    @media screen and (max-width:960px){
        transition: 0.8s all ease;
    }

    .navbar-container{
        display:flex;
        justify-content:space-between;
        height:60px;
        z-index:1;
        width:90%;
        padding:0px ;
        @include desktopsmall{
            padding: 0px 44px 0px 0px;
        }
        @include desktop{
            padding: 0px;
        }
        // max-width:1100px;

        .logo{
             // color:#01bf71;
            justify-self:flex-start;
            cursor:pointer;
            font-size:1.5rem;
            display:flex;
            align-items:center;
            margin-left:0px;
            font-weight:bold;
            text-decoration:none;
            @include mobile{
                position: relative;
                right: 30px;
            }
            @include tablet{
                position: relative;
                right: 30px;
            }
            @include desktop{
                padding-left: 18px;
            }

            .logo-image{
                width: 115px;
                height: 100px;
                transform: scale(0.6);
                transition: 0.8s all ease;
            }
        }

        .mobile-icon{
            display:none;
        }

        .navlist{
            display:flex;
            align-items:center;
            list-style:none;
            text-align:center;
             // margin: 0px 10.3% 0px 0px;
            margin: 5px 0px 0px auto;
            @include mobile{
                margin: 5px 8px 0px auto;
            }
            // @media screen and (max-width:760px){
            //     display:none;
            // }

            .navitem{
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 0 0.7rem;
                height: 100%;
                cursor: pointer;
                height:80px;
                color: whitesmoke;
                font-family: 'Times New Roman', Times, serif;
                text-transform: uppercase;
                transition: auto;
                .box-border{
                    color: #fff;
                    border: 1px solid #fff;
                    padding: 0px 5px;
                    text-decoration: none;
                }
                @include desktopsmall{
                    font-size: 13px;
                    padding: 0 0.5rem;
                }
                @include desktop{
                    font-size: 13px;
                    padding: 0 0.5rem;
                }
                @include desktoplarge{
                    font-size: 16px;
                    padding: 0 0.7rem;
                }
            }
        }
    }
}

.navigation-main{
    @include mobile{
        display: block;
    }
    @include tablet{
        display: block;
    }
    @include desktopsmall{
        display: block;
    }
    @include desktop{
        display: block;
    }
}



@include mobile{
//    Nav bar transparent
 .navbar-transparent{
    .navbar-container{
        .navigation__nav{
            height: 100%;
            .logo-image{
                width: 140px;
            }
        }
        .navlist{
            .navigation{
                .navigation__nav{
                    .social-burger{
                        margin: auto;
                        margin-top: 82vh;
                      }                
                }
                .navigation__list{
                    width: 100%;
                    transform: translate(-50%, 22%);
                }
                .navigation__item{
                    margin: 0;
                    transition: auto;
                    ul{
                        li{
                            padding: 4px 0px;
                            @include smallmobile{
                                padding: 2px 0px;
                            }
                            .atag{
                                font-size: 18px;
                                @include smallmobile{
                                    font-size: 14px;
                                }
                            }
                            .dropdown-menus{
                                li{
                                    font-size: 13px;  
                                    padding: 3px 0px;
                                    @include smallmobile{
                                        padding: 2px 0px;
                                    }
                                }
                            }
                        }
                    }
                }
                .navigation__button{
                    z-index: 999;
                    position: relative !important;
                    // left: 120px;
                    // bottom: 1px;
                 } 
            }
        }
    }
  }

//    Nav bar black
  .navbar-black{
 
  

    .navbar-container{

        
     
        .navigation__nav{
            height: 100%;
            .logo-image{
                width: 140px;
            }
        }
        .navlist{
            .burger-menu-li{
                width: 39px;
            }
            .navigation{
                .navigation__nav{
                    .social-burger{
                        margin: auto;
                        margin-top: 82vh;
                      }                
                }
                .navigation__list{
                    width: 100%;
                    transform: translate(-50%, 22%);
                }
                .navigation__item{
                    margin: 0;
                    transition: auto;
                    ul{
                        li{
                            padding: 4px 0px;
                            @include smallmobile{
                                padding: 1px 0px;
                            }
                            .atag{
                                font-size: 18px;
                                @include smallmobile{
                                    font-size: 14px;
                                }
                            }
                            .dropdown-menus{
                                li{
                                    font-size: 12px;  
                                    padding: 3px 0px;
                                    @include smallmobile{
                                        padding: 2px 0px;
                                    }
                                }
                            }
                        }
                    }
                }
                .navigation__button{
                    position: relative !important;
                    top: -7px;
                    left: 14px;
                 } 
            }
        }
    }
    
  }


}

@include mobile{
    .navitem{
        display: none !important;
    }  
}
@include tablet{
    .navitem{
        display: none !important;
    }  
}
@include desktopsmall{
    .navitem{
        display: none !important;
    }  
    .navbar-transparent{
    .navlist{
        margin: auto 70% !important;
     }
    }
}
@include desktop{
    .navitem{
        display: flex !important;
    }  
    .navbar-transparent{
        .navlist{
            margin: 5px 40px 0px auto !important;
         }
   }
}
  