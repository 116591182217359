@import "../../assets/app.scss";

.hotel-faq-wrapper {
    background-color: #0f2538;
    padding: 50px 0px;
    .arrow-icon {
        width: 12px;
        height: 12px;
        transform: rotate(90deg);
        margin-top: 6px;
        position: absolute;
        left: 99%;
        top: 0%;
        bottom: 0%;
        @include mobile{
            margin-left: 16px;
        }
        &.rotate {
            transform: rotate(270deg);
            position: absolute;
            left: 99%;
            top: 0%;
            bottom: 0%;
            margin-top: 6px;
        }
    }
    .hotel-faq-title {
        position: relative;
        height: 17px;
        left: 0%;
        right: 0%;
        font-family: "Gilroy";
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 2.33333px;
        text-transform: uppercase;
        color: #ede2d4;
        margin-bottom: 10px;
        @include mobile{
            height: unset;
            top: 46px;
            margin-bottom: 64px;
        }
    }
    .hotel-faq-subtitle {
        position: relative;
        height: 50px;
        left: 0%;
        right: 0%;
        font-family: "TFArrow";
        font-style: normal;
        font-size: 40px;
        line-height: 50px;
        text-align: center;
        color: #ffffff;
        @include mobile{
            height: 40px;
            font-size: 34px;
            line-height: 40px;
        }
    }
    .question-description {
        position: relative;
        font-family: "TFArrow";
        font-style: normal;
        font-size: 16px;
        line-height: 26px;
        text-align: left;
        color: #ffffff;
        margin-top: 20px;
        width: 916px;
        cursor: pointer;
        margin:16px auto 0 auto;
        @include mobile{
            width: 84%;
            margin-left: 16px;
        }
        @include tablet{
            font-size: 18px;
        }
    }
    .border-bottom-label {
        width: 916px;
        height: 0px;
        top: 226px;
        margin: 0 auto;
        border-bottom: 1px solid #c6d8e1;
        margin-top: 15px;
        @include mobile{
            width: 92%;
            margin-top: 16px;
        }
        &.border-bottom-label-wrapper {
            width: 916px;
            height: 0px;
            top: 226px;
            margin: 0 auto;
            border-bottom: 1px solid #c6d8e1;
            @include mobile{
                width: 92%;
            }
        }

        
    }
    .answer-description {
        position: relative;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 27px;
        color: #ffffff;
        text-align: left;
        width: 916px;
        margin: 10px auto 15px;
         @include mobile{
             width: 93%;
            margin-top: 9%;
            left: 0%;
            word-break: break-all;
         }
    }
}
.tablet-device{
    .hotel-faq-wrapper{
        .hotel-faq-title{
            height: unset;
        }
        .hotel-faq-subtitle{
            font-size: 37px;
            line-height: 44px;
        }
        .question-description{
            width: 84%;
            @media only screen  and (max-device-width: 1366px) and (orientation:portrait){
                right: 4%;
                height: 44px;
            }

        }
        .border-bottom-label{
            width: 86%;
            @media only screen  and (max-device-width: 1366px) and (orientation:landscape){
                width: 84%;
            }
            @media only screen  and (max-device-width: 1366px) and (orientation:portrait){
               margin-left: 4%;
            }
        }
        .answer-description{
            width: 84%;
            @media only screen  and (max-device-width: 1366px) and (orientation:portrait){
                right: 4%;
            }
        }
    }
}
.full-width-component.hotel-faq-wrapper{
    @include mobile{
        margin: 10px auto;
    }}