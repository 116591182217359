@import "../../assets/app.scss";

@include desktoplarge {
    .col-xll-4 {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@include mobile {
    .mobile-previous-events {
        display: block;
    }

    .desktop-events-filter {
        display: none;
    }
}

@include tablet {
    .mobile-previous-events {
        display: none;
    }

    .desktop-events-filter {
        display: block;
    }
}

.previous-events-main-container {
    padding: 55px 31px 46px 31px;

    @include mobile {
        padding: 0px 31px 46px 31px;
    }

    .previous-events-filter {
        @include mobile {
            padding: 20px 0px 35px 0px;
        }

        .title {
            color: $jclub-navy;
            text-align: center;
          
            font-size: 24px;

            @include mobile {
                font-size: 20px;
            }

            @include tablet {
                font-size: 24px;
            }

            font-family: 'Times New Roman',
            Times,
            serif;
            letter-spacing: 2px;
            text-transform: uppercase;
        }

        .filters {
            .filter-label {
                font-size: 14px;

                @include mobile {
                    font-size: 12px;
                }

                @include tablet {
                    font-size: 14px;
                }
            }

            span {
                color: $jclub-navy;
                font-weight: 600;
            }

            select {
                display: inline;

                @include desktopsmall {
                    width: 35%;
                }

                @include desktop {
                    width: 30%;
                }

                height: 24px;
                padding: 0px 7px;
                text-transform: uppercase;
                font-size: 0.9rem;
                font-weight: 400;
                box-shadow: none;
                color: $jclub-grey;
            }

        }
    }
}

.no-filter-date {
    margin: auto;
}

.mobile-previous-events {
    text-align: center;

    .title {
        font-size: 18px;
        font-family: 'Times New Roman', Times, serif;
        letter-spacing: 2px;
        color: $jclub-navy;
        text-transform: uppercase;
    }

    .filter-label {
        @include mobile {
            padding: 10px 0px 5px 0px;
        }

        @include tablet {
            padding: 10px 0px;
        }

        color: $jclub-navy;
    }

    .select-date {
        color: $jclub-grey;
        width: 65%;
        margin: auto;
        border-radius: 8px;
        height: 34px;
        box-shadow: none;
        font-size: 13px;
    }
}

.previous-all-events {
    @include mobile {
        padding: 0px;
    }

    padding: 2rem 5rem 2rem 5rem;

    .previous-all-events-slider {
        padding: 20px 5px;

        @include mobile {
            padding: 20px 5px 5px 5px;
        }

        @include tablet {
            padding: 20px 5px;
        }

        .profile {
            .events-bg-image {
                width: 100%;

                @include mobile {
                    height: 145px;
                }

                @include tablet {
                    height: 190px;
                }

                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
            }

            .card-body {
                padding: 0px;
                border-bottom: 2px solid #c8d0d3;
                border-left: 2px solid #c8d0d3;
                border-right: 2px solid #c8d0d3;

                @include mobile {
                    border: none;
                }

                .mobile-title {
                    color: $jclub-navy;
                    text-align: center;
                    font-size: 10px;
                    font-weight: 700;

                    @include mobile {
                        padding: 13px 0px 0px 0px;
                        display: block;
                    }

                    @include tablet {
                        padding: 7px 0px 0px 0px;
                        display: none;
                    }

                    margin: 0px;
                }

                .title {
                    margin: 0;
                    font-weight: 600;
                    text-align: center;
                    margin: 0;
                    font-family: Arial, Helvetica, sans-serif;
                    color: $jclub-navy;
                    background: white;
                    padding: 9px 0px;
                    position: relative;
                    top: -18px;
                    left: 19%;
                    font-size: 12px;
                    letter-spacing: 0.5px;
                    width: 61%;
                }

                .border-bottom {
                    width: 13%;
                    margin: auto;
                    padding: 0px;
                    border-bottom: 2px solid #c8d0d3 !important;
                    position: relative;
                    bottom: 15px;
                }

                @include mobile {
                    .mobile_final_description {
                        display: block;
                    }

                    .desktop_final_description {
                        display: none;
                    }
                }

                @include tablet {
                    .mobile_final_description {
                        display: none;
                    }

                    .desktop_final_description {
                        display: block;
                    }
                }

                .description {
                    text-align: center;
                    font-family: Arial, Helvetica, sans-serif;
                    color: $jclub-navy;
                    font-size: 13px;
                    padding: 0px 20px 0px 20px;
                    letter-spacing: 0.3px;
                    line-height: 1.6;
                    height: 60px;

                    @include mobile {
                        height: 40px;
                    }

                    @include tablet {
                        height: 60px;
                    }
                }

                .events {
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: 600;
                    color: $jclub-grey;
                    text-align: center;
                    letter-spacing: 0.5px;
                    font-size: 15px;
                    text-transform: uppercase;
                    padding: 12px 0px 0px 0px;
                }

                .events-showdate {
                    @include mobile {
                        display: none;
                    }

                    @include tablet {
                        display: block;
                    }

                    font-family: Arial,
                    Helvetica,
                    sans-serif;
                    font-weight: 600;
                    color: $jclub-grey;
                    text-align: center;
                    letter-spacing: 0.5px;
                    font-size: 15px;
                    text-transform: uppercase;
                    padding: 12px 0px 0px 0px;

                    @include mobile {
                        padding: 12px 0px 0px 0px;
                    }

                    @include tablet {
                        padding: 12px 0px 12px 0px;
                    }
                }

                .main-link {

                    padding: 0px 10px 7px 10px;

                    .info-link {
                        width: 100%;
                        display: inline-block;
                        padding: 0px;
                        margin: 0px;
                        text-align: center;
                        font-weight: 600;
                        font-family: Arial;
                        border-top: 2px solid #c8d0d3;

                        p {
                            padding: 0px;
                            margin: 0px;
                            position: relative;
                            top: 3px;
                            padding: 4px 0px;
                        }
                    }

                    .book-link {
                        // width: 50%;
                        display: inline-block;
                        padding: 0px;
                        margin: 0px;
                        text-align: center;
                        font-weight: 600;
                        font-family: Arial;
                        border-top: 2px solid #c8d0d3 !important;

                        p {
                            padding: 0px;
                            margin: 0px;
                            position: relative;
                            top: 3px;
                            padding: 4px 0px;
                        }
                    }
                }

                @include mobile {
                    .title {
                        display: block;
                    }

                    .border-bottom {
                        display: block;
                    }

                    .description {
                        font-size: 11px;
                        letter-spacing: 0;
                        line-height: 1.3;
                        padding: 3px 8px 0px 8px;

                    }

                    .date {
                        display: none;
                    }

                    .main-link {
                        padding: 0px 10px 7px 10px;

                        .info-link {
                            border-top: none;

                            p {
                                padding: 0px 0px;
                                font-size: 10px;
                                top: 0px;
                                color: $jclub-grey;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (min-width:300px) and (max-width:821px) {
    .mobile-filter-adjust-event {
        text-align: center;
        padding: 0px 0px 34px 0px;

        @include tablet {
            padding: 0px 0px 15px 0px;
        }

        @include desktopsmall {
            padding: 0px 0px 34px 0px;
        }

        select {
            @include tablet {
                margin: 15px 0px 0px 0px !important;
            }

            @include desktopsmall {
                margin: 15px 0px 35px 0px !important;
            }

            width: 35% !important;
            height: 24px !important;
        }
    }
}