@import "../../../assets/app.scss";

.top-level{
    //   ::-webkit-scrollbar {
    //     display: none;
    //   }
    .modal-dialog-centered {
        min-height: calc(100% - 1rem);
    }
    .lightbox{
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.6);
        width: 100%;
        height: 100vh;
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        transition: all 200ms ease-out;
    
        .box-wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100vh;
            padding: 15px;
    
            .box{
                width: 600px;
                height: 400px;
                background-color: darkgrey;
                transform: scale(0);
                transition: all 200ms ease-in-out;
                padding: 10px;
                box-shadow: 0 3px 9px rgba(0,0,0,0.1);
                position: relative;
    
                .close-btn{
                    position: relative;
                    z-index: 100;
                    font-size: 30px;
                    color: #ccc;
                    left: 100%;
                    top:0;
                    border: 2px solid #ccc;
                    border-radius: 50%;
                    display: block;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    line-height: 35px;
                    margin-left: 10px;
                    cursor: pointer;
                    transition: all 200ms linear;
                    display: none;
                }
            }
        }
    }
    .lightbox-effect {
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.8);
        width: 100%;
        height: 103vh;
        z-index: 99;
        opacity: 1;
        visibility: visible;
        transition: all 200ms ease-out;
        overflow: auto;
        .box-wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            // height: 100vh;
            padding: 15px;
    
            .box-effect {
                min-width: 400px;
                @include tablet{
                    max-width: 100%;
                }
                @include desktopsmall{
                    max-width: 90%;
                }
                @include desktop{
                    max-width: 85%;
                }
                @include desktoplarge{
                    max-width: 90%;
                }
               
                transform: scale(1);
                transition: all 200ms ease-in-out;
                padding: 10px;
                box-shadow: 0 3px 9px rgba(0,0,0,0.1);
                position: relative;
                
                .modal-card{
                    padding: 10px;
                    background: white;
                    
                    .modal-inner-card{
                        border: 2px solid darkgrey;
                        .modal-image-wrapper{
                            // object-fit: cover;
                            .modal-gradient-image{
                                width: 94.7%;
                                height: 100%;
                                position:absolute;
                                z-index: 1;
                            }
                            .slick-slider{
                                .slick-dots {
                                    bottom: 5px;
                                    z-index: 9;
                                    li {
                                        margin:0px;
                                        &.slick-active{
                                            button{
                                              &::before{
                                                color: white;
                                                font-size: 9px;
                                                opacity: 1;
                                              }
                                            }
                                          }
                                    }
                                    li button {
                                        background: transparent;
                                        border: 2px solid #fff;
                                        border-radius: 50%;
                                        height: 9px;
                                        line-height: 0;
                                        opacity: 1;
                                        padding: 0;
                                        width: 9px;
                                    }
                                    li button:before {
                                        color: transparent;
                                        line-height: 11px;
                                        opacity: 1;
                                        width: 8px;
                                    }
                                }
                            } 
                            .modal-image{

                                @include tablet{
                                    height: 400px;
                                }
                                @include desktopsmall{
                                    height: 510px;
                                }
                                @include desktop{
                                    height: 550px;
                                }
                                @include desktoplarge{
                                    height: 550px;
                                }
                                // height: 100%;
                                object-fit: cover;
                                // object-position: 0%;
                            }
                        }
                        .modal-content-new{
                            .modal-content-title{
                                align-items: center;
                                display: flex;
                                justify-content: center;
                                @include tablet{
                                    font-size: 16px;
                                    padding: 10px 0px;
                                }
                                @include desktopsmall{
                                    font-size: 16px;
                                    padding: 18px 0px;
                                }
                                @include desktop{
                                    font-size: 18px;
                                    padding: 18px 0px;
                                }
                                @include desktoplarge{
                                    font-size: 20px;
                                    padding: 16px 0px;
                                }
                                padding: 20px 0px;
                                font-size: 24px;
                                font-weight: 600;
                                text-transform: uppercase;
                                color: $jclub-grey;  
                            }
                            
                            .findout-more{
                                .btn{
                                border-radius: 10px;
                                background: $jclub-grey;
                                color: $jclub-navy;
                                border: 1px solid $jclub-grey;
                                width: 48%;
                                max-width: 150px;
                                text-transform: uppercase;
                                font-size: 13px;
                                @include tablet{
                                    font-size: 11px;
                                    padding: 3px 0px;
                                    width: 40%;
                                    margin: 10px 0px 0px 0px;
                               }
                               @include desktopsmall{
                                font-size: 12px;
                                padding: 5px 0px;
                                width: 48%;
                                margin: 0;
                               }
                               @include desktop{
                                font-size: 13px;
                                width: 48%;
                               }
                                font-weight: 600;
                                padding: 6px 0px;
                                box-shadow: none;
                                }
                            }

                            .findout-more-wide{
                                .btn{
                                    border-radius: 10px;
                                    background: $jclub-grey;
                                    color: $jclub-navy;
                                    border: 1px solid $jclub-grey;
                                    width: 48%;
                                    max-width: 190px;
                                    text-transform: uppercase;
                                    font-size: 13px;
                                    @include tablet{
                                        font-size: 11px;
                                        padding: 3px 0px;
                                        width: 55%;
                                        margin: 10px 0px 0px 0px;
                                   }
                                   @include desktopsmall{
                                    font-size: 12px;
                                    padding: 5px 0px;
                                    width: 48%;
                                    margin: 0;
                                   }
                                   @include desktop{
                                    font-size: 13px;
                                    width: 48%;
                                   }
                                    font-weight: 600;
                                    padding: 6px 0px;
                                    box-shadow: none;
                                    }
                            }

                            .modal-info-description-with-findmore{
                                margin-left: auto;
                                margin-right: auto;
                                overflow-y: auto;
                                @include tablet{
                                    height: 242px;
                               }
                               @include desktopsmall{
                                    height:350px;
                               }
                               @include desktop{
                                   height: 386px;
                               }

                            
                                padding: 0px 22px;
                                    &::-webkit-scrollbar {
                                    width: 5px;
                                    }
                                    &::-webkit-scrollbar-track {
                                    background: #f1f1f1;
                                    box-shadow: inset 0 0 5px grey;
                                    border-radius: 10px;
                                    }
                                    
                                    &::-webkit-scrollbar-thumb {
                                    background: #888;
                                    border-radius: 5px;
                                    }
                                
                                @include tablet{
                                    font-size: 11px;
                                }
                                @include desktopsmall{
                                    font-size: 13px;
                                }
                                @include desktop{
                                    font-size: 14px;
                                }
                                @include desktoplarge{
                                    font-size: 16px;
                                }
                                font-size: 16px;
                                // max-width: 800px;
                                p{
                                    margin: 0px;
                                    margin: 0px 0px 10px 0px;
                                }


                              

                            }
                            .next-item-findmore{
                                font-family: 'Times New Roman', Times, serif;
                                display: flex;
                                justify-content: space-between;
                                padding: 14px 12px 0px 12px;
                                font-weight: 600;
                                 @include tablet{
                                    font-size: 10px;
                                    padding: 30px 0px 0px 0px;
                                }
                                @include desktopsmall{
                                    font-size: 12px;
                                    padding: 14px 12px 0px 12px;
                                }
                                @include desktop{
                                    font-size: 13px;
                                }
                                @include desktoplarge{
                                    font-size: 14px;
                                }
                                font-size: 14px;
                                // .left-arrow{
                                //     position: absolute;
                                //     bottom: 1px;
                                //     left: 12px;
                                // }
                                // .right-arrow{
                                //     position: absolute;
                                //     bottom: 1px;
                                //     right: 15px;
                                // }
                                p{
                                    cursor: pointer;
                                    letter-spacing: 0.5px;
                                    margin: 0;
                                }
                                .arrow-icon{
                                    margin-bottom: 2px;
                                    cursor:pointer;
                                    font-size: 1.5rem;
                                }
                            }
                            
                            .modal-content-description{
                                // display: flex;
                                justify-content: center;
                                text-align: center;
                                align-content: center;
                                padding: 1px 12px;
                                @include mobile{
                                    padding: 20px;  
                                }


                                .modal-info-description{
                                    margin-left: auto;
                                    margin-right: auto;
                                    overflow-y: auto;
                                    @include tablet{
                                        height: 298px;
                                   }
                                   @include desktopsmall{
                                        height:393px
                                   }
                                   @include desktop{
                                     height: 430px;
                                   }

                                
                                    padding: 0px 22px;
                                        &::-webkit-scrollbar {
                                        width: 5px;
                                        }
                                        &::-webkit-scrollbar-track {
                                        background: #f1f1f1;
                                        box-shadow: inset 0 0 5px grey;
                                        border-radius: 10px;
                                        }
                                        
                                        &::-webkit-scrollbar-thumb {
                                        background: #888;
                                        border-radius: 5px;
                                        }
                                    
                                    @include tablet{
                                        font-size: 11px;
                                    }
                                    @include desktopsmall{
                                        font-size: 13px;
                                    }
                                    @include desktop{
                                        font-size: 14px;
                                    }
                                    @include desktoplarge{
                                        font-size: 16px;
                                    }
                                    font-size: 16px;
                                    // max-width: 800px;
                                    p{
                                        margin: 0px;
                                        margin: 0px 0px 10px 0px;
                                    }
                                }


                                .modal-overview{
                                    text-align: center;
                                    .modal-overview-title{
                                        margin-top: 20px;
                                        font-weight: bold;
                                    }
                                    .overview-point-list{
                                        list-style: none;
                                    }
                                }                    
                            }
                            .next-item{
                                font-family: 'Times New Roman', Times, serif;
                                display: flex;
                                justify-content: space-between;
                                padding: 14px 12px 0px 12px;
                                font-weight: 600;
                                 @include tablet{
                                    font-size: 10px;
                                    padding: 22px 0px 0px 0px;
                                }
                                @include desktopsmall{
                                    font-size: 12px;
                                    padding: 14px 12px 0px 12px;
                                }
                                @include desktop{
                                    font-size: 13px;
                                }
                                @include desktoplarge{
                                    font-size: 14px;
                                }
                                font-size: 14px;
                                // .left-arrow{
                                //     position: absolute;
                                //     bottom: 1px;
                                //     left: 12px;
                                // }
                                // .right-arrow{
                                //     position: absolute;
                                //     bottom: 1px;
                                //     right: 15px;
                                // }
                                p{
                                    cursor: pointer;
                                    letter-spacing: 0.5px;
                                    margin: 0;
                                }
                                .arrow-icon{
                                    margin-bottom: 2px;
                                    cursor:pointer;
                                    font-size: 1.5rem;
                                }
                            }
                        }
                        
                    }
                }
                .close-btn{
                    z-index: 100;
                    font-size: 25px;
                    color : $jclub-grey;
                    border-radius: 50%;
                    display: block;
                    text-align: center;
                    line-height: 24px;
                    cursor: pointer;
                    transition: all 200ms linear;
                    position: absolute;
                    right: 3px;
                }
            }
        }
    }
}
