@import "../../../assets/app.scss";
@include top-bottom-animation;

.in-viewport02.cg-whatwedo{
	opacity:1;
	transform:translateY(0px);
}

.card-list-main{
    transform:translateX(-20px);
    opacity:0;
    transition:transform 500ms ease-in;  
}

.card-parent{

    .card{
        padding: 15px;
        background-color: whitesmoke;
        box-shadow: 0 1px 1px #f7f1f0;
        border-radius: 0px;
        .inner-card{
            border: 2px solid darkgray;
           
            .image-wrapper{
                width: 100%;
                height: 250px;
                @include desktoplarge{
                    height: 350px;
                }
                margin-bottom: 10px;
                overflow: hidden; // image zoom purpose
            }
    
            .image-wrapper img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: all 0.4s ease;
            }
    
            .content{
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 180px;
                margin-bottom: 20px;
                h1{
                    font-size: 16px;
                    font-weight: 900;
                    margin-bottom: 10px;
                    color: #444;
                }
                p{
                    font-size: 14px;
                    @include tablet{
                        font-size: 13px;
                    }
                    @include desktopsmall{
                        font-size: 11px;
                    }
                    @include desktop{
                        font-size: 14px;  
                    }
                    @include desktopxtralarge{
                        font-size: 16px;
                    }
                    line-height: 1.5;
                    color: #555;
                }
                .content-title{
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    padding: 10px 0px 0px;
                    @include tablet{
                        font-size: 20px;
                    }
                    @include desktopsmall{
                        font-size: 20px;
                    }
                    @include desktop{
                        font-size: 24px;
                    }
                    font-size: 24px;
                    // font-weight: 600;
                    text-transform: uppercase;
                    color: $jclub-grey;
                    font-family: 'Times New Roman', Times, serif;
                    letter-spacing: 2px;
                    text-align: center;
                    height: 100px;
                    padding:50px;
                    }
                .content-description{
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    align-content: center;
                    padding: 10px 12px;
                    font-weight: 600;
                        @include tablet{
                            font-size: 13px;
                            padding: 10px 10px;
                        }    
                        @include desktopsmall{
                            font-size: 12px;
                            padding: 10px 10px;
                        }
                        @include desktop{
                            font-size: 14px;
                            padding: 10px 12px;
                        }
                        p{
                            margin-bottom: 0px;
                        }
                    }
            }

            .read-more-button{
                display: flex;
                justify-content: center;
                margin-bottom: 5px;
                height: 30px;
                button{
                    background: inherit;
                    border: none;
                    outline:none;
                    font-weight: 700;
                    color: $jclub-grey;
                }
                
            }
        }
    }

    .inner-card:hover{
        border: 2px solid whitesmoke;
    }

    
  
    .inner-card::before, .inner-card::after{
        content:"";
        width: 0;
        height: 2px;
        position: absolute;
        transition: all 0.8s linear;
        background: $jclub-grey;
    }
    
                
    .inner-card  .content::before, .inner-card .content::after{
        content:"";
        width:2px;
        height:0;
        position: absolute;
        transition: all 0.8s linear;
        background: $jclub-grey;
    }
    // Horizontal line
    @media screen and (min-width:$tablet-min-width) and (max-width:$tablet-max-width){
        .inner-card:hover::before, .inner-card:hover::after{
            width: calc(97% - 21px);
        }
    }
    // Horizontal line
    @media screen and (min-width:1250px) and (max-width:1350px){
        .inner-card:hover::before, .inner-card:hover::after{
            width: calc(97% - 21px);
        }
    }
    // // Horizontal line
    @media screen and (min-width:1350px) and (max-width:1600px){
        .inner-card:hover::before, .inner-card:hover::after{
            width: calc(97% - 17px);
        }
    }
    //  // Horizontal line
     @media screen and (min-width:1600px)  and (max-width:2200px) {
        .inner-card:hover::before, .inner-card:hover::after{
            width: calc(97% - 21px);
        }
    }
    // vertical line
    @media screen and (min-width:$tablet-min-width) and (max-width:$tablet-max-width){
        .inner-card:hover .content::before, .inner-card:hover .content::after{
            height: calc(97% - 14px);
        }
    }

    // vertical line
    @media screen and (min-width:1280px){
        .inner-card:hover .content::before, .inner-card:hover .content::after{
            height: calc(97% - 14px);
        }
    }
    // vertical line
    @media screen and (min-width:1550px){
        .inner-card:hover .content::before, .inner-card:hover .content::after{
            height: calc(98% - 19px);
        }
    }
    // vertical line
    @media screen and (min-width:2000px){
        .inner-card:hover .content::before, .inner-card:hover .content::after{
            height: calc(98% - 19px);
        }
    }
        
    
    
    .inner-card:after{
        left: 15px;
        bottom: 15px;
        transition-delay: 0s;
    }
    
    .inner-card .content::after{
        transition-delay: 0s;
        right: 14.5px;
        bottom: 15px;
      }
    
    .inner-card::before{
        right: 15px;
        top: 14.5px;
        transition-delay: 0s;
    }
    
    .inner-card .content::before{
        transition-delay: 0s;
        left: 14.5px;
        top: 15px;
      }
      
    
    .inner-card:hover::after{
        transition-delay: 0s;
     }
    
     .inner-card:hover .content::after{
        transition-delay: 0s;
      }
    
    .inner-card:hover::before{
        transition-delay: 0s;
     }
    
     .inner-card:hover .content::before{
        transition-delay: 0s;
      }
      
       .card:hover .card-image{
            transform: scale(1.20);
            transition: transform 0.4s ease;
       }

}




 