@import "../../../assets/app.scss";

.newslist{
    padding: 50px 0px 0px 0px;
    .container-fluid{
        width: 90%;
        padding: 50px 0px;
        .news-left{
            padding: 0px 40px 0px 0px;
            @include tablet{
                padding: 0px 30px 0px 0px;
            }
            @include desktopsmall{
                padding: 0px 40px 0px 0px;
            }
            .show-on{
                display: block;
            }
            .show-off{
                display: none;
            }
            .no-posts{
                margin: 0 auto;
            }
        }
        @include tablet{
            .news-right{
                padding: 0px 25px 0px 0px;
            }
         }
         .show-more{
             font-weight: 600;
             font-size: 14px;
             font-family: Arial, Helvetica, sans-serif;
             cursor: pointer;
         }
        .news-column{
            margin: 0px 0px 41px 0px;
         }
        .news-bg-image{
            width: 100%;
            @include tablet{
                height: 150px;
            }
            @include desktopsmall{
                height: 170px;
            }
            @include desktoplarge{
                height: 200px;
            }

            background-repeat: no-repeat;
            background-position: center center;
            // transition: all 1000ms ease;
            background-size: cover;
        }
        .card{
            padding: 0px 14px;
            border: none;
            .card-body{
                padding: 13px 0px;
                border: none;
                text-align: center;
                font-family: Arial, Helvetica, sans-serif;
                .card-title{
                    font-size: 14px;
                    @include tablet{
                        font-size: 11px;
                    }
                    @include desktopsmall{
                        font-size: 11x;
                    }
                    @include desktop{
                        font-size: 12px;
                    }
                    @include desktoplarge{
                        font-size: 14px;
                    }
                    font-weight: 600;
                    margin: 0px;
                    padding: 6px 0px 4px 0px;
                }
                .card-description{
                    font-size: 14px;
                    @include tablet{
                        font-size: 11px;
                    }
                    @include desktopsmall{
                        font-size: 11x;
                    }
                    @include desktop{
                        font-size: 12px;
                    }
                    @include desktoplarge{
                        font-size: 14px;
                    }
                    margin: 0px;
                    padding: 4px 0px 0px 0px;
                    text-transform: capitalize;
                }
                .month-show{
                    font-size: 13px;
                    color: $jclub-navy;
                    font-weight: 600;
                    @include tablet{
                        font-size: 11px;
                    }
                    @include desktopsmall{
                        font-size: 11x;
                    }
                    @include desktop{
                        font-size: 12px;
                    }
                    @include desktoplarge{
                        font-size: 13px;
                    }
                    margin: 0px;
                    padding: 0px 0px;
                    text-transform: capitalize;
                }
                .card-readmore{
                    font-size: 14px;
                    @include tablet{
                        font-size: 11px;
                    }
                    @include desktopsmall{
                        font-size: 12px;
                    }
                    @include desktop{
                        font-size: 12px;
                    }
                    @include desktoplarge{
                        font-size: 14px;
                    }
                    font-weight: 600;
                    color: #ABB8BD;
                    margin: 0px;
                    padding: 4px 0px;
                }
            }
        }
        .search-news{
            .inside-search{
                .search-close{
                    position: absolute;
                    top: 7px;
                    right: 25px;
                    cursor: pointer;
                }
                .search-close-on{
                    display: block;
                }
                .search-close-off{
                    display: none;
                }
            }
            .title{
                color: $jclub-grey;
                font-family: 'Times New Roman', Times, serif;
                font-size: 22px;
                letter-spacing: 1px;
                margin: 0;
            }
            .search-holder{
                color: $jclub-grey;
                box-shadow: none;
                border: 1px solid $jclub-grey;
                border-radius: 10px;
                height: calc(1.1em + 0.75rem + 2px);
                margin: 5px 0px;
            }
            .search-holder::placeholder{
                color: $jclub-grey;
                font-size: 14px;
                letter-spacing: 0.5px;
            }
            .go-search{
                background: $jclub-grey;
                border: none;
                padding: 4px 18px;
                margin: 4px -20px;
                border-radius: 9px;
                box-shadow: none;
                color: $jclub-navy;
            }
        }
        .categories{
            .title{
                color: $jclub-grey;
                font-family: 'Times New Roman', Times, serif;
                font-size: 22px;
                letter-spacing: 1px;
                margin: 0;
                margin-top: 15px;
                text-transform: uppercase;
            }
            ul{
                margin: 0;
                padding: 0px 4px;
                li{
                    font-size: 16px;
                    color: $jclub-grey;
                    list-style: none;
                    padding: 1px 0px;
                    cursor: pointer;
                }
                .selected{
                    color: $jclub-navy;
                    font-weight: 600;
                }
            }
        }
        .older-post{
            padding: 2em 0em;
            .title{
                color: $jclub-grey;
                font-family: 'Times New Roman', Times, serif;
                font-size: 22px;
                letter-spacing: 1px;
                margin: 0;
                text-transform: uppercase;
            }
            .show-months{
                ul{
                    margin: 0;
                    padding: 15px 4px;
                    li{
                        font-size: 16px;
                        color: $jclub-grey;
                        list-style: none;
                        padding: 1px 0px;
                        cursor: pointer;
                    }
                    .selected{
                        color: $jclub-navy;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}