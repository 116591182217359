@import "../../assets/app.scss";

.testimonial-main{
    padding: 50px 0px;
    .slick-prev{
        left: -31px !important;
     }
    .slick-next{
        right: -31px !important;
    }

    @include desktopsmall{
        .mobile-view{
            display: none;
        }
        .desktop-view{
            display: block;
        }
    }

    @include desktoplarge{
        .mobile-view{
            display: none;
        }
        .desktop-view{
            display: block;
        }
    }

    @include desktopxtralarge{
        .mobile-view{
            display: none;
        }
        .desktop-view{
            display: block;
        }
    }

    @include mobile{
        .mobile-view{
            display: block;
        }
        .desktop-view{
            display: none;
        }
    }
    @include tablet{
        .testimonial-overview{
            zoom: 0.9;
        }
    }

    @media screen and (min-width:768px) and (max-width:1200px){

            .testimonial-overview .overview-paragraph{
                width: 160%;
            }
            .testimonial-overview .testimonial-stage1-title {
                height: 252px;
            }
            .testimonial-overview .overview-paragraph .border-pad .paragraph{
                font-size: 14px;
            }
            .testimonial-overview .overview-paragraph {
                position: relative;
                top: 84px;
            }

    }

    @media screen and (min-width:992px) and (max-width:1150px){
        .testimonial-overview-paragraph{
            max-width: 45%;
        }
    }


 
}



.testimonial-overview{
      .background-img{
        width: 590px;
        height: 570px;
        position: relative;
        right: 5px;
        background-repeat: no-repeat;
        background-position: left;
        transition: all 1000ms ease;
        background-size: cover;
      }

     .banner-img{
         position: relative;
         max-width: 90%;
     }
     .testimonial-stage1-title{
        color: $jclub-facilities-title;
        font-family: 'Times New Roman', Times, serif;
        background: $jclub-facilities;
        position: absolute;
        left: -39px;
        top: 50px;
        height: 298px;
        .testimonial-main-title{
            transform: rotate(-90deg);
            font-size: 50px;
            position: relative;
            top: 220px;
            left: -154px;
            letter-spacing: 4px;
            z-index: 9;
            @include tablet{
                top: 93px;
                left: -103px;
                font-size: 36px;
            }
             @include desktop{
                top: 238px;
                font-size: 50px;
                left: -161px;
            }

        }
     }

    

     .overview-paragraph{
        position: relative;
        top: 110px;
        right: 145px;    
        width: 137%;
        background: white;
        padding: 14px 14px;
        .border-pad{
            padding: 7px 11px;
            border: 1px solid #ABB8BD;
            padding: 25px 35px 0px 35px;
            .arrow-settings{
                .left-arrow{
                    width: 28px;
                    cursor: pointer;
                }
                .right-arrow{
                    width: 28px;
                    cursor: pointer;
                }
            }
            .paragraph{
                height: auto;
                overflow-y: auto;
                overflow-x: hidden;
                position: relative;
                z-index: 99;
                max-height: 120px;
                padding: 0px 12px 0px 0px;

                &::-webkit-scrollbar {
                    width: 5px;
                    }
                    &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                    box-shadow: inset 0 0 5px grey;
                    border-radius: 10px;
                    }
                    
                    &::-webkit-scrollbar-thumb {
                    background: #888;
                    border-radius: 5px;
                    }

                font-family: Arial, Helvetica, sans-serif;
                font-size: 14px;
                @include desktopsmall{
                    font-size: 12px;
                }
                @include desktop{
                    font-size: 14px;
                }
                color: rgba(0, 0, 0, 0.85);
                letter-spacing: 1px;
            }
            .member-title{
                font-family: 'Times New Roman', Times, serif;
                text-transform: uppercase;
                letter-spacing: 2px;
                @include desktopsmall{
                    font-size: 1.3em;
                }
            }
        }
     }

     
 }