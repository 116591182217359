a[target='_blank']
body, html {
  background-color: #ffffff !important;
  transition: all 5000s ease;
}
h1,h2,h3{
  font-size: 24px;
}
/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData, .scpm { display: none !important; }