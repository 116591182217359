@import "../../assets/app.scss";

.event-gallery-modal{
   
   //   display: flex;
   //   justify-content: space-between;
       .profile-bg-image{
            width: 100%;
            height: 500px;
            @include mobile{
               height: 221px;
            }
            background-repeat: no-repeat;
            background-position: center center;
            transition: all 1000ms ease;
            background-size: cover;
            margin: -2px 0px;
      }
     .gallerymodal-img{
        height: 450px;
        background-repeat: no-repeat;
        background-position: center center;
        transition: all 1000ms ease;
        background-size: cover;
     }
     .gallery-user-settings{
        background: $jclub-navy;
        padding: 8px 9px;
        margin: -6px 0px;
         .left-close{
           display: inline;
           img{
              cursor: pointer;
              width: 30px;
           }
        }
        .right-controls{
           display: inline;
           position: absolute;
           right: 9px;
           .left-arrow{
              cursor: pointer;
              width: 22px;
              margin: 0px 19px;
           }
           .right-arrow{
              cursor: pointer;
              width:21px;
           }
        }
     }
   
 
}

.modal-backdrop.show {
   opacity: .8;
}