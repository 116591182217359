@import "../../assets/app.scss";
.personal-trainer-main-profile{
    padding: 50px 35px 30px 35px;
    .personal-trainer{
        padding: 32px 0px 0px 0px;
        .title{
            color: $jclub-grey;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 28px;
            text-align: center;
            @include times-new-roman;
        }
        .filters{
            .filter-label{
                font-size: 14px;
            }
            .mobile-filter-adjust{
                margin: 0px 10px;
            }
            span{
                color: $jclub-navy;
                font-weight: 600;
            }
            select{
                display: inline;
                width: 33%;
                height: 24px;
                padding: 0px 7px;
                text-transform: uppercase;
                font-size: 0.9rem;
                font-weight: 400;
                box-shadow: none;
                color: $jclub-grey;
            }
            
        }
    }
}
.personal-slider-main-location{
     @include mobile{
        padding-top: 1.8rem !important;
     }
     @include tablet{
        padding: 2rem 0rem 2rem 0rem !important;
     }
     @include desktopsmall{
        padding: 0rem 2rem 0rem 2rem !important;
     }    
     @include desktop{
        padding: 0rem 4rem 0rem 4rem !important;
     }
     @include desktoplarge{
        padding: 0rem 4rem 0rem 4rem !important;
     }
    .slick-prev{
        @include mobile{
            left: -30px;
        }
        left: -30px;
        top: 35%;

        img{
            width: 30px;
        }
    }
    .slick-next{
        @include mobile{
            right: -30px;
        }
        top: 35%;
        right: -30px;
        img{
            width: 30px;
            transform: scaleX(-1);
        }
    }

    .personal-slider{
        .outline-none{
            outline: none;
        }
        @include desktopsmall{
            padding: 3.5em 1.5em;
        }
        @include desktop{
            padding: 2.3em 1em 3.5em 1em;
        }
        @include desktoplarge{
            padding: 2.8em 2.5em 3.5em 2.5em;
        }

        img{
            @include smallmobile{
                height: 180px;
            }
            @include mobile{
                height: 200px;
            }
            @include tablet{
                height: 340px;
            }
            height: 240px;
            width: 340px;
            object-fit: cover;
        }
    }
    .profile{
        .title{
            font-weight: 600;
            text-align: center;
            padding: 20px 0px 0px 0px;
            margin: 0;
            font-size: 15px;
            font-family: Arial;
            color: $jclub-navy;
            @include mobile{
                height: 40px;
                 font-size: 12px;
            }
            @include smallmobile{
                height: 58px;
            }
        }
        .description{
            text-align: center;
            font-family: Arial;
            color: $jclub-navy;
            font-size: 14px;
            @include mobile{
                height: 25px;
                font-size: 12px;
                margin: 0;
            }
        }
        .Learn-more{
            font-weight: 600;
            color: $jclub-grey;
            text-decoration: underline;
            text-align: center;
            font-size: 12px;  
            @include mobile{
                font-size: 12px;
            }         
            @include tablet{
                font-size: 14px;
            }
        }
    }
}


@media screen and (min-width:300px) and (max-width:1100px)  {

    .personal-slider-main-location {
        
        .personal-slider{
            padding: 0.5em;
        }
        @include desktopsmall{
            .personal-slider{
                padding: 4em 2em;
            }  
        }
        //  padding: 0px !important;
     }
    
}

@media screen and (min-width:300px) and (max-width:821px){
    .personal-trainer-main-profile {
        padding: 55px 35px 30px 35px !important;
    }
    .personal-trainer{
        .title{
            font-size: 18px !important;
        }
        .filter-label {
            font-size: 14px !important;
        }
    }
    .mobile-filter-adjust{
        text-align: center;
        padding: 0px 0px 34px 0px;
        select{
            margin: 15px 0px 35px 0px !important;
            width: 35% !important;
            height: 24px !important;
        }
    }
}