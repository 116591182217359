@import "../../../assets/app.scss";
@include mobile{
    .no-posts{
        padding: 28px;
    }
    .newslist{
        display: none;
    }
    .news-home{
        display: none;
    }
    .news-mobile{
        display: block;
        padding: 50px 0px 3px 0px;
        .show-on{
            display: block;
        }
        .show-off{
            display: none;
        }
        .news-updates{
            text-align: center;
            .title{
                font-size: 18px;
                font-family: 'Times New Roman', Times, serif;
                letter-spacing: 2px;
                color: $jclub-navy;
                text-transform: uppercase;
            }
            .filter-label{
                padding: 10px 0px;
                color: $jclub-navy;
            }
            .select-date{
                color: $jclub-grey;
                width: 65%;
                margin: auto;
                border-radius: 8px;
                height: calc(1.4em + 0.75rem + 2px);
                box-shadow: none;
            }
        }
        .news-column-mobile{
            padding: 60px 14px 8px 14px;
        }
        .news-column-left{
            padding: 4px;
        }
        .news-column-right{
            padding: 4px;
        }
        .news-bg-image{
            width: 100%;
            height: 120px;
            background-repeat: no-repeat;
            background-position: center center;
            // transition: all 1000ms ease;
            background-size: cover;
        }
        .card{
            padding: 0px 14px;
            border: none;
            .card-body{
                padding: 13px 0px;
                border: none;
                text-align: center;
                font-family: Arial, Helvetica, sans-serif;
                .card-title{
                     @include mobile{
                        font-size: 10px;
                    }
                    font-weight: 600;
                    margin: 0px;
                    padding: 6px 0px 4px 0px;
                    height: 58px;
                    @include mobile{
                        height: 24px;
                    }
                }
                .card-description{
                    font-size: 10px;
                    margin: 0px;
                    padding: 4px 0px;
                    height: 50px;
                }
                .month-show{
                    @include mobile{
                        font-size: 10px;
                    }
                    font-weight: 600;
                    margin: 0px;
                    padding: 6px 0px 4px 0px;
                    height: 58px;
                    @include mobile{
                        height: 24px;
                    }
                }
                .card-readmore{
                    font-size: 11px;
                    font-weight: 600;
                    color: #ABB8BD;
                    margin: 0px;
                    padding: 4px 0px;
                }
            }
        }
        .show-more{
            padding: 10px 0px 0px 0px;
            font-weight: 600;
            font-size: 14px;
            font-family: Arial, Helvetica, sans-serif;
        }
    }
}
@include tablet{
    .news-mobile{
        display: none;   
    }
    .newslist{
        display: block;
    }
    .news-home{
        display: block;
    }
}