// Define all the variables here

/* :: screen widths */
$xxs-min-width: 325px;
$xxs-max-width: 576px;
$xs-min-width: 577px;
$xs-max-width: 767px;
$sm-min-width: 768px;
$sm-max-width: 991px;
$md-min-width: 992px;
$mdd-min-width: 1025px;
$mdd-max-width: 1024px;
$md-max-width: 1199px;
$lg-min-width: 1200px;
$lg-max-width: 1439px;
$xlg-min-width: 1440px;
$xxlg-min-width: 1800px;
$tablet-min-width:768px;
$tablet-max-width:1200px;


// $sm-min-width: 375px;
// $sm-max-width: 767px;
// $md-min-width: 768px;
// $md-max-width: 991px;
// $lg-min-width: 992px;
// $lg-max-width: 1400px;
// $xlg-min-width: 1441px;

/* :: color variables */
$jum-black: #000000;
$jum-dark-grey: #2F2F2F;
$jum-dark-grey-1: #1E1E1E;
$jum-grey: #8F8F8F;
$jum-light-grey: #F6F6F6;
$jum-grey-trans: rgba(143, 143, 143, 0.15);
$jum-white: #FFFFFF;
$jum-white-trans: rgba(255, 255, 255, 0.15);
$jum-white-trans2: rgba(255, 255, 255, 0.5);
$jum-gold-1: #7C6F55;
$jum-gold-2-hoverstates: #615641;
$jum-gold-2: #EDE2D4;
$jum-gold-3: #9b8d5f;
$jum-gold-1-trans: rgba(124, 111, 85, 0.15);
$jum-blue: #75ADC8;
$jclub-grey:#abb8bd;
$jclub-navy:#0E2538;
$jclub-facilities:#eef1f2;
$jclub-facilities-title:#d6b8b5;
$jclub-timebg:#10243c;
$jclub-light-gold:#eae3de;
$jclub-dark-gery:#adb9be;
$jclub-table-bg:#e5e9eb;