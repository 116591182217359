@import "../../assets/app.scss";

.hero-container {
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    // height: 800px;
    height: 100vh;
    position: relative;
    z-index: 1;
    overflow: hidden;

    ul {
        padding: 0px;
    }

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0.6) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, tranparent 100%);
        z-index: 2;
    }

    .hero-background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        height: 100vh;

        .video-control {
            display: flex;
            align-items: center;
            align-content: center;
        }

        .video-background {
            height: 100vh;
            overflow: hidden;
            position: relative;
            background-size: cover;
            background-position: 50%;
            background-repeat: no-repeat;
            width: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .video-foreground {
            height: 300%;
            top: -100%;
        }

        .video-foreground {
            position: relative;
            width: 100%;
        }

        iframe {
            // position: relative;
            // top: 0;
            // left: 0;
            width: 100%;
            height: 100%;

            @include mobile {
                width: 350%;
                left: -90%;
            }

            @include tablet {
                width: 270%;
                left: -90%;
            }

            @include desktopsmall {
                width: 130%;
                left: 0%;
                top: 0%;
            }

            @include desktop {
                width: 120%;
                left: 0%;
                top: 0%;
            }

        }

        .res-radial-bottom {
            background-image: linear-gradient(rgba(12, 36, 56, 0),
                    rgba(12, 36, 56, 0),
                    rgba(12, 36, 56, 0),
                    rgba(15, 36, 57, 0),
                    rgba(15, 36, 57, 0.15),
                    rgba(13, 36, 56, 0.30),
                    rgba(13, 36, 56, 0.75),
                    rgba(13, 36, 56, 0.96),
                    rgba(13, 38, 56, 1),
                );
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
        }

        .res-radial-top {
            background-image: linear-gradient(160deg,
                    rgba(13, 36, 56, 1),
                    rgba(13, 36, 56, 1),
                    rgba(13, 36, 56, 0.9),
                    rgba(13, 38, 56, 0.70),
                    rgba(13, 38, 56, 0.55),
                    rgba(12, 37, 56, 0.1),
                    rgba(12, 37, 56, 0),
                    rgba(12, 37, 56, 0),
                    rgba(12, 37, 56, 0),
                    rgba(15, 38, 57, 0));
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
        }

        .bottom-radial {
            background-image: linear-gradient(190deg, rgba(15, 38, 57, 0),
                    rgba(15, 38, 57, 0),
                    rgba(15, 38, 57, 0),
                    rgba(12, 37, 56, 0.28),
                    rgba(12, 37, 56, 0.7),
                    rgba(12, 37, 56, 1));
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
        }

        .top-radial {
            background-image: linear-gradient(rgba(14, 37, 56, 1),
                    rgba(14, 37, 56, 0.8),
                    rgba(14, 37, 56, 0.5),
                    rgba(12, 37, 56, 0.2),
                    rgba(12, 37, 56, 0),
                    rgba(12, 37, 56, 0),
                    rgba(12, 37, 56, 0),
                    rgba(12, 37, 56, 0),
                    rgba(12, 37, 56, 0),
                    rgba(15, 38, 57, 0));
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
        }

        .hero-video {
            width: 100%;
            height: 100vh;
            -o-object-fit: cover;
            object-fit: cover;
            background: #232a34;
            // object-position: 40% 0%;
        }

        .hero-video-fit {
            width: 100%;
            height: 100vh;
            -o-object-fit: cover;
            object-fit: cover;
            background: #232a34;
            object-position: 40% 0%;

            @include mobile {
                object-position: 5% 0%;
            }

            @include tablet {
                object-position: 15% 0%;
            }
        }

        .gradient-image {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;

            @include mobile {
                display: none;
            }
        }

        .gradient-image-mobile {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;

            @include tablet {
                display: none;
            }

            @include desktop {
                display: none;
            }
        }
    }

    .hero-content {
        z-index: 3;
        max-width: 1200px;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin-top: 70vh;
        bottom: 1rem;

        // margin-left: -25%;
        @include desktop {
            margin-bottom: 2rem;
            left: 50px;
        }

        @include mobile {
            left: 1rem;
        }

        .hero-title {
            color: #fff;
            font-size: 48px;
            text-align: center;

            @media screen and (max-width:760px) {
                font-size: 40px;

            }

            @media screen and (max-width:480px) {
                font-size: 32px;

            }
        }

        .hero-tagline {
            // margin-top:24px;
            // color:#fff;
            // font-size:24px;
            // max-width:300px;
            // padding: 0px 35px;
            // text-align:center;
            margin-top: 24px;
            color: #fff;

            max-width: 100%;
            text-align: center;
            font-family: 'Times New Roman', Times, serif;
            letter-spacing: 2px;
            line-height: 40px;

            @include top-bottom-animation;

            h1 {
                font-size: 2.5rem;

                @include mobile {
                    font-size: 1.5rem;
                    text-align: left;
                    margin-bottom: 2rem;
                }
            }

            @media screen and (max-width:480px) {
                font-size: 18px;
            }

            @media screen and (max-width:760px) {
                font-size: 24px;
                width: 100%;
                line-height: 1.2;
                text-align: left;
            }

            @include desktop {
                text-align: left;
            }

            @include desktopsmall {
                font-size: 30px;
            }

            @include desktop {
                font-size: 30px;
            }

            @include desktoplarge {
                font-size: 35px;
            }

        }

        p {
            margin: 0px;
        }
    }
}