@import "../../assets/app.scss";
.membership{
    display: block;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    // padding: 20px;
    // padding: 4rem 0 12rem 0;
    padding: 0rem 0 6rem  0;
    width: 80%;
    font-family: 'Times New Roman', Times, serif;
    @include mobile{
        width: 90%;
        padding: 0px;
    }
    .membership-container{
        .sub-info{
            text-align: center;
            margin-top: 50px;
            @include mobile{
                margin-top: 30px;
            }
            p{
                @include mobile {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    width: 80%;
                }
            }
        }
        .mobile-sub-info{
            text-align: center;
            margin-top: 30px;
            p{
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                width: 88%;
            }
        }
        .title{
            // font-size: 20px;
            display: flex;
            justify-content: center;
            // font-size: 20px;
            padding: 50px 20px;
            @include tablet{
                padding: 25px 20px 14px 20px;
            }
            @include desktop{
                padding: 0px 20px 25px 20px;
            }
            text-transform: uppercase;
            h2{
                font-size: 25px;
                color: #abb8bd;
                letter-spacing: 0.5px;
                text-align: center;
            }
        }
        .subtitle{
            display: grid;
            grid-template-columns: 0.95fr 1fr;
            line-height: 2rem;
            padding: 20px 0px;
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 0.2px;
            @include mobile{
                padding-right: 0px;
                font-size: 12px;
            }
            @include tablet{
                padding-right: 40px;
            }
            @include desktop{
                padding-right: 82px;
            }
            .subtitle-list{
                display: flex;
                justify-content: space-between;
                padding-right: 50px;
                @include mobile{
                    padding: 0px 30px;
                }
                color: $jclub-navy;
                .city{
                    height: 15px;
                    width: 15px;
                    @include mobile{
                        transform: rotate(-45deg);
                    }
                    @include tablet{
                        margin: 0 -31px;
                    }
                }
                .fit{
                    height: 15px;
                    width: 15px;
                    @include mobile{
                        transform: rotate(-45deg);
                    }
                }
                .life{
                    height: 15px;
                    width: 15px;
                    @include mobile{
                        transform: rotate(-45deg);
                    }
                    @include tablet{
                        margin: 0px -29px;
                    }
                    
                }
            }
        }
    }
    .accordion-item {
        background-color: #fff;
        border: none;
        border-top: 1px solid rgba(0,0,0,.125);
        border-bottom: 1px solid rgba(0,0,0,.125);

        .accordion-header{
            color:black;
            .accordion-button:not(.collapsed) {
                color: inherit;
                background-color: inherit;
                box-shadow: none;
            }
        }
    }
}