@import "../../assets/app.scss";

.event-gallery-main-container{
    padding: 3em 3em;
    .events-gallery-title{
        .title{
            color: $jclub-grey;
            text-align: center;
            font-size: 24px;
            font-family: 'Times New Roman', Times, serif;
            letter-spacing: 2px;
            margin: 0px;
            padding: 0px;
            padding: 0px 0px 2px 0px;
        }
        .description{
            font-family: Arial;
            color: $jclub-navy;
            text-align: center;
            font-size: 14px;
            letter-spacing: 0.5px;
            font-weight: 600;
            padding: 8px 0px;
            span{
               cursor: pointer; 
               color: $jclub-navy;
            }
            .activeclass{
                border-bottom-width: 2px;
                border-bottom-style: solid;
                border-color:  $jclub-navy;
            }
        }
    }
    
    .event-gallry-image{
        @include mobile{
            padding: 0px;
        }
        @include desktopsmall{
            padding: 1em 4em;
        }
        @include desktop{
            padding: 2em 4em;
        }
        .gallery-background-sub{
            padding: 10px 6px;
            .gallery-background-image{
                @include mobile{
                    height: 15vh;
                }
                @include tablet{
                    height: 14vh ;
                }
                 @include desktopsmall{
                    height: 30vh;
                }
                background-repeat: no-repeat;
                background-position: center center;
                transition: all 1000ms ease;
                background-size: cover;
            }
            .play-button{
                position: absolute;
                top: 36%;
                font-size: 45px;
                left: 42%;
                color: $jclub-grey;
            }
        }
    }
}









