@import "../../assets/app.scss";
.info-section{
    @include desktopsmall{
        zoom: 0.850;
     }
    @include desktop{
        zoom: 0.850;
     }
    @include desktoplarge{
        zoom: 1;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #0d2233;
    padding: 2.5rem 0 0 0;

    .info-title{
        font-size: 24px;
        text-transform: uppercase;
        color: $jclub-grey;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding: 4rem 4rem 1rem 4rem;
        font-family: 'Times New Roman', Times, serif;
        letter-spacing: 2px;
    }

    .info-text{
        font-size: 16px;
        color: whitesmoke;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        // padding: 1rem;
        margin-left: 50px;
        margin-right: 50px;
        padding-top: 3rem;
        @include tablet{
            padding-top: 1rem;
        }
        @include desktop{
            padding-top: 1rem;
        }
        .info-description{
            margin-left: auto;
            margin-right: auto;
            max-width: 740px;
            P{
                margin-bottom: 0px;
            }
        }
    }
}