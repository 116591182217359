@import "../../assets/app.scss";
.cardlist-container{
    background: #fff;
    margin: 0px;
    // padding: 50px;
    @include tablet{
        // padding: 100px;
        padding: 50px;
    }
    @include desktop{
        // padding: 4rem 80px 100px 80px;
        padding: 5rem 80px 100px 80px;
    }
    @include mobile{
        display: none;
    }
    .title{
        font-size: 25px;
        color: $jclub-grey;
        text-transform: uppercase;
        display: flex;
        // padding: 20px 0px 36px 0px;
        padding: 20px 0px 20px 0px;
        justify-content: center;
        font-family: 'Times New Roman', Times, serif;
        letter-spacing: 2px;
    }
    .grid{
        display: grid;
        // grid-template-columns: repeat(auto-fill,minmax(280px,1fr));
        // grid-template-columns: repeat(auto-fill,minmax(700px,1fr));
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        align-items: stretch;
        @include tablet{
            grid-gap: 40px;
        }
        @media screen and (min-width:768px) and (max-width:1280px) {
            grid-template-columns: repeat(auto-fill,minmax(280px,1fr));
        }
    }
}
