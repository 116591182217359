@import "../../assets/app.scss";

.timetable-main{
    
    background-color: $jclub-timebg;
    // background-repeat: none;
    background-size: cover;
    background-position: center center;
    height: 140px;
    @include mobile{
        .timetable-desktop-section{
            display: none;
        }
    }
    @include tablet{
        height: 260px;
    }
    @include desktop{
        height: 260px;
    }
    .mobile-timetable{
        text-transform: uppercase;
        color: $jclub-grey;
        position: relative;
        text-align: center;
        top: 33%;
        font-size: 24px;
        @include times-new-roman;
        letter-spacing: 3px;
        @include desktop{
            display: none;
        }
        @include tablet{
            display: none;
        }
    }
    .timetable-desktop-section{
        color: $jclub-grey;
        text-align: center;
        svg{
            font-size: 60px;
            padding: 10px 0px;
            margin:10x 0px;
        }
        .title{
            @include times-new-roman;
            letter-spacing: 3px;
            padding: 30px 0px 10px;
        }
        .timetable-icon{
            height: 68px;
            width: 85px;
            margin-bottom: 20px;
        }
        .first-p{
            font-family: Arial, Helvetica, sans-serif;
         }
        .second-p{
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            color: $jclub-grey;
            text-decoration: none;
         }
    }
}