/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
@media (min-width: 768px) {
  .article {
    padding: 40px 40px; } }

@media (min-width: 992px) {
  .article {
    padding: 40px 40px; } }

@media (min-width: 1200px) {
  .article {
    padding: 60px 60px; } }

@media (min-width: 1440px) {
  .article {
    padding: 80 80px; } }

.article .article-title {
  letter-spacing: 2px;
  width: 100%;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .article .article-title {
      font-size: 25px; } }
  @media (min-width: 992px) {
    .article .article-title {
      font-size: 27px; } }
  @media (min-width: 1200px) {
    .article .article-title {
      font-size: 32px; } }

.article .article-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: center; }

.article .article-description {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  margin-top: 30px; }
  @media (max-width: 767px) {
    .article .article-description {
      font-size: 13px; } }
  @media (min-width: 992px) {
    .article .article-description {
      font-size: 15px; } }

.article .right-column-article .col-md-4 .card-img-top {
  height: 300px;
  width: 100%;
  object-fit: cover; }

@media (min-width: 768px) {
  .article .right-column-article {
    padding-top: 50px; } }

@media (min-width: 992px) {
  .article .right-column-article {
    padding-top: 50px; } }

@media (min-width: 1200px) {
  .article .right-column-article {
    padding-top: 100px; } }

.article .right-column-article .row {
  grid-gap: 1rem;
  gap: 1rem; }

.article .right-column-article .col-md-4 {
  padding-left: 0;
  padding-right: 0; }

.article .right-column-article .card-title {
  font-size: 14px;
  font-weight: 700; }

.article .right-column-article .related {
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  letter-spacing: 2px;
  margin-bottom: 20px; }
