@import "../../assets/app.scss";

.facilities{
    @include tablet{
        // padding-top: 6rem;
        // padding-bottom: 7rem;
        padding-top: 3.5rem;
        padding-bottom: 4.7rem;
    }
    @include desktop{
        padding-top: 6rem;
        padding-bottom: 7.5rem;
    }
    .trademark{
        vertical-align: super;
        font-size: 10px;
        text-transform: lowercase;
    }
    // Slider Dots
    .slick-slider{
        // Arrow Purpose
        .slick-prev {
            left: -8.2% !important;
            @include smallmobile{
                left: -11.5% !important;
            }
            margin-top: 165px ;
        }
        .slick-next {
            right: -8.2% !important;
            @include smallmobile{
                right: -11.5% !important;
            }
            margin-top: 150px ;
        }
        // Arrow Purpose
        .slick-dots {
            bottom: 5px;
            z-index: 9;
            li {
                margin:0px;
                &.slick-active{
                    button{
                      &::before{
                        color: white;
                        font-size: 9px;
                        opacity: 1;
                      }
                    }
                  }
            }
            li button {
                background: transparent;
                border: 2px solid #fff;
                border-radius: 50%;
                height: 9px;
                line-height: 0;
                opacity: 1;
                padding: 0;
                width: 9px;
            }
            li button:before {
                color: transparent;
                line-height: 11px;
                opacity: 1;
                width: 8px;
            }
        }
    } 
    // Slider Dots

    .facilities-desktop-view{
        .left-banner{
            .facilities-vertical-background{
                background: $jclub-facilities;    
                width: 155px;
                height: 266px;
                position: absolute;
                left: -40px;
                top: 208px;
                @include desktopsmall{
                    top: 145px;
                }
                @include desktoplarge{
                    left: -40px;
                    top: 203px;
                }       
                .facilities-vertical-title{
                    transform: rotate(-90deg);
                    font-size: 44px;
                    position: relative;
                    background: unset;
                    top: 130px;
                    text-transform: uppercase;
                    z-index: 2;
                    left: -34px;
                    letter-spacing: 3px;
                    color: $jclub-facilities-title;
                    font-family: 'Times New Roman', Times, serif;
                }
            }
            .image-bg{
                position: relative;
                z-index: 1;
            }
            .facilties-content{
                border : 2px solid $jclub-grey;
                padding: 15px 75px 11px 35px;
                position: relative;
                left: 54px;
                z-index: 9;
                top: 24px;            
                .title{
                    color: $jclub-navy;
                    font-family: 'Times New Roman', Times, serif;
                    letter-spacing: 1px;
                }
                .paragraph{
                    color: $jclub-navy;
                    font-family: Arial;
                    letter-spacing: 1px;
                    font-weight: 500;
                    font-size: 14px;
                   div{
                    .trademark{
                        vertical-align: super;
                        font-size: 10px;
                        text-transform: lowercase;
                    }
                   } 
                }
            }
        }
        .right-banner{
            .bigbuild{
                position: relative;
                width: 40vh ;
                @include tablet{
                    left: 5%;
                    width: 300px;
                }
                @include desktopsmall{
                    left: 0%;
                    width: 300px !important;
                }
                @include desktop{
                    width: 51vh !important;
                }
                @include desktoplarge{
                    left: 0%;
                    width: 51vh !important;
                }
                @include desktopxtralarge{
                    left: 0%;
                    width: 51vh !important;
                }
                
            }
        }
    }


    @include desktopsmall{
        .mobile-view{
            display: none;
        }
        .desktop-view{
            display: block;
        }
    }

    @include desktoplarge{
        .mobile-view{
            display: none;
        }
        .desktop-view{
            display: block;
        }
    }

    @include desktopxtralarge{
        .mobile-view{
            display: none;
        }
        .desktop-view{
            display: block;
        }
    }

    @include mobile{
        .mobile-view{
            display: block;
        }
        .desktop-view{
            display: none;
        }
    }


}


@media screen and (min-width:$sm-min-width) and (max-width:$sm-max-width){
    .facilities-desktop-view{
        .left-banner{
            position: relative;
            right: -13px;
            .facilities-vertical-background{
                top: 52px !important;
            }
        }
        zoom: 0.9;
    }
}    

@media screen and (min-width:$md-min-width) and (max-width:1079px){
    .facilities-desktop-view{
        .left-banner{
            .facilities-vertical-background{
                left: -40px !important;
                top: 125px !important;
            }
        }
        // position: relative;
        // left: 22px;
        zoom: 0.9;
    }
}

@media screen and (min-width:1080px) and (max-width:1200px) {
    .facilities-desktop-view{
        zoom: 0.9;
        position: relative;
        left: 22px;
    }
    .facilities-vertical-background{
        left: -40px !important;
        top: 123px !important;
    }
    
}

@media screen and (min-width:1200px) and (max-width:1260px){
    .facilities-desktop-view{
        zoom: 0.9;
    }
}