@import "../../../assets/app.scss";
.arrow-icon{
    margin-bottom: 2px;
    cursor:pointer;
    font-size: 1.5rem;
}
.mobile-offer-card-wrapper{
    background-color: #dee2e6;
    // padding:50px 25px;
    padding: 4rem 20px;
    display: block;
    @include tablet{
        display: none;
    }
    @include desktop{
        display: none;
    }
    .slick-prev {
        left: -3% !important;
        @include smallmobile{
            left: -6% !important;
        }
        z-index: 1;
        margin-top: 80px;
        img{
            width: 100%;
            height: 100%;
        }
      }
      .slick-next {
        right: -3% !important;
        @include smallmobile{
            right: -6% !important;
        }
        z-index: 1;
        margin-top: 60px;
        transform: rotate(0deg);
        img{
            width: 100%;
            height: 100%;
        }
      }
    .offer-card-title{
        background-color: inherit;
        font-size: 24px;
        color: $jclub-navy;
        display: flex;
        justify-content: center;
        padding: 30px 0px;
        font-family: 'Times New Roman', Times, serif;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: 600;
        @include mobile{
            font-weight: 500;
            font-size: 20px;
        }
    }
    .offer-card-main-wrapper{
        padding: 0px 20px;
    }
    .offer-card-image{
        // background-image: url();
        transition: opacity 500ms ease 0s, visibility 500ms ease 0s;
        
        .card-image{
            width: 100%;
            object-fit: cover;
            height: 250px;
        }
    }
    .offer-card-content{
        width: 300px;
        @include smallmobile{
            width: 280px;
        }
        margin-left: auto;
        margin-right: auto;
        // height: auto;
        background-color: white;
        padding:10px;
        position: relative;
        margin-top: -50px;
        display: flex;
        .offer-inner-card{
            border: 1px solid darkgray;
            .offer-title{
                display: flex;
                justify-content: center;
                padding: 20px 0px ;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
            }
            .offer-description{
                display: flex;
                justify-content: center;
                padding: 0px 20px;
                text-align: center;
                transition: .25s ease;
                .findout-more{
                    .btn{
                    border-radius: 10px;
                    background: $jclub-grey;
                    color: $jclub-navy;
                    border: 1px solid $jclub-grey;
                    width: 60%;
                    max-width: 150px;
                    text-transform: uppercase;
                    font-size: 13px;
                    @include tablet{
                        font-size: 11px;
                        padding: 3px 0px;
                        width: 40%;
                        margin: 10px 0px 0px 0px;
                   }
                   @include desktopsmall{
                    font-size: 12px;
                    padding: 5px 0px;
                    width: 48%;
                    margin: 0;
                   }
                   @include desktop{
                    font-size: 13px;
                    width: 48%;
                   }
                    font-weight: 600;
                    padding: 6px 0px;
                    box-shadow: none;
                    }
                }

                .readmore {
                    position: relative;
                    max-height: 170px;
                    overflow: hidden;
                    // width:200px;
                    // border: solid 1px blue;
                    // padding: 10px;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 14px;
                    margin-bottom: 0px;
                    transition:max-height 0.15s ease-out;
                    .modal-overview{
                        text-align: center;
                        .modal-overview-title{
                            margin-top: 20px;
                            font-weight: bold;
                        }
                        .overview-point-list{
                            list-style: none;
                            padding: 0px;
                        }
                    } 
                  }
                  .readmore.expand{
                    max-height: 100% !important;
                    transition:max-height 0.35s ease-in-out;
                    .modal-overview{
                        text-align: center;
                        .modal-overview-title{
                            margin-top: 20px;
                            font-weight: bold;
                        }
                        .overview-point-list{
                            list-style: none;
                            padding: 0px;
                        }
                    } 
                  }
                  
                  .readmore-link{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    display: block;
                    width:100%;
                    height: 60px;
                    text-align: center;
                    color: darkgray;
                    font-weight:bold;
                    font-size:16px;
                    padding-top:14px;
                    background-image: linear-gradient(to bottom, white, white);
                    cursor: pointer;
                    color: #12293b;
                    font-size: 14px;
                    letter-spacing: 0.5px;

                    &::after{
                       content:"Read more";
                    }
                  }
                  .readmore-links{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    display: block;
                    width:100%;
                    height: 60px;
                    text-align: center;
                    color: darkgray;
                    font-weight:bold;
                    font-size:16px;
                    padding-top:14px;
                    background-image: linear-gradient(to bottom, white, white);
                    cursor: pointer;
                    color: #12293b;
                    font-size: 14px;
                    letter-spacing: 0.5px;

                    &::after{
                       content:"Read more";
                    }
                  }
                  .readmore-link.expand {
                    position: relative;
                    background-image: none;
                    padding-top:0px;
                    height:30px;
                    color: #12293b;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    margin: 9px 0px;

                    &::after{
                        content:"Read less";
                     }
                  }
                  
                  .readmore-links.expands {
                    position: relative;
                    background-image: none;
                    padding-top:0px;
                    height:30px;
                    color: #12293b;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    margin: 9px 0px;

                    &::after{
                        content:"Read less";
                     }
                  }
            }
        }
    }
    .offer-read-more{
        display: flex;
        padding: 20px;
        justify-content: center;
        transition: .25s ease;
        .read-more{
            border: none;
            background: inherit;
            font-size: 16px;
            color: darkgray;
        }
    }
}