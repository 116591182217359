@import "../../assets/app.scss";

.personal-trainers-profile{
    background: $jclub-light-gold;
    padding-bottom: 3rem;
    padding-top: 2.5rem;

    @include mobile{
        background-color: white;
    }

    .mobile-p-0-and-m-0{
        @include mobile{
            padding: 0px !important;
            margin: 0px  !important;
        }
    }

    .left-sub-parent{
        .location-profile{
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
            font-weight: 600;
        }
        
        @include mobile{
            text-align: center;
            .mobile-view{
                padding: 0px 0px 64px 0px;
                .club-location{
                    display: block; 
                    margin: 0px;
                    padding: 0px !important;
                }
                .email-address{
                    display: block;
                }
            }
        }
        .mobile-view{
            @include desktop {
                display: none;
            }
            @include tablet {
                display: none;
            }
        } 
        
 
        .profile-name{
            @include times-new-roman;
            text-transform: uppercase;
            font-size: 24px;
            letter-spacing: 3px;
            padding: 10px 0px;
            color: $jclub-navy;
            @include mobile{
                padding: 10px 29%;
                font-size: 18px;
                letter-spacing: 1px;
            }
        }
        .club-location{
            font-weight: 700;
            color: $jclub-navy;
            font-size: 14px;
            padding: 0;
            margin: 0;
            @include mobile{
                display: none;
             }
        }
        .email-address{
            font-weight: 700;
            font-size: 14px;
            color: $jclub-dark-gery;
            @include mobile{
                display: none;
            }
        }
    }
    .right-sub-parent{
        padding-top: 2.6rem;
        margin-top: 3rem;
        padding-bottom: 3rem;
    }

    .profile-paragraph-adjust{
        @include mobile{
            padding: 38px 0px 0px 0px;
        }
        .profile-heading{
            font-family: 'Times New Roman', Times, serif;
            text-transform: uppercase;
            padding: 0px;
            margin: 0px;
            letter-spacing: 1px;
            font-size: 16px;
            @include mobile{
                text-align: center;
            }
        }
        .qualification-margin-adjust{
            @include mobile{
                margin: 30px 0px;
            }
        }
        .profile-paragraph{
            font-size: 14px;
            color: $jclub-navy;
            font-family: Arial, Helvetica, sans-serif;
            text-transform: capitalize;
            padding: 0px;
            margin: 0px;
            padding: 4px 0px;
            font-size: 14px;
            
            ul{
                padding: 4px 16px;
                li{
                    @include mobile{
                        list-style-position: inside;
                    }
                    padding: 2px 0px;
                }
             }
        }
        // @include desktop{
        //     position: absolute;
        //     top: 88px;
        // }
        // @include desktopsmall{
        //     position: absolute;
        //     top: 88px;
        // }
        @include mobile{
             .profile-paragraph{
                text-align: center;
                padding: 2px 21px;
            }
         }
    }
}