@import "../../assets/app.scss";
.footer-section{
    @include desktopsmall{
        zoom: 0.880;
     }
    @include desktop{
        zoom: 0.880;
     }
    @include desktoplarge{
        zoom: 1;
    }
    border-top: 8px solid #abb8bd;
    .container-fluid{
        width: 100%;
        @include desktop{
            padding: 50px 35px;
        }
    }
    .footer-logo{
        padding-left: 20px;
        .logo-image{
            width: 200px;
            height: 200px;
        }
        @include mobile{
            .logo-image{
                width: 120px;
                height: 120px;
            }
        }
    }
    .mobile-view-padding{
        @include mobile{
            padding-bottom: 2rem !important;
            padding-top: 2rem !important;
        }
        @include tablet{
            padding-bottom: 2rem !important;
            padding-top: 3rem !important;
        }
        @include desktopsmall{
            padding-bottom: 2rem !important;
            padding-top: 3rem !important;
        }
        @include desktop{
            padding-bottom: 0.5rem!important;
            padding-top: 0.5rem!important;       
            }
        padding-bottom: 0.5rem!important;
        padding-top: 0.5rem!important;       
    }
    .footer-menuitems{
        list-style: none;
        color: #0e2538;
        font-size: 14px;
        text-transform: uppercase;
        font-family: "Times New Roman", Times, serif;
        letter-spacing: 1px;
        // font-size: 15px;
        padding: 0px;
        @include mobile{
            font-size: 12px;
            text-align: right;
            padding: 0px 21px;
        }
        a{
            color: #0e2538;
        }
        li{
             padding: 4px 0px;
        }
        @include mobile{
             li{
                 padding: 0px 0px;
             }
        }
        .icons-size{
            font-size: 25px;
            padding: 0px;
            color: #ABB8BD;
            .tagclass{
                font-size: 25px;
                padding: 0px;
                color: #ABB8BD;
                text-decoration: none;
            }
         }
         .social-media{
            margin: -9px 0px;
         }
    }
 
}



.footer-mobile-view{
    display: flex;
    flex-direction: column;
    .newsletter-mobile-view{
        display: none;
        @media screen and (max-width:760px){
            display: flex;
            justify-content: center;
            background-color: #0d2233;
        }
        .newsletter{
            display: flex;
            justify-content: center;
            .newsletter-form{
                justify-content: center;
                align-items: center;
                display: grid;
                .newsletter-row-1{
                  input{
                      @media screen and (max-width:450px) {
                          width: 160px;
                      }
                    }
                }
            }
        }
    }
    .footer {
        background-color: #fff;
        border-top: 8px solid #abb8bd;
        color: #0d2233;
        line-height: 1.5;
        padding: 1.25rem 1.875rem;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-evenly;
        justify-content: space-around;
        .footer-logo{
                left: 1.5rem;
                background: transparent;
                font-size: 2rem;
                cursor: pointer;
                outline: none;
                color: #abb8bd;
                .logo-image{
                    width: 100px;
                    height: 100px;
                }
        }
        .footer-newsletter{
            // padding: 1.25rem 1.875rem;
            @media screen and (max-width:760px){
                display: none;
            }
        }
        .footer-menuitems{
            list-style: none;
            color: #0d2233;
            font-size: 14px;
            text-transform: uppercase;
            padding: 10px;
            @media screen and (min-width:760px) and (max-width:864px) {
                display: none;
            }
            @media screen and (max-width:424px) {
                display:none;
            }
        }
        .footer-items{
            list-style: none;
            color: #0d2233;
            font-size: 14px;
            padding: 10px;
            text-transform: uppercase;
        }
    }
}



    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        .newsletter-mobileview{
            display: block;
        }
        .newsletter-desktopview{
            display: none;
        }
        .footer-menuitems1{
            display: none;
        }
        .footer-section{
            // border-top: none;
        }
         
      }
      
      /* Small devices (portrait tablets and large phones, 600px and up) */
      @media only screen and (min-width: 600px) {
        .newsletter-mobileview{
            display: block;
        }
        .newsletter-desktopview{
            display: none;
        }
        .footer-menuitems1{
            display: none;
        }
       
      }
      
      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (min-width: 768px) {
        .newsletter-mobileview{
            display: block;
        }
        .newsletter-desktopview{
            display: none;
        }
        .footer-menuitems1{
            display: block;
        }
      } 
      
      /* Large devices (laptops/desktops, 992px and up) */
      @media only screen and (min-width: 992px) {
        .newsletter-mobileview{
            display: none;
        }
        .newsletter-desktopview{
            display: block;
        }
        .footer-menuitems1{
            display: block;
        }
      } 
      
      /* Extra large devices (large laptops and desktops, 1200px and up) */
      @media only screen and (min-width: 1200px) {
        .newsletter-mobileview{
            display: none;
        }
        .newsletter-desktopview{
            display: block;
        }
        .footer-menuitems1{
            display: block;
        }
      }


#CookieReportsPanel{
   #CookieReportsBanner{ 
        .wscrHeading{
            font-family: 'Times New Roman', Times, serif;
            color: $jclub-navy;
            @include desktopsmall{
                font-size: 18px;
            }
            @include desktop{
                font-size: 18px;
            }
            @include desktoplarge{
                font-size: 20px;
            }
        }
        #CookieReportsBannerDescription{
            font-family: Arial, Helvetica, sans-serif;
            color: $jclub-grey;
            p{
                font-size: 13px;
            }
        }
        .wscrBannerContentInner{
            font-family: Arial, Helvetica, sans-serif;
           .wscrOk{
              font-size: 13px;
              background: $jclub-navy;
           } 
           .wscrOk2{
              font-size: 13px;
              background: $jclub-navy;
           }
        }
        .wscrBannerContentInner2{
            font-family: Arial, Helvetica, sans-serif;
            .wscrBannerLink{
                font-size: 12px;
            }
        }
    }

    
 #CookieReportsAccordion{
    width: 102%;
    #wscrAccordionInjectContent{
       span{
          color: $jclub-grey !important;
          font-size: 12px !important;
       }
      a{
          color: $jclub-grey !important;
          font-size: 12px;
          span{
              color: $jclub-grey !important;
          }
      }
   }
    #CookieReportsAccordionSections{
           .CookieReportsAccordionHeading{
            //    outline: none;
            background: $jclub-navy;
           }
           .CookieReportsAccordionHeading::after{
               color: white;
           }
           .wscrSectionCount{
               min-width: 25px;
               @include mobile{
                   min-width: 12px;
               }
           }
           .wscrSectionTitle{
               padding: 0px;
               .wscrSectionTitleText{
                   color:white;
                   letter-spacing: 1.5px;
                   font-size: 19px;
                   @include mobile{
                       font-size: 11px;
                   }
                   @include tablet{
                       font-size: 16px;
                   }
                   @include desktop{
                       font-size: 16px;
                   }
                   cursor: pointer;
                   font-family: 'Times New Roman', Times, serif;
                   text-transform: uppercase;
               }
           }
           .wscrSectionTitleConsent{
              .wscrSwitchParts{
                   border: 2px solid #0E2538;
                   .wscrSwitchLabel:before {
                       padding-left: 10px;
                       background-color: white;
                       color: #0E2538;
                       border: none;
                   }
                   .wscrSwitchLabel:after {
                       content: "\2716";
                       padding-right: 10px;
                       background-color: white;
                       text-align: right;
                       color: #0E2538;
                   }
              } 
               .wscrSwitchToggle{
                   background: #0E2538;
               }
           }
           dd{
               .wscrSectionDescription{
                   font-family: Arial, Helvetica, sans-serif;
                   padding: 11px 14px;
                   color: $jclub-navy;
                   font-size: 12px; 
                   p{
                       font-size: 13px;
                       font-family: Arial, Helvetica, sans-serif;
                       color: $jclub-navy;
                       letter-spacing: 1px;
                       line-height: 1.5;                    
                   }
               }
               .CookieReportsAccordionCookies{
                   table{
                       th{
                           border-bottom: 1px solid #ccc;
                           font-size: 13px;
                           font-family: Arial, Helvetica, sans-serif;
                           text-transform: uppercase;
                       }
                       td{
                           font-family: Arial, Helvetica, sans-serif;
                           font-size: 13px;
                           @include mobile{
                                   font-size: 10px;
                           }
                           @include tablet{
                               font-size: 13px;
                           }                        
                       }
                       .wscrField-description{
                           font-size: 12px;
                           @include mobile{
                               padding: 10px 0px 20px 0px;
                           }
                           @include tablet{
                               padding: 10px 10px 20px 10px;
                           }
                           .wscrCompanyPrivacy{
                               li{
                                   a{
                                       color: $jclub-grey;
                                       font-size: 12px;
                                   }
                               }
                           }
                       }
                   }
               }
           }
       

   }
}

@include mobile{
   .wscrSwitch{
       zoom: 0.7;
       position: relative;
       left: 29px;
   }
}
}