@import "../../assets/app.scss";
// .form-submitted{
//     filter: blur(20px);
//     pointer-events: none;
//     user-select: none;
// }
.popup{
    // position: fixed;
    // top: 40%;
    // left: 50%;
    // transform: translate(-50%, 400%);
    // position: absolute;
    // top: 30%;
    // left: 30%;
    // transform: translate(0%, 400%);
    // width: 600px;
    // padding: 50px;
    p{
        font-family: 'Times New Roman', Times, serif;
        font-size: 16px;
        color: #38bb3d;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }
    // .okay-button{
    //     border-radius: 10px;
    //     background: $jclub-grey;
    //     color: $jclub-navy;
    //     border: 1px solid $jclub-grey;
    //     width: 100%;
    //     max-width: 250px;
    //     text-transform: uppercase;
    //     font-size: 16px;
    //     font-weight: 600;
    //     justify-content: center;
    // }
}
.visible{
    visibility:visible;
    opacity: 1;
    // transition: 0.5s;
}

.form{
    background: $jclub-navy;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px 50px 100px 50px;
    font-family: Arial, Helvetica, sans-serif;
    .form-title{
        font-size: 24px;
        display: flex;
        justify-content: center;
        font-family: 'Times New Roman', Times, serif;
        padding: 50px 0px 10px;
        color: $jclub-grey;
        letter-spacing: 1.5px;
    }
    .spinner {
        margin-right: 5px;
        animation: spin infinite 5s linear;
    
        /*You can increase or decrease the timer (5s) to 
         increase or decrease the speed of the spinner*/
      }
    
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    .form-wrapper{
        @include desktop{
            margin-left: 150px;
            margin-right: 150px;
        }
    }
    input[type=text],input[type=number],textarea{
        border-radius: 10px;
        background: transparent;
        border: 1px solid $jclub-grey;
        width: 100%;
        padding: 0.1rem 0.75rem;
        color: white;
        text-decoration: none;
        &:focus{
            outline: none;
        }
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    .submit-btn{
        border-radius: 10px;
        background: $jclub-grey;
        color: $jclub-navy;
        border: 1px solid $jclub-grey;
        width: 100%;
        max-width: 200px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
    }
    input[type=checkbox]{
        background: #0E2538;
        border: 1px solid #abb8bd;
        // margin-top: 3px;
        margin-right: 5px;
        font-size: 14px;
        width: 18px;
        border-radius: 5px;
        height: 18px;
        appearance: none;
        cursor: pointer;
    }
    input[type=checkbox]:checked{
        background: $jclub-grey;
    }
    label{
        font-size: 14px;
        color: white;
        letter-spacing: 0.5px;
    }
    .required{
        color: white;
        font-size: 14px;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .error{
        padding: 1px 0px;
        text-align: end;
        display: block;
        font-size: 14px;
        text-transform: uppercase;
        font-family: 'Times New Roman', Times, serif;
        letter-spacing: 0.5px;
    }
}