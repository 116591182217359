@import "../../assets/app.scss";
.navbar-transparent{
    .navigation__checkbox:checked~.navigation__button {
        position: fixed;
        @include tablet{
            top: 1rem;
            right: 3rem;
            // right: 3rem;
        }
        @include desktopsmall{
            top: 1.1rem;
            right: 7rem;
            
        }
        @include desktop{
            top: 1.2rem;
            right: 3.2rem;
        }
        @include desktoplarge{
            top: 1.2rem;
            right: 3.7rem;
        }

    }
}
.dropdown-menus{
    li:hover{
        color: white !important;
    }
}
.navbar-black{
    .navigation__checkbox:checked~.navigation__button {
        position: fixed;
        @include tablet{
            top: 0.5rem;
            right: 1.7rem;
            
        }
        @include desktopsmall{
            top: 0.5rem;
            right: 7rem;
            
        }
        @include desktop{
            top: 0.6rem;
            right: 4.7rem; 
        }
        @include desktoplarge{
            top: 0.5rem;
            right: 5.2rem;
        }

    }
}


.navigation{
    .navigation__nav .logo-image{
        width: 150px;
        height: 140px;
        margin: 0px 25%;
        @include tablet{
            position: relative;
            left: 1rem;
        }
        @include desktoplarge{
            position: relative;
            left: 1.5rem;
        }
        @include desktop{
            position: relative;
            left: 1.5rem;
        }
        @include desktopsmall{
            position: relative;
            left: 1.5rem;
        }
    }
    .burger-menu-icon{
        font-size: 20px;
        position: fixed;
        top: 91%;
        left: 49%;
        list-style: none;
    }
    .navigation__item{
        ul{
            a{
                text-decoration: none;
            }
             li{
                list-style: none;
                text-transform: uppercase;
                color: #abb8bd;
                font-family: 'Times New Roman', Times, serif;
                letter-spacing: 3px;
                font-size: 18px;
                padding: 8px 0px 6px 0;
                @include desktopsmall{
                    font-size: 12px;
                    padding: 4px 0px 4px 0;    
                }
                @include desktop{
                    font-size: 16px;
                    padding: 5px 0px 5px 0;    
                }
                @include desktoplarge{
                    font-size: 18px;
                    padding: 8px 0px 6px 0;
                }
                transition: 0.5s linear;
                .atag{
                       color: rgba(218, 216, 216, 0.87);
                       text-decoration: none;
                }
                .atag:hover{
                    color: white;
                    cursor: pointer;
                }
                    ul{
                         transition: visibility 0s linear  0ms, opacity 0ms;
                        cursor: pointer;
                            li{
                                @include mobile{
                                    font-size: 10px;
                                }
                                @include tablet{
                                    font-size: 10px;
                                }
                                @include desktopsmall{
                                    font-size: 10px; 
                                }
                                @include desktop{
                                    font-size: 12px; 
                                }
                                @include desktoplarge{
                                    font-size: 14px; 
                                }
                                padding: 2px 0px; 
                            }
                     } 
            }
            }
        }
        .dropdown-toggle{
            color: unset;
            text-decoration: none;
        }
        .navigation__checkbox {
            display: none;
        }

        .navigation__button {
            text-align: center;
            color: white;
            border-radius: 50%;
            z-index: 300;
            cursor: pointer;
            margin-bottom: 0.6rem;
        }
       
        .margin-4{
            margin-top: 3.0rem!important;
        }

        .navigation__nav {
            position: fixed;
            top: 0;
            right: 0;
            height: 100vh;
            opacity: 0;
            width: 0;
            visibility: hidden;
            z-index: 200;
            transition: all 800ms ease-in-out;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .navigation__list {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 0%);
            @include mobile{
                transform: translate(-50%, 20%);
            }
            @include tablet{
                transform: translate(-50%, 20%);
            }
            @include desktopsmall{
                transform: translate(-50%, 0%);
            }
            @include desktop{
                transform: translate(-50%, 25%);
            }
            text-align: center;
            width: 50%;
            list-style: none;
        }

        .navigation__item {
            margin: 0rem 1rem;
        }

        .navigation__link:link,
        .navigation__link:visited {
            display: inline-block;
            padding: 1rem 2rem;
            text-transform: uppercase;
            color: #f4f4f4;
            font-size: 2.4rem;
            text-decoration: none;
            transition: all .2s;
        }

        @media(min-width: 768px) {
            .navigation__link:link,
            .navigation__link:visited {
                font-size: 3.8rem;
            }
        }

        .navigation__link span {
            margin-right: 1.5rem;
            display: inline-block;
        }

        .navigation__link:hover {
            color: #C1D0D0;
            transform: scale(1.1);
        }

        .navigation__checkbox:checked~.navigation__nav {
            width: 100%;
            visibility: visible;
            opacity: 1;
        }

        .navigation__icon {
            position: relative;
            margin-top: 2.5rem;
            @include desktopsmall{
                left: 80px;
            }
            @include desktop{
                left: 0;
            }
        }

        @media screen and (min-width: 768px) {
            .navigation__icon {
                margin-top: 2rem;
            }
        }
         @media (min-width: 768px) {
            .navigation__icon,
            .navigation__icon::before,
            .navigation__icon::after {
                width: 1.7rem;
            }
        }

        .navigation__icon::before,
        .navigation__icon::after {
            content: '';
            position: absolute;
            left: 0;
            transition: all 200ms;
        }

        .navigation__icon::before {
            top: -0.6rem;
        }

        .navigation__icon::after {
            top: 0.6rem;
        }

        .navigation__button:hover .navigation__icon::before {
            top: -0.6rem;
        }

        .navigation__button:hover .navigation__icon::after {
            top: 0.6rem;
        }

        .navigation__checkbox:checked+.navigation__button .navigation__icon {
            background-color: transparent;
        }

        .navigation__checkbox:checked+.navigation__button .navigation__icon::before {
            top: 0;
            transform: rotate(135deg);
        }

        .navigation__checkbox:checked+.navigation__button .navigation__icon::after {
            top: 0;
            transform: rotate(-135deg);
        }
}

.social-burger{
    margin: auto;
    @include desktopsmall{
        margin-top: 85vh;
    }
    @include desktop{
        margin-top: 85vh;
    }
    @include desktoplarge{
        margin-top: 87vh;
    }
    margin-top: 87vh;
    font-size: 18px;
 } 