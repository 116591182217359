@import "../../assets/app.scss";

.article-campaign{
    @include tablet{
        padding: 100px 60px;
    }
    @include desktopsmall{
        padding: 100px 80px;
    }
    @include desktop{
        padding: 100px 100px;
    }
    @include desktoplarge{
        padding: 100px 140px;
    }
    .article-title{
        @include tablet{
            font-size: 25px;
        }
        @include desktopsmall{
            font-size: 27px;
        }
        @include desktop{
            font-size: 32px;
        }
        letter-spacing: 2px;
        width: 100%;
        font-family: 'Times New Roman', Times, serif;
        margin-bottom: 40px;
    }
    .article-image{
        width: 100%;
        height: 500px;
        object-fit: cover;
        object-position: top;
    }
    .article-description{
        font-family: Arial, Helvetica, sans-serif;
        width: 100%;
        margin-top: 30px;
        @include mobile{
            font-size: 13px;
        }
        @include desktopsmall{
            font-size: 15px;
        }
    }
    .right-column-article{
        @include tablet{
            padding-left: 50px;
        }
        @include desktopsmall{
            padding-left: 50px;
        }
        @include desktop{
            padding-left: 100px;
        }
        
        .card-title{
            font-size: 14px;
            font-weight: 700;
        }
        .related{
            font-family: 'Times New Roman', Times, serif;
            font-size: 20px;
            letter-spacing: 2px;
            margin-bottom: 20px;
        }
    }
}


@include tablet{
    .news-mobile-article{
        display: none;
    }
    .campaign-mobile-article{
        display: block;
    }
}