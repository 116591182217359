.jclub-faq-accoridon-container {
    background-color: white;
    align-self: center;
    width: 75%;
    position: relative;
    padding-left: 70px;
    padding-right: 40px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    [data-reach-accordion-button] {
      font-family: jumeirah-font2;
      font-size: 16px;
      background: none;
      border: none;
      outline: none;
      font-weight: bold !important;
      display: flex;
      svg {
        width: 2em;
        height: 1.5em;
        margin-left: 10px;
      }
    }
    [data-reach-accordion] {
    //   padding-top: 60px;
    }
    [data-reach-accordion-panel] {
      font-family: jumeirah-font2;
      font-size: 14px !important;
      font-weight: 500;
      background: #ccc;
      padding: 12px;
      margin-bottom: 10px;
      margin-left: 18px;
    }
  
    .form-group {
      float: right;
      padding-top: 20px;
      span {
        padding-left: 10px;
        padding-right: 10px;
      }
      input[type="checkbox"],
      input[type="radio"] {
        position: absolute;
        opacity: 0;
        & + label {
          position: relative;
          cursor: pointer;
          padding: 0;
          font-size: 13px;
        }
        & + label:before {
          content: "";
          display: inline-block;
          vertical-align: text-top;
          width: 16px;
          height: 16px;
          padding: 4px;
          border: rgb(240, 240, 240) 3px solid;
          outline: #cdc9b8 1px solid;
          background: white;
        }
        &:checked + label:before {
          background: #8c816b;
        }
  
        // Disabled state label.
        &:disabled + label {
          opacity: 0.8;
          cursor: default;
        }
  
        // Disabled box.
        &:disabled + label:before {
          box-shadow: none;
          cursor: default;
          opacity: 0.8;
        }
      }
    }
    .new-badge {
      position: absolute;
      left: -70px;
      top: -2px;
      font-size: 14px;
      .text {
        background: #f00;
        float: left;
        width: 50px;
        color: #fff;
        line-height: 30px;
        text-align: center;
        height: 30px;
      }
      .arrow {
        width: 30px;
        float: left;
        height: 0px;
      }
  
      .right {
        border-top: 15px solid transparent;
        border-left: 15px solid #f00;
        border-bottom: 15px solid transparent;
      }
    }
  }
  