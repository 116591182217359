@import "../../assets/app.scss";
.personal-trainer-main-home{
    padding: 5em 35px 0px 35px;
    .personal-trainer{
        padding: 6px 0px;
        .title{
            color: $jclub-grey;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 28px;
            text-align: center;
            @include times-new-roman;
        }

        .filters{
            span{
                color: $jclub-navy;
                font-weight: 600;
            }
            .mobile-filter-adjust{
                     margin: 0px 10px;
             }
            select{
                display: inline;
                width: 33%;
                height: 24px;
                font-size: 12px;
                padding: 0px 7px;
                text-transform: uppercase;
                font-size: 0.9rem;
                font-weight: 400;
                box-shadow: none;
                color: $jclub-grey;
            }
            
        }
    }
}
.personal-slider-main-home{
     
    @include mobile{
        padding: 1rem 0rem 1rem 0rem ;
    }
    @include tablet{
        padding: 0px;
    }
    @include desktopsmall{
        padding: 1.5rem 1rem 2rem 1rem;
    }
    @include desktop{
        padding: 2rem 4rem 2rem 4rem ;
    }
    
    .padding-adjust{
        @include tablet{
            margin: 0rem 0rem 0.4rem 0rem;
        }
        @include desktop{
            margin: 0rem 0rem 0.4rem 0rem;
        }
        @include desktopsmall{
            margin: 0rem 0rem 1.5rem 0rem;
        }
       
        @include mobile{
            padding-left: 0px;
            margin: 5px 0px;
            .Learn-more{
                text-decoration: none !important;
                font-size: 14px;
                padding: 0px 5px;
            }
        }
    }

    .card{
        border: none;
        border-radius: 0px;
    }
    
    .slick-prev{
        left: -31px !important;
        top: 44% !important;
        img{
            width: 35px;
        }
    }
    .slick-next{
        right: -31px !important;
        top: 44% !important;
        img{
            width: 35px;
            transform: scaleX(-1);
        }
    }

    .personal-slider{
        .outline-none{
            outline: none;
        }
        @include desktop{
            padding: 1em;
        }
        @include desktoplarge{
            padding: 2em;
        }
        .profile-bg-image{
            width: 100%;
            height: 325px;
            @include mobile{
                height: 221px;
            }
            background-repeat: no-repeat;
            background-position: center center;
            transition: all 1000ms ease;
            background-size: cover;
        }
    }
    .profile{
        .title{
            font-weight: 600;
            text-align: center;
            padding: 22px 0px 0px 0px;
            margin: 0;
            font-size: 16px;
            font-family: Arial;
            color: $jclub-navy;
            @include mobile{
                height: 70px;
                color: #0E2538;
            }
        }
        .description{
            text-align: center;
            font-family: Arial;
            color: $jclub-navy;
            font-size: 15px;
        }
        .Learn-more-head{
            .Learn-more{
                font-weight: 600;
                color: $jclub-grey;
                text-decoration: none;
                text-align: center;
            }
       }
    }
}


@media screen and (min-width:300px) and (max-width:1100px)  {

    .personal-slider-main-home {
        .personal-slider{
            padding: 0.5em 0 2em 13px;
        }
     }
    
}

@media screen and (min-width:300px) and (max-width:767px){
    .personal-trainer{
        .title{
            font-size: 18px !important;
        }
        .filter-label {
            font-size: 14px !important;
        }
    }
    .mobile-filter-adjust{
        text-align: center;
        margin: 13px 0px 20px 0px;
        padding: 0px 0px 20px 0px;
     }
}