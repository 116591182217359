a[target='_blank']
body, html {
  background-color: #ffffff !important;
  -webkit-transition: all 5000s ease;
  transition: all 5000s ease;
}
h1,h2,h3{
  font-size: 24px;
}
/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData, .scpm { display: none !important; }
/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.error-page-wrapper {
  background-image: url(/static/media/errorpagebg.3c28e80f.png);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  position: relative;
  height: 100vh;
  width: 100%;
  padding-top: 30%;
  color: #0E2538; }
  @media (min-width: 768px) {
    .error-page-wrapper {
      padding-top: 15%; } }
  @media (min-width: 1200px) {
    .error-page-wrapper {
      padding-top: 15%; } }
  .error-page-wrapper h1 {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-family: SometimesTimes;
    font-size: 65px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    background-color: white;
    border-radius: 50px; }
    @media (min-width: 1200px) {
      .error-page-wrapper h1 {
        width: 65%; } }
    @media (min-width: 768px) {
      .error-page-wrapper h1 {
        font-size: 65px; } }
    @media (max-width: 767px) {
      .error-page-wrapper h1 {
        font-size: 42px; } }
  .error-page-wrapper p {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-top: 15px;
    font-family: FSMeridian;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    background-color: white; }
    @media (min-width: 1200px) {
      .error-page-wrapper p {
        margin-top: 22px;
        width: 62.5%; } }
    @media (min-width: 768px) {
      .error-page-wrapper p {
        font-size: 23px; } }
  .error-page-wrapper a {
    margin: 25px auto;
    display: block;
    padding: 8px 0;
    width: 80%;
    border: 1px solid #fff;
    color: #0E2538;
    text-align: center;
    text-transform: uppercase;
    background-color: #fff;
    font-family: FSMeridian; }
    @media (min-width: 1200px) {
      .error-page-wrapper a {
        width: 22%; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.events-main-container .events-title h2 {
  color: #abb8bd;
  text-align: center;
  font-size: 24px;
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 2px;
  margin: 0px;
  padding: 0px;
  padding: 90px 0px 50px 0px; }
  @media (max-width: 767px) {
    .events-main-container .events-title h2 {
      padding: 50px 0px 0px 0px;
      font-size: 20px; } }

.upcoming-events {
  padding: 0rem 5rem 5rem 5rem; }
  @media (max-width: 767px) {
    .upcoming-events {
      padding: 2rem 1rem 2rem 1rem; } }
  @media (max-width: 767px) {
    .upcoming-events .slick-prev {
      left: -30px; } }
  .upcoming-events .slick-prev img {
    width: 30px; }
  .upcoming-events .slick-next {
    right: -31px; }
    @media (max-width: 767px) {
      .upcoming-events .slick-next {
        right: -30px; } }
    .upcoming-events .slick-next img {
      width: 30px;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }
  .upcoming-events .upcoming-events-slider {
    padding: 20px 5px; }
    @media (max-width: 767px) {
      .upcoming-events .upcoming-events-slider {
        padding: 0px 5px 5px 5px; } }
    @media (min-width: 768px) {
      .upcoming-events .upcoming-events-slider {
        padding: 20px 5px; } }
    .upcoming-events .upcoming-events-slider .profile .blog-title h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }
      @media (max-width: 767px) {
        .upcoming-events .upcoming-events-slider .profile .blog-title h3 {
          font-size: 14px !important; } }
    .upcoming-events .upcoming-events-slider .profile .cursor-none {
      cursor: auto; }
    .upcoming-events .upcoming-events-slider .profile .events-bg-image {
      width: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover; }
      @media (max-width: 767px) {
        .upcoming-events .upcoming-events-slider .profile .events-bg-image {
          height: 300px; } }
      @media (min-width: 768px) {
        .upcoming-events .upcoming-events-slider .profile .events-bg-image {
          height: 300px; } }
    .upcoming-events .upcoming-events-slider .profile .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 0px;
      border-bottom: 2px solid #c8d0d3;
      border-left: 2px solid #c8d0d3;
      border-right: 2px solid #c8d0d3; }
      @media (max-width: 767px) {
        .upcoming-events .upcoming-events-slider .profile .card-body {
          border: none; } }
      .upcoming-events .upcoming-events-slider .profile .card-body .mobile-title {
        color: #0E2538;
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        background: #fff;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        padding: 0.5rem 2rem;
        margin: -22px auto 2rem;
        margin: 0px; }
        @media (max-width: 767px) {
          .upcoming-events .upcoming-events-slider .profile .card-body .mobile-title {
            padding: 13px 0px 0px 0px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical; } }
        @media (min-width: 768px) {
          .upcoming-events .upcoming-events-slider .profile .card-body .mobile-title {
            padding: 7px 0px 0px 0px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical; } }
      .upcoming-events .upcoming-events-slider .profile .card-body .title {
        margin: 0;
        font-weight: 600;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        color: #0E2538;
        background: white;
        padding: 9px 10px;
        font-size: 16px;
        letter-spacing: 0.5px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        height: 60px;
        -webkit-box-orient: vertical; }
        @media (max-width: 767px) {
          .upcoming-events .upcoming-events-slider .profile .card-body .title {
            height: 55px; } }
      .upcoming-events .upcoming-events-slider .profile .card-body .border-bottom {
        width: 13%;
        margin: auto;
        padding: 0px;
        border-bottom: 2px solid #c8d0d3 !important;
        position: relative;
        bottom: 15px; }
      @media (max-width: 767px) {
        .upcoming-events .upcoming-events-slider .profile .card-body .mobile_final_description {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; }
        .upcoming-events .upcoming-events-slider .profile .card-body .desktop_final_description {
          display: none; } }
      @media (min-width: 768px) {
        .upcoming-events .upcoming-events-slider .profile .card-body .mobile_final_description {
          display: none; }
        .upcoming-events .upcoming-events-slider .profile .card-body .desktop_final_description {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; } }
      .upcoming-events .upcoming-events-slider .profile .card-body .description {
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        color: #0E2538;
        font-size: 13px;
        padding: 0px 20px 0px 20px;
        letter-spacing: 0.3px;
        line-height: 1.6;
        height: 45px; }
        @media (max-width: 767px) {
          .upcoming-events .upcoming-events-slider .profile .card-body .description {
            height: 40px;
            padding: 0px 20px 0px 20px !important; } }
        @media (min-width: 768px) {
          .upcoming-events .upcoming-events-slider .profile .card-body .description {
            height: 45px; } }
      .upcoming-events .upcoming-events-slider .profile .card-body .main-link {
        margin-top: auto;
        /* Ensures the button is at the bottom */
        padding: 0px 10px 7px 10px; }
        .upcoming-events .upcoming-events-slider .profile .card-body .main-link .info-link,
        .upcoming-events .upcoming-events-slider .profile .card-body .main-link .info-link-single,
        .upcoming-events .upcoming-events-slider .profile .card-body .main-link .book-link {
          width: 100%;
          display: inline-block;
          padding: 0px;
          margin: 0px;
          text-align: center;
          font-weight: 600;
          font-family: Arial;
          border-top: 2px solid #c8d0d3; }
          .upcoming-events .upcoming-events-slider .profile .card-body .main-link .info-link p,
          .upcoming-events .upcoming-events-slider .profile .card-body .main-link .info-link-single p,
          .upcoming-events .upcoming-events-slider .profile .card-body .main-link .book-link p {
            padding: 0px;
            margin: 0px;
            position: relative;
            top: 3px;
            padding: 4px 0px; }

@media screen and (min-width: 300px) and (max-width: 1100px) {
  .personal-slider-main .personal-slider {
    padding: 0.5em; } }

@media screen and (min-width: 300px) and (max-width: 821px) {
  .personal-trainer-main {
    padding: 55px 35px 30px 35px !important; }
  .personal-trainer .title {
    font-size: 18px !important; }
  .personal-trainer .filter-label {
    font-size: 14px !important; }
  .mobile-filter-adjust {
    text-align: center;
    padding: 0px 0px 34px 0px; }
    .mobile-filter-adjust select {
      margin: 15px 0px 35px 0px !important;
      width: 35% !important;
      height: 24px !important; } }

.right-column-article .card-title {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif; }

.right-column-article .card-text {
  font-size: 14px;
  letter-spacing: .5px; }

.right-column-article .readmore {
  font-size: 14px;
  letter-spacing: .5px; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
@media (min-width: 768px) {
  .article {
    padding: 40px 40px; } }

@media (min-width: 992px) {
  .article {
    padding: 40px 40px; } }

@media (min-width: 1200px) {
  .article {
    padding: 60px 60px; } }

@media (min-width: 1440px) {
  .article {
    padding: 80 80px; } }

.article .article-title {
  letter-spacing: 2px;
  width: 100%;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .article .article-title {
      font-size: 25px; } }
  @media (min-width: 992px) {
    .article .article-title {
      font-size: 27px; } }
  @media (min-width: 1200px) {
    .article .article-title {
      font-size: 32px; } }

.article .article-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: center; }

.article .article-description {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  margin-top: 30px; }
  @media (max-width: 767px) {
    .article .article-description {
      font-size: 13px; } }
  @media (min-width: 992px) {
    .article .article-description {
      font-size: 15px; } }

.article .right-column-article .col-md-4 .card-img-top {
  height: 300px;
  width: 100%;
  object-fit: cover; }

@media (min-width: 768px) {
  .article .right-column-article {
    padding-top: 50px; } }

@media (min-width: 992px) {
  .article .right-column-article {
    padding-top: 50px; } }

@media (min-width: 1200px) {
  .article .right-column-article {
    padding-top: 100px; } }

.article .right-column-article .row {
  grid-gap: 1rem;
  gap: 1rem; }

.article .right-column-article .col-md-4 {
  padding-left: 0;
  padding-right: 0; }

.article .right-column-article .card-title {
  font-size: 14px;
  font-weight: 700; }

.article .right-column-article .related {
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  letter-spacing: 2px;
  margin-bottom: 20px; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
@media (max-width: 767px) {
  .article {
    display: none; }
  .news-mobile-article {
    display: block;
    padding: 40px 20px; }
    .news-mobile-article .image {
      padding: 24px; }
      .news-mobile-article .image img {
        object-fit: cover;
        height: 213px;
        width: 300px; }
    .news-mobile-article .contents {
      color: #0E2538; }
      .news-mobile-article .contents .title {
        font-family: 'Times New Roman', Times, serif;
        text-align: center;
        text-transform: uppercase;
        font-size: 17px; }
      .news-mobile-article .contents .description {
        font-size: 15px;
        text-align: center; }
  .arrow-settings {
    color: #0E2538;
    font-size: 14px;
    font-weight: 600; }
    .arrow-settings .left {
      float: left; }
      .arrow-settings .left .left-arrow {
        width: 20px; }
    .arrow-settings .right {
      float: right; }
      .arrow-settings .right .right-arrow {
        width: 20px; } }

@media (min-width: 768px) {
  .news-mobile-article {
    display: none; }
  .article {
    display: block; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.popup p {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  color: #38bb3d;
  letter-spacing: 1.5px;
  text-transform: uppercase; }

.visible {
  visibility: visible;
  opacity: 1; }

.form {
  background: #0E2538;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px 50px 100px 50px;
  font-family: Arial, Helvetica, sans-serif; }
  .form .form-title {
    font-size: 24px;
    display: flex;
    justify-content: center;
    font-family: 'Times New Roman', Times, serif;
    padding: 50px 0px 10px;
    color: #abb8bd;
    letter-spacing: 1.5px; }
  .form .spinner {
    margin-right: 5px;
    -webkit-animation: spin infinite 5s linear;
            animation: spin infinite 5s linear;
    /*You can increase or decrease the timer (5s) to 
         increase or decrease the speed of the spinner*/ }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  @media (min-width: 1200px) {
    .form .form-wrapper {
      margin-left: 150px;
      margin-right: 150px; } }
  .form input[type=text], .form input[type=number], .form textarea {
    border-radius: 10px;
    background: transparent;
    border: 1px solid #abb8bd;
    width: 100%;
    padding: 0.1rem 0.75rem;
    color: white;
    text-decoration: none; }
    .form input[type=text]:focus, .form input[type=number]:focus, .form textarea:focus {
      outline: none; }
  .form input[type=number]::-webkit-inner-spin-button,
  .form input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .form .submit-btn {
    border-radius: 10px;
    background: #abb8bd;
    color: #0E2538;
    border: 1px solid #abb8bd;
    width: 100%;
    max-width: 200px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600; }
  .form input[type=checkbox] {
    background: #0E2538;
    border: 1px solid #abb8bd;
    margin-right: 5px;
    font-size: 14px;
    width: 18px;
    border-radius: 5px;
    height: 18px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    cursor: pointer; }
  .form input[type=checkbox]:checked {
    background: #abb8bd; }
  .form label {
    font-size: 14px;
    color: white;
    letter-spacing: 0.5px; }
  .form .required {
    color: white;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 10px; }
  .form .error {
    padding: 1px 0px;
    text-align: end;
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 0.5px; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.Bredcrumb {
  display: flex;
  background: #0d2233; }
  .Bredcrumb .list {
    display: inline-flex;
    margin-left: 0.25rem;
    align-items: center;
    list-style: none;
    margin-bottom: 0px; }
    @media (max-width: 767px) {
      .Bredcrumb .list {
        padding-left: 1rem;
        flex-wrap: wrap; } }
  .Bredcrumb .list-center {
    display: inline-flex;
    align-items: center; }
  .Bredcrumb .list-icon {
    color: #9CA3AF;
    width: 1rem;
    height: 1rem; }
  .Bredcrumb .list-icon-home {
    color: #9CA3AF;
    width: 1rem;
    height: 1rem; }
  .Bredcrumb .list-title {
    margin-left: 0.25rem;
    color: #6B7280;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    text-decoration: none; }
  .Bredcrumb .list-title:hover {
    color: #6B7280; }
  .Bredcrumb .list-title-last {
    margin-left: 0.25rem;
    color: #6B7280;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    text-decoration: none;
    margin: 0px; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
@media (min-width: 768px) {
  .article-campaign {
    padding: 100px 60px; } }

@media (min-width: 992px) {
  .article-campaign {
    padding: 100px 80px; } }

@media (min-width: 1200px) {
  .article-campaign {
    padding: 100px 100px; } }

@media (min-width: 1440px) {
  .article-campaign {
    padding: 100px 140px; } }

.article-campaign .article-title {
  letter-spacing: 2px;
  width: 100%;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .article-campaign .article-title {
      font-size: 25px; } }
  @media (min-width: 992px) {
    .article-campaign .article-title {
      font-size: 27px; } }
  @media (min-width: 1200px) {
    .article-campaign .article-title {
      font-size: 32px; } }

.article-campaign .article-image {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: top; }

.article-campaign .article-description {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  margin-top: 30px; }
  @media (max-width: 767px) {
    .article-campaign .article-description {
      font-size: 13px; } }
  @media (min-width: 992px) {
    .article-campaign .article-description {
      font-size: 15px; } }

@media (min-width: 768px) {
  .article-campaign .right-column-article {
    padding-left: 50px; } }

@media (min-width: 992px) {
  .article-campaign .right-column-article {
    padding-left: 50px; } }

@media (min-width: 1200px) {
  .article-campaign .right-column-article {
    padding-left: 100px; } }

.article-campaign .right-column-article .card-title {
  font-size: 14px;
  font-weight: 700; }

.article-campaign .right-column-article .related {
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  letter-spacing: 2px;
  margin-bottom: 20px; }

@media (min-width: 768px) {
  .news-mobile-article {
    display: none; }
  .campaign-mobile-article {
    display: block; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
@media (max-width: 767px) {
  .article-campaign {
    display: none; }
  .campaign-mobile-article {
    display: block;
    padding: 10px 40px; }
    .campaign-mobile-article .image {
      padding: 24px; }
      .campaign-mobile-article .image img {
        object-fit: cover;
        height: 213px;
        width: 300px; }
    .campaign-mobile-article .contents {
      color: #0E2538; }
      .campaign-mobile-article .contents .title {
        font-family: 'Times New Roman', Times, serif;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px; }
      .campaign-mobile-article .contents .description {
        font-size: 12px;
        text-align: center; }
  .arrow-settings {
    color: #0E2538;
    font-size: 14px;
    font-weight: 600; }
    .arrow-settings .left {
      float: left; }
      .arrow-settings .left .left-arrow {
        width: 20px; }
    .arrow-settings .right {
      float: right; }
      .arrow-settings .right .right-arrow {
        width: 20px; } }

@media (min-width: 768px) {
  .article-campaign {
    display: block; }
  .campaign-mobile-article {
    display: none; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.campaign-home {
  background: #abb8bd6e; }
  @media (max-width: 767px) {
    .campaign-home {
      padding: 40px 0px 40px 0px; } }
  @media (min-width: 768px) {
    .campaign-home {
      padding: 70px 0px 70px 0px; } }
  .campaign-home .container-fluid {
    width: 90%; }
    .campaign-home .container-fluid .left-banner .campaigns-banner {
      width: 100%;
      background-position: 0%;
      background-repeat: no-repeat;
      -webkit-transition: all 1000ms ease;
      transition: all 1000ms ease;
      background-size: cover; }
      @media (max-width: 767px) {
        .campaign-home .container-fluid .left-banner .campaigns-banner {
          margin: 0px 0px 30px 0px; } }
      @media (min-width: 768px) {
        .campaign-home .container-fluid .left-banner .campaigns-banner {
          margin: 0px 0px 50px 0px; } }
      @media (min-width: 992px) {
        .campaign-home .container-fluid .left-banner .campaigns-banner {
          margin: 0px 0px 67px 0px; } }
      @media (min-width: 360px) and (max-width: 376px) {
        .campaign-home .container-fluid .left-banner .campaigns-banner {
          height: 190px; } }
      @media (max-width: 767px) {
        .campaign-home .container-fluid .left-banner .campaigns-banner {
          height: 220px; } }
      @media (min-width: 768px) {
        .campaign-home .container-fluid .left-banner .campaigns-banner {
          height: 340px; } }
      @media (min-width: 992px) {
        .campaign-home .container-fluid .left-banner .campaigns-banner {
          height: 500px; } }
      @media (min-width: 1200px) {
        .campaign-home .container-fluid .left-banner .campaigns-banner {
          height: 570px; } }
      @media (min-width: 1440px) {
        .campaign-home .container-fluid .left-banner .campaigns-banner {
          height: 625px; } }
    .campaign-home .container-fluid .left-banner .description {
      position: relative;
      top: 81%; }
      @media (min-width: 768px) {
        .campaign-home .container-fluid .left-banner .description {
          top: 75%; } }
      @media (min-width: 992px) {
        .campaign-home .container-fluid .left-banner .description {
          top: 80%; } }
      @media (min-width: 1440px) {
        .campaign-home .container-fluid .left-banner .description {
          top: 81%; } }
      .campaign-home .container-fluid .left-banner .description .title {
        text-align: center;
        color: white;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-family: 'Times New Roman', Times, serif; }
        @media (min-width: 360px) and (max-width: 376px) {
          .campaign-home .container-fluid .left-banner .description .title {
            font-size: 9px; } }
        @media (max-width: 767px) {
          .campaign-home .container-fluid .left-banner .description .title {
            font-size: 10px; } }
        @media (min-width: 768px) {
          .campaign-home .container-fluid .left-banner .description .title {
            font-size: 14px; } }
        @media (min-width: 992px) {
          .campaign-home .container-fluid .left-banner .description .title {
            font-size: 17px; } }
        @media (min-width: 1200px) {
          .campaign-home .container-fluid .left-banner .description .title {
            font-size: 18px; } }
        @media (min-width: 1440px) {
          .campaign-home .container-fluid .left-banner .description .title {
            font-size: 21px; } }
      .campaign-home .container-fluid .left-banner .description .date {
        font-size: 12px;
        color: white;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600; }
    .campaign-home .container-fluid .right-banner {
      padding: 0px 0px 0px 5px; }
      .campaign-home .container-fluid .right-banner .campaigns-banner {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-transition: all 1000ms ease;
        transition: all 1000ms ease;
        background-size: cover; }
        @media (min-width: 768px) {
          .campaign-home .container-fluid .right-banner .campaigns-banner {
            height: 165px; } }
        @media (min-width: 992px) {
          .campaign-home .container-fluid .right-banner .campaigns-banner {
            height: 244px; } }
        @media (min-width: 992px) {
          .campaign-home .container-fluid .right-banner .campaigns-banner {
            height: 244px; } }
        @media (min-width: 1200px) {
          .campaign-home .container-fluid .right-banner .campaigns-banner {
            height: 280px; } }
        @media (min-width: 1440px) {
          .campaign-home .container-fluid .right-banner .campaigns-banner {
            height: 307px; } }
      .campaign-home .container-fluid .right-banner .description {
        position: relative; }
        @media (min-width: 768px) {
          .campaign-home .container-fluid .right-banner .description {
            top: 50%; } }
        @media (min-width: 992px) {
          .campaign-home .container-fluid .right-banner .description {
            top: 64%; } }
        @media (min-width: 1440px) {
          .campaign-home .container-fluid .right-banner .description {
            top: 64%; } }
        .campaign-home .container-fluid .right-banner .description .title {
          text-align: center;
          color: white;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-family: 'Times New Roman', Times, serif; }
          @media (min-width: 768px) {
            .campaign-home .container-fluid .right-banner .description .title {
              font-size: 14px; } }
          @media (min-width: 992px) {
            .campaign-home .container-fluid .right-banner .description .title {
              font-size: 17px; } }
          @media (min-width: 1200px) {
            .campaign-home .container-fluid .right-banner .description .title {
              font-size: 18px; } }
          @media (min-width: 1440px) {
            .campaign-home .container-fluid .right-banner .description .title {
              font-size: 21px; } }
        .campaign-home .container-fluid .right-banner .description .date {
          font-size: 12px;
          color: white;
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 600; }
      .campaign-home .container-fluid .right-banner .second-image-padding {
        padding: 10px 15px; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.contact-us-main {
  background: #0E2538; }
  .contact-us-main .small-description {
    font-size: 24px;
    text-transform: uppercase;
    color: #abb8bd;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px; }
  .contact-us-main .address-rows .column {
    background-color: transparent; }
    @media (max-width: 767px) {
      .contact-us-main .address-rows .column {
        padding: 0px 12px 20px 12px; } }
    @media (min-width: 768px) {
      .contact-us-main .address-rows .column {
        padding: 0px 12px 20px 12px; } }
    @media (min-width: 992px) {
      .contact-us-main .address-rows .column {
        padding: 12px 12px; } }
    @media (min-width: 1200px) {
      .contact-us-main .address-rows .column {
        padding: 20px 16px; } }
    .contact-us-main .address-rows .column .card {
      background-color: transparent;
      border: 1px solid #abb8bd; }
    .contact-us-main .address-rows .column .hotel-name {
      font-family: 'Times New Roman', Times, serif;
      font-size: 20px;
      color: #abb8bd;
      letter-spacing: 1px; }
    .contact-us-main .address-rows .column .address {
      height: 50px; }
    @media (min-width: 992px) {
      .contact-us-main .address-rows .column .phone {
        margin-top: 25px; } }
    .contact-us-main .address-rows .column .phone svg {
      width: 20px; }
    .contact-us-main .address-rows .column .clock svg {
      width: 20px; }
    .contact-us-main .address-rows .column .email svg {
      width: 20px; }
    .contact-us-main .address-rows .column .direction svg {
      width: 20px; }
    .contact-us-main .address-rows .column .direction a {
      text-decoration: underline; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.policy-container {
  padding: 4em 4em;
  background-color: #0E2538; }
  @media (max-width: 767px) {
    .policy-container {
      padding: 1.5em; } }
  @media (min-width: 768px) {
    .policy-container {
      padding: 4em 4em; } }
  .policy-container .main-description {
    font-family: 'Times New Roman', Times, serif;
    color: #abb8bd; }
    .policy-container .main-description .child-head {
      padding: 15px 0px; }
    .policy-container .main-description .title {
      color: #abb8bd;
      font-family: 'Times New Roman', Times, serif;
      letter-spacing: 2.3px;
      font-size: 22px;
      text-transform: uppercase; }
      .policy-container .main-description .title a {
        color: #abb8bd;
        text-decoration: none; }
    .policy-container .main-description .description {
      color: white;
      letter-spacing: 0.5px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 16px;
      line-height: 24px; }
      .policy-container .main-description .description .specify-text {
        text-decoration: underline; }
    .policy-container .main-description .description-list {
      padding: 0px;
      list-style: none; }
      .policy-container .main-description .description-list li {
        margin: 0;
        margin-bottom: 0.5em;
        padding-left: 1.5em;
        position: relative;
        color: white;
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif; }
        .policy-container .main-description .description-list li:after {
          content: '';
          height: .4em;
          width: .4em;
          background: white;
          display: block;
          position: absolute;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
          top: 5px;
          left: 0;
          font-size: 22px; }

#CookieReportsAccordion {
  width: 102%; }
  #CookieReportsAccordion #wscrAccordionInjectContent span {
    color: #abb8bd !important;
    font-size: 12px !important; }
  #CookieReportsAccordion #wscrAccordionInjectContent a {
    color: #abb8bd !important;
    font-size: 12px; }
    #CookieReportsAccordion #wscrAccordionInjectContent a span {
      color: #abb8bd !important; }
  #CookieReportsAccordion #CookieReportsAccordionSections .CookieReportsAccordionHeading {
    outline: none; }
  #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionCount {
    min-width: 25px; }
    @media (max-width: 767px) {
      #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionCount {
        min-width: 12px; } }
  #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle {
    padding: 0px; }
    #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle .wscrSectionTitleText {
      color: #0E2538;
      letter-spacing: 1.5px;
      font-size: 19px;
      cursor: pointer;
      font-family: 'Times New Roman', Times, serif;
      text-transform: uppercase; }
      @media (max-width: 767px) {
        #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle .wscrSectionTitleText {
          font-size: 12px; } }
      @media (min-width: 768px) {
        #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle .wscrSectionTitleText {
          font-size: 18px; } }
      @media (min-width: 1200px) {
        #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle .wscrSectionTitleText {
          font-size: 19px; } }
  #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitleConsent .wscrSwitchParts {
    border: 2px solid #0E2538; }
    #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitleConsent .wscrSwitchParts .wscrSwitchLabel:before {
      padding-left: 10px;
      background-color: white;
      color: #0E2538;
      border: none; }
    #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitleConsent .wscrSwitchParts .wscrSwitchLabel:after {
      content: "\2716";
      padding-right: 10px;
      background-color: white;
      text-align: right;
      color: #0E2538; }
  #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitleConsent .wscrSwitchToggle {
    background: #0E2538; }
  #CookieReportsAccordion #CookieReportsAccordionSections dd .wscrSectionDescription {
    font-family: Arial, Helvetica, sans-serif;
    padding: 11px 14px;
    color: white;
    font-size: 14px; }
    #CookieReportsAccordion #CookieReportsAccordionSections dd .wscrSectionDescription p {
      font-size: 14px;
      font-family: Arial, Helvetica, sans-serif;
      color: white;
      letter-spacing: 1px;
      line-height: 1.5; }
  #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies p {
    text-align: center;
    padding: 0px 0px 18px 0px;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    letter-spacing: 1px;
    line-height: 1.5; }
  #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table th {
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase; }
  #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table td {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px; }
    @media (max-width: 767px) {
      #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table td {
        font-size: 10px; } }
    @media (min-width: 768px) {
      #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table td {
        font-size: 14px; } }
  #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table .wscrField-description {
    font-size: 12px; }
    @media (max-width: 767px) {
      #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table .wscrField-description {
        padding: 10px 0px 20px 0px; } }
    @media (min-width: 768px) {
      #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table .wscrField-description {
        padding: 10px 10px 20px 10px; } }
    #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table .wscrField-description .wscrCompanyPrivacy li a {
      color: #abb8bd;
      font-size: 12px; }

@media (max-width: 767px) {
  .wscrSwitch {
    zoom: 0.7;
    position: relative;
    left: 29px; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.hotel-faq-wrapper {
  background-color: #0f2538;
  padding: 50px 0px; }
  .hotel-faq-wrapper .arrow-icon {
    width: 12px;
    height: 12px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 6px;
    position: absolute;
    left: 99%;
    top: 0%;
    bottom: 0%; }
    @media (max-width: 767px) {
      .hotel-faq-wrapper .arrow-icon {
        margin-left: 16px; } }
    .hotel-faq-wrapper .arrow-icon.rotate {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      position: absolute;
      left: 99%;
      top: 0%;
      bottom: 0%;
      margin-top: 6px; }
  .hotel-faq-wrapper .hotel-faq-title {
    position: relative;
    height: 17px;
    left: 0%;
    right: 0%;
    font-family: "Gilroy";
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 2.33333px;
    text-transform: uppercase;
    color: #ede2d4;
    margin-bottom: 10px; }
    @media (max-width: 767px) {
      .hotel-faq-wrapper .hotel-faq-title {
        height: unset;
        top: 46px;
        margin-bottom: 64px; } }
  .hotel-faq-wrapper .hotel-faq-subtitle {
    position: relative;
    height: 50px;
    left: 0%;
    right: 0%;
    font-family: "TFArrow";
    font-style: normal;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #ffffff; }
    @media (max-width: 767px) {
      .hotel-faq-wrapper .hotel-faq-subtitle {
        height: 40px;
        font-size: 34px;
        line-height: 40px; } }
  .hotel-faq-wrapper .question-description {
    position: relative;
    font-family: "TFArrow";
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    color: #ffffff;
    margin-top: 20px;
    width: 916px;
    cursor: pointer;
    margin: 16px auto 0 auto; }
    @media (max-width: 767px) {
      .hotel-faq-wrapper .question-description {
        width: 84%;
        margin-left: 16px; } }
    @media (min-width: 768px) {
      .hotel-faq-wrapper .question-description {
        font-size: 18px; } }
  .hotel-faq-wrapper .border-bottom-label {
    width: 916px;
    height: 0px;
    top: 226px;
    margin: 0 auto;
    border-bottom: 1px solid #c6d8e1;
    margin-top: 15px; }
    @media (max-width: 767px) {
      .hotel-faq-wrapper .border-bottom-label {
        width: 92%;
        margin-top: 16px; } }
    .hotel-faq-wrapper .border-bottom-label.border-bottom-label-wrapper {
      width: 916px;
      height: 0px;
      top: 226px;
      margin: 0 auto;
      border-bottom: 1px solid #c6d8e1; }
      @media (max-width: 767px) {
        .hotel-faq-wrapper .border-bottom-label.border-bottom-label-wrapper {
          width: 92%; } }
  .hotel-faq-wrapper .answer-description {
    position: relative;
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: #ffffff;
    text-align: left;
    width: 916px;
    margin: 10px auto 15px; }
    @media (max-width: 767px) {
      .hotel-faq-wrapper .answer-description {
        width: 93%;
        margin-top: 9%;
        left: 0%;
        word-break: break-all; } }

.tablet-device .hotel-faq-wrapper .hotel-faq-title {
  height: unset; }

.tablet-device .hotel-faq-wrapper .hotel-faq-subtitle {
  font-size: 37px;
  line-height: 44px; }

.tablet-device .hotel-faq-wrapper .question-description {
  width: 84%; }
  @media only screen and (max-device-width: 1366px) and (orientation: portrait) {
    .tablet-device .hotel-faq-wrapper .question-description {
      right: 4%;
      height: 44px; } }

.tablet-device .hotel-faq-wrapper .border-bottom-label {
  width: 86%; }
  @media only screen and (max-device-width: 1366px) and (orientation: landscape) {
    .tablet-device .hotel-faq-wrapper .border-bottom-label {
      width: 84%; } }
  @media only screen and (max-device-width: 1366px) and (orientation: portrait) {
    .tablet-device .hotel-faq-wrapper .border-bottom-label {
      margin-left: 4%; } }

.tablet-device .hotel-faq-wrapper .answer-description {
  width: 84%; }
  @media only screen and (max-device-width: 1366px) and (orientation: portrait) {
    .tablet-device .hotel-faq-wrapper .answer-description {
      right: 4%; } }

@media (max-width: 767px) {
  .full-width-component.hotel-faq-wrapper {
    margin: 10px auto; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.dining-main-container {
  padding: 80px 50px 75px 50px; }
  @media (max-width: 767px) {
    .dining-main-container {
      padding: 80px 0px 75px 0px; } }
  @media (min-width: 768px) {
    .dining-main-container {
      padding: 80px 50px 75px 50px; } }
  .dining-main-container .findout-more {
    border-radius: 6px;
    background: #abb8bd;
    color: #0E2538;
    border: 1px solid #abb8bd;
    width: 48%;
    max-width: 150px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    padding: 6px 0px;
    box-shadow: none; }
    @media (min-width: 768px) {
      .dining-main-container .findout-more {
        font-size: 11px;
        padding: 3px 0px;
        width: 40%;
        margin: 10px 0px 0px 0px; } }
    @media (min-width: 992px) {
      .dining-main-container .findout-more {
        font-size: 12px;
        padding: 5px 0px;
        width: 48%;
        margin: 0; } }
    @media (min-width: 1200px) {
      .dining-main-container .findout-more {
        font-size: 13px;
        width: 48%; } }
  .dining-main-container .banner-container {
    text-align: center; }
    .dining-main-container .banner-container .slick-slider .slick-dots {
      bottom: 5px;
      z-index: 9; }
      .dining-main-container .banner-container .slick-slider .slick-dots li {
        margin: 0px; }
        .dining-main-container .banner-container .slick-slider .slick-dots li.slick-active button::before {
          color: white;
          font-size: 9px;
          opacity: 1; }
      .dining-main-container .banner-container .slick-slider .slick-dots li button {
        background: transparent;
        border: 2px solid #fff;
        border-radius: 50%;
        height: 9px;
        line-height: 0;
        opacity: 1;
        padding: 0;
        width: 9px; }
      .dining-main-container .banner-container .slick-slider .slick-dots li button:before {
        color: transparent;
        line-height: 11px;
        opacity: 1;
        width: 8px; }
  .dining-main-container .dinning-description .list-items {
    padding: 80px 15px 50px 15px;
    padding: 25px 0px 0px 0px; }
    .dining-main-container .dinning-description .list-items table {
      margin: 0px; }
      .dining-main-container .dinning-description .list-items table .thead-light th {
        background-color: #0E2538;
        color: #abb8bd;
        font-family: 'Times New Roman', Times, serif;
        font-weight: 500;
        font-size: 20px;
        padding: 3px 6px;
        letter-spacing: 0.5px;
        border-top: 1px solid #abb8bd;
        border-bottom: 1px solid #abb8bd;
        text-transform: uppercase; }
      .dining-main-container .dinning-description .list-items table tbody tr td {
        width: 30%; }
      .dining-main-container .dinning-description .list-items table tbody tr:nth-of-type(even) {
        background-color: #e5e9eb;
        font-size: 14px; }
        .dining-main-container .dinning-description .list-items table tbody tr:nth-of-type(even) td {
          padding: 0.32rem 0.35rem;
          font-family: Arial, Helvetica, sans-serif; }
      .dining-main-container .dinning-description .list-items table tbody tr:nth-of-type(odd) {
        background-color: unset !important;
        font-size: 14px; }
        .dining-main-container .dinning-description .list-items table tbody tr:nth-of-type(odd) td {
          padding: 0.32rem 0.35rem;
          font-family: Arial, Helvetica, sans-serif; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.event-detail-main-container .left-background-image {
  height: 93vh;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  background-size: cover; }
  @media (min-width: 768px) {
    .event-detail-main-container .left-background-image {
      height: 64vh; } }
  @media (min-width: 992px) {
    .event-detail-main-container .left-background-image {
      height: 90vh; } }
  @media (min-width: 1200px) {
    .event-detail-main-container .left-background-image {
      height: 90vh; } }
  @media (min-width: 1440px) {
    .event-detail-main-container .left-background-image {
      height: 93vh; } }
  @media (max-width: 767px) {
    .event-detail-main-container .left-background-image {
      height: 35vh; } }

.event-detail-main-container .right-background-event {
  padding: 5em 4em 2em 5em;
  background: #abb8bd; }
  @media (min-width: 768px) {
    .event-detail-main-container .right-background-event {
      padding: 1em 2em 1em 2em; } }
  @media (min-width: 1200px) {
    .event-detail-main-container .right-background-event {
      padding: 2em 2em 2em 3em; } }
  @media (max-width: 767px) {
    .event-detail-main-container .right-background-event {
      padding: 36px 36px;
      background: none; } }
  .event-detail-main-container .right-background-event .event-title {
    font-family: 'Times New Roman', Times, serif;
    font-size: 26px;
    letter-spacing: 2px;
    color: #0E2538; }
    @media (max-width: 767px) {
      .event-detail-main-container .right-background-event .event-title {
        text-align: center; } }
  .event-detail-main-container .right-background-event .show-mobile-location {
    display: none; }
    @media (max-width: 767px) {
      .event-detail-main-container .right-background-event .show-mobile-location {
        display: block;
        text-align: center;
        font-family: Arial;
        font-weight: 600;
        letter-spacing: 1px;
        padding: 9px 0px; } }
  .event-detail-main-container .right-background-event .event-location-time {
    padding: 15px 0px 18px 0px; }
    @media (max-width: 767px) {
      .event-detail-main-container .right-background-event .event-location-time {
        padding: 0px; } }
    .event-detail-main-container .right-background-event .event-location-time p {
      color: #0E2538;
      font-size: 14px;
      font-weight: 600;
      font-family: Arial, Helvetica, sans-serif;
      padding: 0px;
      margin: 0;
      letter-spacing: 0.6px; }
      @media (max-width: 767px) {
        .event-detail-main-container .right-background-event .event-location-time p {
          display: none; } }
  @media (min-width: 768px) {
    .event-detail-main-container .right-background-event .event-description {
      max-height: 46vh;
      overflow-y: auto; } }
  @media (min-width: 992px) {
    .event-detail-main-container .right-background-event .event-description {
      max-height: 55vh;
      overflow-y: auto; } }
  @media (min-width: 1200px) {
    .event-detail-main-container .right-background-event .event-description {
      max-height: 58vh;
      overflow-y: auto; } }
  @media (min-width: 1440px) {
    .event-detail-main-container .right-background-event .event-description {
      max-height: 63vh;
      overflow-y: auto; } }
  .event-detail-main-container .right-background-event .event-description::-webkit-scrollbar {
    width: 5px; }
  .event-detail-main-container .right-background-event .event-description::-webkit-scrollbar-track {
    background: #f1f1f1;
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px; }
  .event-detail-main-container .right-background-event .event-description::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px; }
  .event-detail-main-container .right-background-event .event-description .readmore {
    background: #0E2538;
    color: #abb8bd;
    padding: 5px 19px;
    border-radius: 6px;
    font-size: 14px;
    box-shadow: none; }
  .event-detail-main-container .right-background-event .event-description P {
    color: #0E2538;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 0.5px; }
    @media (max-width: 767px) {
      .event-detail-main-container .right-background-event .event-description P {
        padding: 0px; } }
    @media (min-width: 768px) {
      .event-detail-main-container .right-background-event .event-description P {
        padding: 0px; } }
  .event-detail-main-container .right-background-event .show-mobile-detail {
    display: none; }
    @media (max-width: 767px) {
      .event-detail-main-container .right-background-event .show-mobile-detail {
        display: block;
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-family: Arial;
        font-weight: 600;
        text-align: center; }
        .event-detail-main-container .right-background-event .show-mobile-detail p {
          padding: 2px 0px;
          margin: 0;
          color: #0E2538; } }
  .event-detail-main-container .right-background-event .booknow-button {
    padding: 20px 0px; }
    @media (max-width: 767px) {
      .event-detail-main-container .right-background-event .booknow-button {
        text-align: center; } }
    .event-detail-main-container .right-background-event .booknow-button .btn {
      background: #10243c;
      color: #abb8bd;
      border-radius: 0px;
      color: #abb8bd;
      font-weight: 600;
      box-shadow: none;
      padding: 7px 27px; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.event-gallery-main-container {
  padding: 3em 3em; }
  .event-gallery-main-container .events-gallery-title .title {
    color: #abb8bd;
    text-align: center;
    font-size: 24px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px;
    margin: 0px;
    padding: 0px;
    padding: 0px 0px 2px 0px; }
  .event-gallery-main-container .events-gallery-title .description {
    font-family: Arial;
    color: #0E2538;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 8px 0px; }
    .event-gallery-main-container .events-gallery-title .description span {
      cursor: pointer;
      color: #0E2538; }
    .event-gallery-main-container .events-gallery-title .description .activeclass {
      border-bottom-width: 2px;
      border-bottom-style: solid;
      border-color: #0E2538; }
  @media (max-width: 767px) {
    .event-gallery-main-container .event-gallry-image {
      padding: 0px; } }
  @media (min-width: 992px) {
    .event-gallery-main-container .event-gallry-image {
      padding: 1em 4em; } }
  @media (min-width: 1200px) {
    .event-gallery-main-container .event-gallry-image {
      padding: 2em 4em; } }
  .event-gallery-main-container .event-gallry-image .gallery-background-sub {
    padding: 10px 6px; }
    .event-gallery-main-container .event-gallry-image .gallery-background-sub .gallery-background-image {
      background-repeat: no-repeat;
      background-position: center center;
      -webkit-transition: all 1000ms ease;
      transition: all 1000ms ease;
      background-size: cover; }
      @media (max-width: 767px) {
        .event-gallery-main-container .event-gallry-image .gallery-background-sub .gallery-background-image {
          height: 15vh; } }
      @media (min-width: 768px) {
        .event-gallery-main-container .event-gallry-image .gallery-background-sub .gallery-background-image {
          height: 14vh; } }
      @media (min-width: 992px) {
        .event-gallery-main-container .event-gallry-image .gallery-background-sub .gallery-background-image {
          height: 30vh; } }
    .event-gallery-main-container .event-gallry-image .gallery-background-sub .play-button {
      position: absolute;
      top: 36%;
      font-size: 45px;
      left: 42%;
      color: #abb8bd; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.event-gallery-modal .profile-bg-image {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  background-size: cover;
  margin: -2px 0px; }
  @media (max-width: 767px) {
    .event-gallery-modal .profile-bg-image {
      height: 221px; } }

.event-gallery-modal .gallerymodal-img {
  height: 450px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  background-size: cover; }

.event-gallery-modal .gallery-user-settings {
  background: #0E2538;
  padding: 8px 9px;
  margin: -6px 0px; }
  .event-gallery-modal .gallery-user-settings .left-close {
    display: inline; }
    .event-gallery-modal .gallery-user-settings .left-close img {
      cursor: pointer;
      width: 30px; }
  .event-gallery-modal .gallery-user-settings .right-controls {
    display: inline;
    position: absolute;
    right: 9px; }
    .event-gallery-modal .gallery-user-settings .right-controls .left-arrow {
      cursor: pointer;
      width: 22px;
      margin: 0px 19px; }
    .event-gallery-modal .gallery-user-settings .right-controls .right-arrow {
      cursor: pointer;
      width: 21px; }

.modal-backdrop.show {
  opacity: .8; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.event-gallery-modal .profile-bg-image {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  background-size: cover;
  margin: -2px 0px; }
  @media (max-width: 767px) {
    .event-gallery-modal .profile-bg-image {
      height: 221px; } }

.event-gallery-modal .gallerymodal-img {
  height: 450px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  background-size: cover; }

.event-gallery-modal .gallery-user-settings {
  background: #0E2538;
  padding: 8px 9px;
  margin: -6px 0px; }
  .event-gallery-modal .gallery-user-settings .left-close {
    display: inline; }
    .event-gallery-modal .gallery-user-settings .left-close img {
      cursor: pointer;
      width: 30px; }
  .event-gallery-modal .gallery-user-settings .right-controls {
    display: inline;
    position: absolute;
    right: 9px; }
    .event-gallery-modal .gallery-user-settings .right-controls .left-arrow {
      cursor: pointer;
      width: 22px;
      margin: 0px 19px; }
    .event-gallery-modal .gallery-user-settings .right-controls .right-arrow {
      cursor: pointer;
      width: 21px; }

.modal-backdrop.show {
  opacity: .8; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
@media (min-width: 1440px) {
  .col-xll-4 {
    flex: 0 0 25%;
    max-width: 25%; } }

@media (max-width: 767px) {
  .mobile-previous-events {
    display: block; }
  .desktop-events-filter {
    display: none; } }

@media (min-width: 768px) {
  .mobile-previous-events {
    display: none; }
  .desktop-events-filter {
    display: block; } }

.previous-events-main-container {
  padding: 55px 31px 46px 31px; }
  @media (max-width: 767px) {
    .previous-events-main-container {
      padding: 0px 31px 46px 31px; } }
  @media (max-width: 767px) {
    .previous-events-main-container .previous-events-filter {
      padding: 20px 0px 35px 0px; } }
  .previous-events-main-container .previous-events-filter .title {
    color: #0E2538;
    text-align: center;
    font-size: 24px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .previous-events-main-container .previous-events-filter .title {
        font-size: 20px; } }
    @media (min-width: 768px) {
      .previous-events-main-container .previous-events-filter .title {
        font-size: 24px; } }
  .previous-events-main-container .previous-events-filter .filters .filter-label {
    font-size: 14px; }
    @media (max-width: 767px) {
      .previous-events-main-container .previous-events-filter .filters .filter-label {
        font-size: 12px; } }
    @media (min-width: 768px) {
      .previous-events-main-container .previous-events-filter .filters .filter-label {
        font-size: 14px; } }
  .previous-events-main-container .previous-events-filter .filters span {
    color: #0E2538;
    font-weight: 600; }
  .previous-events-main-container .previous-events-filter .filters select {
    display: inline;
    height: 24px;
    padding: 0px 7px;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 400;
    box-shadow: none;
    color: #abb8bd; }
    @media (min-width: 992px) {
      .previous-events-main-container .previous-events-filter .filters select {
        width: 35%; } }
    @media (min-width: 1200px) {
      .previous-events-main-container .previous-events-filter .filters select {
        width: 30%; } }

.no-filter-date {
  margin: auto; }

.mobile-previous-events {
  text-align: center; }
  .mobile-previous-events .title {
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px;
    color: #0E2538;
    text-transform: uppercase; }
  .mobile-previous-events .filter-label {
    color: #0E2538; }
    @media (max-width: 767px) {
      .mobile-previous-events .filter-label {
        padding: 10px 0px 5px 0px; } }
    @media (min-width: 768px) {
      .mobile-previous-events .filter-label {
        padding: 10px 0px; } }
  .mobile-previous-events .select-date {
    color: #abb8bd;
    width: 65%;
    margin: auto;
    border-radius: 8px;
    height: 34px;
    box-shadow: none;
    font-size: 13px; }

.previous-all-events {
  padding: 2rem 5rem 2rem 5rem; }
  @media (max-width: 767px) {
    .previous-all-events {
      padding: 0px; } }
  .previous-all-events .previous-all-events-slider {
    padding: 20px 5px; }
    @media (max-width: 767px) {
      .previous-all-events .previous-all-events-slider {
        padding: 20px 5px 5px 5px; } }
    @media (min-width: 768px) {
      .previous-all-events .previous-all-events-slider {
        padding: 20px 5px; } }
    .previous-all-events .previous-all-events-slider .profile .events-bg-image {
      width: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover; }
      @media (max-width: 767px) {
        .previous-all-events .previous-all-events-slider .profile .events-bg-image {
          height: 145px; } }
      @media (min-width: 768px) {
        .previous-all-events .previous-all-events-slider .profile .events-bg-image {
          height: 190px; } }
    .previous-all-events .previous-all-events-slider .profile .card-body {
      padding: 0px;
      border-bottom: 2px solid #c8d0d3;
      border-left: 2px solid #c8d0d3;
      border-right: 2px solid #c8d0d3; }
      @media (max-width: 767px) {
        .previous-all-events .previous-all-events-slider .profile .card-body {
          border: none; } }
      .previous-all-events .previous-all-events-slider .profile .card-body .mobile-title {
        color: #0E2538;
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        margin: 0px; }
        @media (max-width: 767px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .mobile-title {
            padding: 13px 0px 0px 0px;
            display: block; } }
        @media (min-width: 768px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .mobile-title {
            padding: 7px 0px 0px 0px;
            display: none; } }
      .previous-all-events .previous-all-events-slider .profile .card-body .title {
        margin: 0;
        font-weight: 600;
        text-align: center;
        margin: 0;
        font-family: Arial, Helvetica, sans-serif;
        color: #0E2538;
        background: white;
        padding: 9px 0px;
        position: relative;
        top: -18px;
        left: 19%;
        font-size: 12px;
        letter-spacing: 0.5px;
        width: 61%; }
      .previous-all-events .previous-all-events-slider .profile .card-body .border-bottom {
        width: 13%;
        margin: auto;
        padding: 0px;
        border-bottom: 2px solid #c8d0d3 !important;
        position: relative;
        bottom: 15px; }
      @media (max-width: 767px) {
        .previous-all-events .previous-all-events-slider .profile .card-body .mobile_final_description {
          display: block; }
        .previous-all-events .previous-all-events-slider .profile .card-body .desktop_final_description {
          display: none; } }
      @media (min-width: 768px) {
        .previous-all-events .previous-all-events-slider .profile .card-body .mobile_final_description {
          display: none; }
        .previous-all-events .previous-all-events-slider .profile .card-body .desktop_final_description {
          display: block; } }
      .previous-all-events .previous-all-events-slider .profile .card-body .description {
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        color: #0E2538;
        font-size: 13px;
        padding: 0px 20px 0px 20px;
        letter-spacing: 0.3px;
        line-height: 1.6;
        height: 60px; }
        @media (max-width: 767px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .description {
            height: 40px; } }
        @media (min-width: 768px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .description {
            height: 60px; } }
      .previous-all-events .previous-all-events-slider .profile .card-body .events {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
        color: #abb8bd;
        text-align: center;
        letter-spacing: 0.5px;
        font-size: 15px;
        text-transform: uppercase;
        padding: 12px 0px 0px 0px; }
      .previous-all-events .previous-all-events-slider .profile .card-body .events-showdate {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
        color: #abb8bd;
        text-align: center;
        letter-spacing: 0.5px;
        font-size: 15px;
        text-transform: uppercase;
        padding: 12px 0px 0px 0px; }
        @media (max-width: 767px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .events-showdate {
            display: none; } }
        @media (min-width: 768px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .events-showdate {
            display: block; } }
        @media (max-width: 767px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .events-showdate {
            padding: 12px 0px 0px 0px; } }
        @media (min-width: 768px) {
          .previous-all-events .previous-all-events-slider .profile .card-body .events-showdate {
            padding: 12px 0px 12px 0px; } }
      .previous-all-events .previous-all-events-slider .profile .card-body .main-link {
        padding: 0px 10px 7px 10px; }
        .previous-all-events .previous-all-events-slider .profile .card-body .main-link .info-link {
          width: 100%;
          display: inline-block;
          padding: 0px;
          margin: 0px;
          text-align: center;
          font-weight: 600;
          font-family: Arial;
          border-top: 2px solid #c8d0d3; }
          .previous-all-events .previous-all-events-slider .profile .card-body .main-link .info-link p {
            padding: 0px;
            margin: 0px;
            position: relative;
            top: 3px;
            padding: 4px 0px; }
        .previous-all-events .previous-all-events-slider .profile .card-body .main-link .book-link {
          display: inline-block;
          padding: 0px;
          margin: 0px;
          text-align: center;
          font-weight: 600;
          font-family: Arial;
          border-top: 2px solid #c8d0d3 !important; }
          .previous-all-events .previous-all-events-slider .profile .card-body .main-link .book-link p {
            padding: 0px;
            margin: 0px;
            position: relative;
            top: 3px;
            padding: 4px 0px; }
      @media (max-width: 767px) {
        .previous-all-events .previous-all-events-slider .profile .card-body .title {
          display: block; }
        .previous-all-events .previous-all-events-slider .profile .card-body .border-bottom {
          display: block; }
        .previous-all-events .previous-all-events-slider .profile .card-body .description {
          font-size: 11px;
          letter-spacing: 0;
          line-height: 1.3;
          padding: 3px 8px 0px 8px; }
        .previous-all-events .previous-all-events-slider .profile .card-body .date {
          display: none; }
        .previous-all-events .previous-all-events-slider .profile .card-body .main-link {
          padding: 0px 10px 7px 10px; }
          .previous-all-events .previous-all-events-slider .profile .card-body .main-link .info-link {
            border-top: none; }
            .previous-all-events .previous-all-events-slider .profile .card-body .main-link .info-link p {
              padding: 0px 0px;
              font-size: 10px;
              top: 0px;
              color: #abb8bd; } }

@media screen and (min-width: 300px) and (max-width: 821px) {
  .mobile-filter-adjust-event {
    text-align: center;
    padding: 0px 0px 34px 0px; } }
  @media screen and (min-width: 300px) and (max-width: 821px) and (min-width: 768px) {
    .mobile-filter-adjust-event {
      padding: 0px 0px 15px 0px; } }
  @media screen and (min-width: 300px) and (max-width: 821px) and (min-width: 992px) {
    .mobile-filter-adjust-event {
      padding: 0px 0px 34px 0px; } }

@media screen and (min-width: 300px) and (max-width: 821px) {
    .mobile-filter-adjust-event select {
      width: 35% !important;
      height: 24px !important; } }
    @media screen and (min-width: 300px) and (max-width: 821px) and (min-width: 768px) {
      .mobile-filter-adjust-event select {
        margin: 15px 0px 0px 0px !important; } }
    @media screen and (min-width: 300px) and (max-width: 821px) and (min-width: 992px) {
      .mobile-filter-adjust-event select {
        margin: 15px 0px 35px 0px !important; } }

.slick-slider .slick-arrow.slick-next {
  display: block;
  height: auto;
  padding-right: 0 !important;
  width: 30px;
  height: 30px;
  z-index: 1; }

.slick-slider .slick-next:before {
  content: none !important; }

.slick-slider .slick-arrow.slick-prev {
  display: block;
  height: auto;
  width: 30px;
  height: 30px;
  z-index: 1; }

.slick-slider .slick-prev:before {
  content: none !important; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
@media (min-width: 768px) {
  .facilities {
    padding-top: 3.5rem;
    padding-bottom: 4.7rem; } }

@media (min-width: 1200px) {
  .facilities {
    padding-top: 6rem;
    padding-bottom: 7.5rem; } }

.facilities .trademark {
  vertical-align: super;
  font-size: 10px;
  text-transform: lowercase; }

.facilities .slick-slider .slick-prev {
  left: -8.2% !important;
  margin-top: 165px; }
  @media (min-width: 360px) and (max-width: 376px) {
    .facilities .slick-slider .slick-prev {
      left: -11.5% !important; } }

.facilities .slick-slider .slick-next {
  right: -8.2% !important;
  margin-top: 150px; }
  @media (min-width: 360px) and (max-width: 376px) {
    .facilities .slick-slider .slick-next {
      right: -11.5% !important; } }

.facilities .slick-slider .slick-dots {
  bottom: 5px;
  z-index: 9; }
  .facilities .slick-slider .slick-dots li {
    margin: 0px; }
    .facilities .slick-slider .slick-dots li.slick-active button::before {
      color: white;
      font-size: 9px;
      opacity: 1; }
  .facilities .slick-slider .slick-dots li button {
    background: transparent;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 9px;
    line-height: 0;
    opacity: 1;
    padding: 0;
    width: 9px; }
  .facilities .slick-slider .slick-dots li button:before {
    color: transparent;
    line-height: 11px;
    opacity: 1;
    width: 8px; }

.facilities .facilities-desktop-view .left-banner .facilities-vertical-background {
  background: #eef1f2;
  width: 155px;
  height: 266px;
  position: absolute;
  left: -40px;
  top: 208px; }
  @media (min-width: 992px) {
    .facilities .facilities-desktop-view .left-banner .facilities-vertical-background {
      top: 145px; } }
  @media (min-width: 1440px) {
    .facilities .facilities-desktop-view .left-banner .facilities-vertical-background {
      left: -40px;
      top: 203px; } }
  .facilities .facilities-desktop-view .left-banner .facilities-vertical-background .facilities-vertical-title {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    font-size: 44px;
    position: relative;
    background: unset;
    top: 130px;
    text-transform: uppercase;
    z-index: 2;
    left: -34px;
    letter-spacing: 3px;
    color: #d6b8b5;
    font-family: 'Times New Roman', Times, serif; }

.facilities .facilities-desktop-view .left-banner .image-bg {
  position: relative;
  z-index: 1; }

.facilities .facilities-desktop-view .left-banner .facilties-content {
  border: 2px solid #abb8bd;
  padding: 15px 75px 11px 35px;
  position: relative;
  left: 54px;
  z-index: 9;
  top: 24px; }
  .facilities .facilities-desktop-view .left-banner .facilties-content .title {
    color: #0E2538;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 1px; }
  .facilities .facilities-desktop-view .left-banner .facilties-content .paragraph {
    color: #0E2538;
    font-family: Arial;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 14px; }
    .facilities .facilities-desktop-view .left-banner .facilties-content .paragraph div .trademark {
      vertical-align: super;
      font-size: 10px;
      text-transform: lowercase; }

.facilities .facilities-desktop-view .right-banner .bigbuild {
  position: relative;
  width: 40vh; }
  @media (min-width: 768px) {
    .facilities .facilities-desktop-view .right-banner .bigbuild {
      left: 5%;
      width: 300px; } }
  @media (min-width: 992px) {
    .facilities .facilities-desktop-view .right-banner .bigbuild {
      left: 0%;
      width: 300px !important; } }
  @media (min-width: 1200px) {
    .facilities .facilities-desktop-view .right-banner .bigbuild {
      width: 51vh !important; } }
  @media (min-width: 1440px) {
    .facilities .facilities-desktop-view .right-banner .bigbuild {
      left: 0%;
      width: 51vh !important; } }
  @media (min-width: 1800px) {
    .facilities .facilities-desktop-view .right-banner .bigbuild {
      left: 0%;
      width: 51vh !important; } }

@media (min-width: 992px) {
  .facilities .mobile-view {
    display: none; }
  .facilities .desktop-view {
    display: block; } }

@media (min-width: 1440px) {
  .facilities .mobile-view {
    display: none; }
  .facilities .desktop-view {
    display: block; } }

@media (min-width: 1800px) {
  .facilities .mobile-view {
    display: none; }
  .facilities .desktop-view {
    display: block; } }

@media (max-width: 767px) {
  .facilities .mobile-view {
    display: block; }
  .facilities .desktop-view {
    display: none; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .facilities-desktop-view {
    zoom: 0.9; }
    .facilities-desktop-view .left-banner {
      position: relative;
      right: -13px; }
      .facilities-desktop-view .left-banner .facilities-vertical-background {
        top: 52px !important; } }

@media screen and (min-width: 992px) and (max-width: 1079px) {
  .facilities-desktop-view {
    zoom: 0.9; }
    .facilities-desktop-view .left-banner .facilities-vertical-background {
      left: -40px !important;
      top: 125px !important; } }

@media screen and (min-width: 1080px) and (max-width: 1200px) {
  .facilities-desktop-view {
    zoom: 0.9;
    position: relative;
    left: 22px; }
  .facilities-vertical-background {
    left: -40px !important;
    top: 123px !important; } }

@media screen and (min-width: 1200px) and (max-width: 1260px) {
  .facilities-desktop-view {
    zoom: 0.9; } }

.facilities .slick-prev {
  left: -31px !important; }

.facilities .slick-next {
  right: -31px !important; }
  .facilities .slick-next img {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1); }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.hero-container {
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
  position: relative;
  z-index: 1;
  overflow: hidden; }
  .hero-container ul {
    padding: 0px; }
  .hero-container :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.6))), -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(tranparent));
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, tranparent 100%);
    z-index: 2; }
  .hero-container .hero-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    height: 100vh; }
    .hero-container .hero-background .video-control {
      display: flex;
      align-items: center;
      align-content: center; }
    .hero-container .hero-background .video-background {
      height: 100vh;
      overflow: hidden;
      position: relative;
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .hero-container .hero-background .video-foreground {
      height: 300%;
      top: -100%; }
    .hero-container .hero-background .video-foreground {
      position: relative;
      width: 100%; }
    .hero-container .hero-background iframe {
      width: 100%;
      height: 100%; }
      @media (max-width: 767px) {
        .hero-container .hero-background iframe {
          width: 350%;
          left: -90%; } }
      @media (min-width: 768px) {
        .hero-container .hero-background iframe {
          width: 270%;
          left: -90%; } }
      @media (min-width: 992px) {
        .hero-container .hero-background iframe {
          width: 130%;
          left: 0%;
          top: 0%; } }
      @media (min-width: 1200px) {
        .hero-container .hero-background iframe {
          width: 120%;
          left: 0%;
          top: 0%; } }
    .hero-container .hero-background .res-radial-bottom {
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(12, 36, 56, 0)), color-stop(rgba(12, 36, 56, 0)), color-stop(rgba(12, 36, 56, 0)), color-stop(rgba(15, 36, 57, 0)), color-stop(rgba(15, 36, 57, 0.15)), color-stop(rgba(13, 36, 56, 0.3)), color-stop(rgba(13, 36, 56, 0.75)), color-stop(rgba(13, 36, 56, 0.96)), to(#0d2638));
      background-image: linear-gradient(rgba(12, 36, 56, 0), rgba(12, 36, 56, 0), rgba(12, 36, 56, 0), rgba(15, 36, 57, 0), rgba(15, 36, 57, 0.15), rgba(13, 36, 56, 0.3), rgba(13, 36, 56, 0.75), rgba(13, 36, 56, 0.96), #0d2638);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1; }
    .hero-container .hero-background .res-radial-top {
      background-image: linear-gradient(160deg, #0d2438, #0d2438, rgba(13, 36, 56, 0.9), rgba(13, 38, 56, 0.7), rgba(13, 38, 56, 0.55), rgba(12, 37, 56, 0.1), rgba(12, 37, 56, 0), rgba(12, 37, 56, 0), rgba(12, 37, 56, 0), rgba(15, 38, 57, 0));
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1; }
    .hero-container .hero-background .bottom-radial {
      background-image: linear-gradient(190deg, rgba(15, 38, 57, 0), rgba(15, 38, 57, 0), rgba(15, 38, 57, 0), rgba(12, 37, 56, 0.28), rgba(12, 37, 56, 0.7), #0c2538);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1; }
    .hero-container .hero-background .top-radial {
      background-image: -webkit-gradient(linear, left top, left bottom, from(#0e2538), color-stop(rgba(14, 37, 56, 0.8)), color-stop(rgba(14, 37, 56, 0.5)), color-stop(rgba(12, 37, 56, 0.2)), color-stop(rgba(12, 37, 56, 0)), color-stop(rgba(12, 37, 56, 0)), color-stop(rgba(12, 37, 56, 0)), color-stop(rgba(12, 37, 56, 0)), color-stop(rgba(12, 37, 56, 0)), to(rgba(15, 38, 57, 0)));
      background-image: linear-gradient(#0e2538, rgba(14, 37, 56, 0.8), rgba(14, 37, 56, 0.5), rgba(12, 37, 56, 0.2), rgba(12, 37, 56, 0), rgba(12, 37, 56, 0), rgba(12, 37, 56, 0), rgba(12, 37, 56, 0), rgba(12, 37, 56, 0), rgba(15, 38, 57, 0));
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1; }
    .hero-container .hero-background .hero-video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      background: #232a34; }
    .hero-container .hero-background .hero-video-fit {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      background: #232a34;
      object-position: 40% 0%; }
      @media (max-width: 767px) {
        .hero-container .hero-background .hero-video-fit {
          object-position: 5% 0%; } }
      @media (min-width: 768px) {
        .hero-container .hero-background .hero-video-fit {
          object-position: 15% 0%; } }
    .hero-container .hero-background .gradient-image {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1; }
      @media (max-width: 767px) {
        .hero-container .hero-background .gradient-image {
          display: none; } }
    .hero-container .hero-background .gradient-image-mobile {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1; }
      @media (min-width: 768px) {
        .hero-container .hero-background .gradient-image-mobile {
          display: none; } }
      @media (min-width: 1200px) {
        .hero-container .hero-background .gradient-image-mobile {
          display: none; } }
  .hero-container .hero-content {
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 1rem; }
    @media (min-width: 1200px) {
      .hero-container .hero-content {
        margin-bottom: 2rem;
        left: 50px; } }
    @media (max-width: 767px) {
      .hero-container .hero-content {
        left: 1rem; } }
    .hero-container .hero-content .hero-title {
      color: #fff;
      font-size: 48px;
      text-align: center; }
      @media screen and (max-width: 760px) {
        .hero-container .hero-content .hero-title {
          font-size: 40px; } }
      @media screen and (max-width: 480px) {
        .hero-container .hero-content .hero-title {
          font-size: 32px; } }
    .hero-container .hero-content .hero-tagline {
      margin-top: 24px;
      color: #fff;
      max-width: 100%;
      text-align: center;
      font-family: 'Times New Roman', Times, serif;
      letter-spacing: 2px;
      line-height: 40px; }
      .hero-container .hero-content .hero-tagline .cssanimation, .hero-container .hero-content .hero-tagline .cssanimation span {
        -webkit-animation-duration: 1s;
                animation-duration: 1s;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both; }
      .hero-container .hero-content .hero-tagline .cssanimation span {
        display: inline-block; }
      .hero-container .hero-content .hero-tagline .fadeInBottom {
        -webkit-animation-name: fadeInBottom;
                animation-name: fadeInBottom; }

@-webkit-keyframes fadeInBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  to {
    opacity: 1; } }

@keyframes fadeInBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  to {
    opacity: 1; } }
      .hero-container .hero-content .hero-tagline h1 {
        font-size: 2.5rem; }
        @media (max-width: 767px) {
          .hero-container .hero-content .hero-tagline h1 {
            font-size: 1.5rem;
            text-align: left;
            margin-bottom: 2rem; } }
      @media screen and (max-width: 480px) {
        .hero-container .hero-content .hero-tagline {
          font-size: 18px; } }
      @media screen and (max-width: 760px) {
        .hero-container .hero-content .hero-tagline {
          font-size: 24px;
          width: 100%;
          line-height: 1.2;
          text-align: left; } }
      @media (min-width: 1200px) {
        .hero-container .hero-content .hero-tagline {
          text-align: left; } }
      @media (min-width: 992px) {
        .hero-container .hero-content .hero-tagline {
          font-size: 30px; } }
      @media (min-width: 1200px) {
        .hero-container .hero-content .hero-tagline {
          font-size: 30px; } }
      @media (min-width: 1440px) {
        .hero-container .hero-content .hero-tagline {
          font-size: 35px; } }
    .hero-container .hero-content p {
      margin: 0px; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.jclub-faq-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .jclub-faq-container img {
    width: 100%;
    height: 100%; }
  .jclub-faq-container .linear-gradient-container {
    pointer-events: none;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    height: 100%;
    min-height: 850px;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(#0f2538), to(#0f2538));
    background: linear-gradient(transparent, #0f2538, #0f2538); }
  .jclub-faq-container .jclub-faq-top-container {
    display: flex;
    align-self: center;
    position: relative;
    padding-bottom: 20px; }
  .jclub-faq-container .jbc-title {
    width: 50%;
    align-self: center;
    margin-top: 30px;
    font-size: 18px;
    color: white;
    font-family: jumeirah-font2;
    position: relative;
    letter-spacing: 3px;
    font-weight: 600; }
  .jclub-faq-container .faq-title {
    font-size: 25px;
    color: #abb8bd;
    text-transform: uppercase;
    display: flex;
    padding: 20px 0px 20px 0px;
    justify-content: center;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px; }

.jclub-faq-accoridon-container {
  background-color: white;
  align-self: center;
  width: 75%;
  position: relative;
  padding-left: 70px;
  padding-right: 40px;
  padding-bottom: 20px;
  margin-bottom: 40px; }
  .jclub-faq-accoridon-container [data-reach-accordion-button] {
    font-family: jumeirah-font2;
    font-size: 16px;
    background: none;
    border: none;
    outline: none;
    font-weight: bold !important;
    display: flex; }
    .jclub-faq-accoridon-container [data-reach-accordion-button] svg {
      width: 2em;
      height: 1.5em;
      margin-left: 10px; }
  .jclub-faq-accoridon-container [data-reach-accordion-panel] {
    font-family: jumeirah-font2;
    font-size: 14px !important;
    font-weight: 500;
    background: #ccc;
    padding: 12px;
    margin-bottom: 10px;
    margin-left: 18px; }
  .jclub-faq-accoridon-container .form-group {
    float: right;
    padding-top: 20px; }
    .jclub-faq-accoridon-container .form-group span {
      padding-left: 10px;
      padding-right: 10px; }
    .jclub-faq-accoridon-container .form-group input[type="checkbox"],
    .jclub-faq-accoridon-container .form-group input[type="radio"] {
      position: absolute;
      opacity: 0; }
      .jclub-faq-accoridon-container .form-group input[type="checkbox"] + label,
      .jclub-faq-accoridon-container .form-group input[type="radio"] + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        font-size: 13px; }
      .jclub-faq-accoridon-container .form-group input[type="checkbox"] + label:before,
      .jclub-faq-accoridon-container .form-group input[type="radio"] + label:before {
        content: "";
        display: inline-block;
        vertical-align: text-top;
        width: 16px;
        height: 16px;
        padding: 4px;
        border: #f0f0f0 3px solid;
        outline: #cdc9b8 1px solid;
        background: white; }
      .jclub-faq-accoridon-container .form-group input[type="checkbox"]:checked + label:before,
      .jclub-faq-accoridon-container .form-group input[type="radio"]:checked + label:before {
        background: #8c816b; }
      .jclub-faq-accoridon-container .form-group input[type="checkbox"]:disabled + label,
      .jclub-faq-accoridon-container .form-group input[type="radio"]:disabled + label {
        opacity: 0.8;
        cursor: default; }
      .jclub-faq-accoridon-container .form-group input[type="checkbox"]:disabled + label:before,
      .jclub-faq-accoridon-container .form-group input[type="radio"]:disabled + label:before {
        box-shadow: none;
        cursor: default;
        opacity: 0.8; }
  .jclub-faq-accoridon-container .new-badge {
    position: absolute;
    left: -70px;
    top: -2px;
    font-size: 14px; }
    .jclub-faq-accoridon-container .new-badge .text {
      background: #f00;
      float: left;
      width: 50px;
      color: #fff;
      line-height: 30px;
      text-align: center;
      height: 30px; }
    .jclub-faq-accoridon-container .new-badge .arrow {
      width: 30px;
      float: left;
      height: 0px; }
    .jclub-faq-accoridon-container .new-badge .right {
      border-top: 15px solid transparent;
      border-left: 15px solid #f00;
      border-bottom: 15px solid transparent; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
/* :: screen widths */
/* :: color variables */
.not-found-title {
  font-family: TFArrow-Medium;
  font-size: 32px;
  line-height: normal;
  color: #7c6f55;
  margin-top: 20px; }
  @media (max-width: 767px) {
    .not-found-title {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.18; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0d2233;
  padding: 2.5rem 0 0 0; }
  @media (min-width: 992px) {
    .info-section {
      zoom: 0.850; } }
  @media (min-width: 1200px) {
    .info-section {
      zoom: 0.850; } }
  @media (min-width: 1440px) {
    .info-section {
      zoom: 1; } }
  .info-section .info-title {
    font-size: 24px;
    text-transform: uppercase;
    color: #abb8bd;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 4rem 4rem 1rem 4rem;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px; }
  .info-section .info-text {
    font-size: 16px;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
    padding-top: 3rem; }
    @media (min-width: 768px) {
      .info-section .info-text {
        padding-top: 1rem; } }
    @media (min-width: 1200px) {
      .info-section .info-text {
        padding-top: 1rem; } }
    .info-section .info-text .info-description {
      margin-left: auto;
      margin-right: auto;
      max-width: 740px; }
      .info-section .info-text .info-description P {
        margin-bottom: 0px; }

.newsletter-top-desktop input {
  color: #0e2538;
  border: 1px solid;
  border-radius: 9px;
  font-size: 16px;
  padding: 5px 0.75rem;
  height: auto; }

.newsletter-top-desktop input:focus {
  color: #0e2538;
  border: 1px solid;
  border-radius: 9px;
  box-shadow: none; }

.newsletter-top-desktop button {
  background-color: #abb8bd;
  color: #0e2538;
  padding: 2px 33px;
  border-radius: 9px;
  font-weight: 500;
  box-shadow: none;
  font-size: 14px; }

.newsletter-top-desktop .lastname-padding {
  padding: 0px 15px 0px 7px; }

.newsletter-top-desktop .firstname-padding {
  padding: 0px 0px 0px 15px; }

.newsletter-top-desktop .error {
  color: #dc3545;
  font-size: 12px;
  padding-left: 12px; }

.newsletter-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 10rem 4.5rem 10rem;
  color: white; }
  .newsletter-desktop .newsletter-title {
    margin: 6px 4px;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    font-family: "Times New Roman", Times, serif;
    letter-spacing: 1px; }
  .newsletter-desktop .myform {
    display: contents; }
  .newsletter-desktop .spinner {
    margin-right: 5px;
    -webkit-animation: spin infinite 5s linear;
            animation: spin infinite 5s linear;
    /*You can increase or decrease the timer (5s) to 
       increase or decrease the speed of the spinner*/ }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .newsletter-desktop .newsletter-form {
    display: flex;
    flex-direction: column; }
    .newsletter-desktop .newsletter-form .title {
      color: white;
      letter-spacing: 1px;
      font-size: 22px;
      font-family: 'Times New Roman';
      text-align: center;
      margin-bottom: 10px; }
    .newsletter-desktop .newsletter-form .newsletter-row-1 input {
      padding: 5px 5px;
      border-radius: 10px;
      border: 1px solid darkgrey;
      margin: 5px;
      font-size: 15px;
      background: transparent;
      max-width: calc(100% - 60px); }
    .newsletter-desktop .newsletter-form .newsletter-row-1 input:focus {
      outline: none; }
    .newsletter-desktop .newsletter-form .newsletter-row-1 input::-webkit-input-placeholder {
      padding-left: 5px;
      font-size: 15px;
      color: darkgrey; }
    .newsletter-desktop .newsletter-form .newsletter-row-2 input {
      padding: 5px 5px;
      border-radius: 10px;
      border: 1px solid darkgrey;
      margin: 5px;
      font-size: 15px;
      background: transparent; }
    .newsletter-desktop .newsletter-form .newsletter-row-2 input:focus {
      outline: none; }
    .newsletter-desktop .newsletter-form .newsletter-row-2 input::-webkit-input-placeholder {
      padding-left: 5px;
      font-size: 15px;
      color: darkgrey; }
    .newsletter-desktop .newsletter-form .newsletter-row-3 input {
      padding: 5px 5px;
      border-radius: 10px;
      border: 1px solid darkgrey;
      margin: 5px;
      font-size: 15px;
      background: transparent; }
    .newsletter-desktop .newsletter-form .newsletter-row-3 input[type=submit] {
      background-color: darkgrey;
      width: 100px; }
    .newsletter-desktop .newsletter-form .newsletter-row-3 input:focus {
      outline: none; }
    .newsletter-desktop .newsletter-form .newsletter-row-3 input::-webkit-input-placeholder {
      padding-left: 5px;
      font-size: 15px;
      color: darkgrey; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.newsletter-mobile {
  background-size: cover;
  min-height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0rem 0rem 0rem 0rem; }

.newslettermobileview-title {
  color: #abb8bd;
  text-align: center;
  padding-bottom: 20px;
  /* color: #0e2538; */
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  font-family: "Times New Roman", Times, serif;
  letter-spacing: 1px; }

.newsletter-top-mobile {
  padding: 0rem 0rem 0rem 0rem; }
  @media (max-width: 767px) {
    .newsletter-top-mobile {
      background-repeat: no-repeat;
      background-position: 15% center;
      zoom: 0.8; } }
  @media screen and (max-width: 767px) and (min-width: 576px) and (max-width: 768px) {
    .newsletter-top-mobile {
      zoom: 0.9; } }
  @media (max-width: 767px) {
      .newsletter-top-mobile .container {
        padding: 0px 16% 0px 9%; } }
    @media screen and (max-width: 767px) and (min-width: 576px) and (max-width: 768px) {
      .newsletter-top-mobile .container {
        padding: 0px; } }
  .newsletter-top-mobile input {
    background: transparent;
    color: #abb8bd;
    border: 1px solid;
    border-radius: 9px;
    font-size: 13px;
    box-shadow: none; }
  .newsletter-top-mobile input::-webkit-input-placeholder {
    letter-spacing: 0.8px;
    opacity: 1; }
  .newsletter-top-mobile input::-moz-placeholder {
    letter-spacing: 0.8px;
    opacity: 1; }
  .newsletter-top-mobile input::-ms-input-placeholder {
    letter-spacing: 0.8px;
    opacity: 1; }
  .newsletter-top-mobile input::placeholder {
    letter-spacing: 0.8px;
    opacity: 1; }
  .newsletter-top-mobile input:focus {
    background: transparent;
    color: #abb8bd;
    border: 2px solid;
    border-radius: 9px;
    font-size: 13px;
    box-shadow: none; }
  .newsletter-top-mobile button {
    background-color: #abb8bd;
    color: #0e2538;
    padding: 5px 55px;
    border-radius: 7px;
    font-weight: 500;
    box-shadow: none;
    font-size: 14px;
    height: calc(1.5em + 0.75rem + 2px);
    text-align: center; }
  .newsletter-top-mobile .lastname-padding {
    padding: 0px 15px 0px 7px; }
  .newsletter-top-mobile .firstname-padding {
    padding: 0px 0px 0px 15px; }
  .newsletter-top-mobile .submit-button-newsletter {
    color: #abb8bd;
    text-align: center; }
  .newsletter-top-mobile .error {
    color: #dc3545;
    font-size: 10px;
    padding-left: 12px; }
  .newsletter-top-mobile .spinner {
    margin-right: 5px;
    -webkit-animation: spin infinite 5s linear;
            animation: spin infinite 5s linear;
    /*You can increase or decrease the timer (5s) to 
          increase or decrease the speed of the spinner*/ }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.contact-container {
  background: #abb8bd6e;
  padding: 70px 25px 50px 25px;
  color: #0E2538; }
  @media (min-width: 1200px) {
    .contact-container {
      background: #0E2538;
      color: #abb8bd;
      padding: 100px 150px 30px 150px; } }
  .contact-container .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .contact-container .title h1 {
      text-transform: uppercase;
      font-size: 24px;
      font-family: 'Times New Roman', Times, serif;
      color: #0d2233d1; }
      @media (min-width: 360px) and (max-width: 376px) {
        .contact-container .title h1 {
          font-size: 20px; } }
      @media screen and (max-width: 350px) {
        .contact-container .title h1 {
          font-size: 20px; } }
      @media (min-width: 1200px) {
        .contact-container .title h1 {
          letter-spacing: 2.3px;
          color: #abb8bd; } }
    .contact-container .title p {
      font-size: 18px; }
  .contact-container .main-wrapper {
    margin: 30px 0px;
    position: relative;
    padding: 12px; }
    .contact-container .main-wrapper .location-map {
      width: 100%;
      height: 400px;
      background: black;
      margin-left: auto;
      margin-right: auto; }
      .contact-container .main-wrapper .location-map iframe {
        width: inherit;
        height: inherit;
        border: none;
        text-decoration: none; }
    .contact-container .main-wrapper .contact-list {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      color: #0E2538; }
      .contact-container .main-wrapper .contact-list .club-address ul {
        list-style: none;
        font-size: 20px; }
      .contact-container .main-wrapper .contact-list .club-contact ul {
        list-style: none;
        font-size: 20px; }
  @media (min-width: 1200px) {
    .contact-container #map:before, .contact-container #map > :first-child:before {
      position: absolute;
      width: 180px;
      height: 180px;
      border-color: #abb8bd;
      /* or whatever colour */
      border-style: solid;
      /* or whatever style */
      content: ' '; }
    .contact-container #map > :first-child:before {
      width: 45%;
      height: 180px; }
    .contact-container #map:before {
      top: 0;
      left: 0;
      border-width: 12px 0 0 12px; }
    .contact-container #map > :first-child:before {
      bottom: 0;
      right: 0;
      border-width: 0 12px 12px 0; } }
  .contact-container .desktop-sub-info {
    display: none; }
    .contact-container .desktop-sub-info p {
      margin: 0px 3px;
      color: white; }
    .contact-container .desktop-sub-info .get-direction {
      font-weight: 500;
      margin: 0px 3px; }
    @media (min-width: 1200px) {
      .contact-container .desktop-sub-info {
        display: flex;
        justify-content: center;
        font-size: 14px; } }
  .contact-container .sub-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px; }
    .contact-container .sub-info p {
      margin-bottom: 5px; }
    .contact-container .sub-info .get-direction {
      font-weight: 500;
      margin-top: 20px; }
    @media (min-width: 1200px) {
      .contact-container .sub-info {
        display: none; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
@media (min-width: 992px) {
  .location-slider {
    zoom: 0.880; } }

@media (min-width: 1200px) {
  .location-slider {
    zoom: 0.880; } }

@media (min-width: 1440px) {
  .location-slider {
    zoom: 1; } }

.location-slider .accordion {
  width: 100%;
  height: 550px;
  overflow: hidden; }

.location-slider .accordion ul {
  width: 100%;
  display: table;
  table-layout: fixed;
  margin: 0;
  padding: 0; }

.location-slider .accordion ul li {
  display: table-cell;
  vertical-align: bottom;
  position: relative;
  width: 16.666%;
  height: 550px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  background-size: cover; }

.location-slider .accordion ul li div a {
  display: block;
  height: 550px;
  position: relative;
  z-index: 3;
  padding: 15px 20px;
  box-sizing: border-box;
  color: #fff;
  text-decoration: none;
  font-family: Open Sans, sans-serif;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }

.location-slider .accordion ul li div a * {
  opacity: 0;
  margin: 0; }

.location-slider .accordion ul li div a .slider-description {
  font-family: "Times New Roman", Times, serif;
  text-overflow: clip;
  text-transform: uppercase;
  margin-bottom: 2px;
  top: 40%;
  position: relative; }
  .location-slider .accordion ul li div a .slider-description .title {
    font-size: 24px;
    text-align: center;
    letter-spacing: 2.5px; }
  .location-slider .accordion ul li div a .slider-description .discover-more {
    background: #abb8bd;
    padding: 4px 8px;
    text-align: center;
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 16px; }
  .location-slider .accordion ul li div a .slider-description .discover-more-button {
    text-align: center; }

.location-slider .accordion ul li div a p {
  top: 160px;
  font-size: 13.5px; }

.location-slider .accordion ul:hover li {
  width: 12%; }

.location-slider .accordion ul:hover li:hover {
  width: 60%; }

.location-slider .accordion ul:hover li:hover a {
  background: rgba(2, 6, 9, 0.8); }

.location-slider .accordion ul:hover li:hover a * {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0); }

@media screen and (max-width: 600px) {
  .location-slider .accordion ul li a {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    background: rgba(2, 6, 9, 0.6); }
    .location-slider .accordion ul li a .discover-more-button {
      display: none; }
  .location-slider .accordion ul:hover li:hover a {
    background: rgba(2, 6, 9, 0.6); }
  .location-slider .accordion ul:hover li:hover a * {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  .location-slider .accordion ul li div a * {
    opacity: 1;
    margin: 1; }
  .location-slider .accordion {
    height: auto; }
  .location-slider .accordion ul li,
  .location-slider .accordion ul li:hover,
  .location-slider .accordion ul:hover li,
  .location-slider .accordion ul:hover li:hover {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    -webkit-transition: none;
    transition: none; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.member {
  width: 100%;
  font-family: 'Times New Roman', Times, serif; }
  .member .member-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    border: none;
    background-color: transparent;
    background: #0E2538;
    color: #abb8bd;
    line-height: 2rem;
    letter-spacing: 1px;
    font-size: 20px;
    cursor: pointer; }
    .member .member-button .Button-Title {
      display: flex;
      justify-content: center;
      width: inherit;
      font-size: 16px; }
    .member .member-button .arrow-icon-down {
      color: #abb8bd;
      font-size: 30px; }
      @media (max-width: 767px) {
        .member .member-button .arrow-icon-down {
          font-size: 20px; } }
    .member .member-button .arrow-icon-up {
      color: #abb8bd;
      font-size: 30px;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
      @media (max-width: 767px) {
        .member .member-button .arrow-icon-up {
          font-size: 20px; } }
    .member .member-button::after {
      color: darkblue;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    @media (max-width: 767px) {
      .member .member-button {
        font-size: 15px; } }
  .member .font-weight-bold::after {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  .member .member-desc {
    margin-top: 0;
    margin-bottom: 0;
    height: 0;
    overflow: hidden;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out; }
    .member .member-desc .star-info {
      text-align: center;
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 10px; }
  .member .show-description {
    height: auto;
    margin-bottom: 15px;
    border-top: 1px solid #e7e7e9; }
    .member .show-description .odd-item {
      background: #abb8bd8c; }
    .member .show-description .item-gradient {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 10px; }
      .member .show-description .item-gradient .item-gradient-title {
        background-image: -webkit-gradient(linear, left top, left bottom, from(#abb8bd), to(white));
        background-image: linear-gradient(#abb8bd, white);
        width: -webkit-fill-available;
        text-align: center;
        font-size: 16px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px; }
      .member .show-description .item-gradient .item-gradient-list-title {
        margin-bottom: 0;
        padding-right: 20px; }
    .member .show-description .member-item {
      display: grid;
      grid-template-columns: 0.95fr 1fr;
      align-items: center;
      line-height: 2rem;
      padding: 1px 6px; }
      @media (max-width: 767px) {
        .member .show-description .member-item {
          padding-right: 0px;
          line-height: 1.5rem; } }
      @media (min-width: 768px) {
        .member .show-description .member-item {
          padding-right: 40px;
          line-height: 1.5rem;
          grid-template-columns: 1fr 1fr; } }
      @media (min-width: 1200px) {
        .member .show-description .member-item {
          padding-right: 82px;
          line-height: 2rem;
          grid-template-columns: 1fr 1fr; } }
      .member .show-description .member-item .member-item-title {
        font-size: 14px;
        letter-spacing: 0.2px;
        font-family: Arial, Helvetica, sans-serif; }
        @media (max-width: 767px) {
          .member .show-description .member-item .member-item-title {
            font-size: 12px;
            letter-spacing: 0px; } }
      .member .show-description .member-item .member-item-list {
        display: flex;
        justify-content: space-between; }
        @media (max-width: 767px) {
          .member .show-description .member-item .member-item-list {
            padding: 0px 30px; } }
        .member .show-description .member-item .member-item-list .circle-fill {
          background: darkgrey;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 2px solid; }
        .member .show-description .member-item .member-item-list .circle-empty {
          background: transparent;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 2px solid; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.membership {
  display: block;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0rem 0 6rem  0;
  width: 80%;
  font-family: 'Times New Roman', Times, serif; }
  @media (max-width: 767px) {
    .membership {
      width: 90%;
      padding: 0px; } }
  .membership .membership-container .sub-info {
    text-align: center;
    margin-top: 50px; }
    @media (max-width: 767px) {
      .membership .membership-container .sub-info {
        margin-top: 30px; } }
    @media (max-width: 767px) {
      .membership .membership-container .sub-info p {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        width: 80%; } }
  .membership .membership-container .mobile-sub-info {
    text-align: center;
    margin-top: 30px; }
    .membership .membership-container .mobile-sub-info p {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      width: 88%; }
  .membership .membership-container .title {
    display: flex;
    justify-content: center;
    padding: 50px 20px;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .membership .membership-container .title {
        padding: 25px 20px 14px 20px; } }
    @media (min-width: 1200px) {
      .membership .membership-container .title {
        padding: 0px 20px 25px 20px; } }
    .membership .membership-container .title h2 {
      font-size: 25px;
      color: #abb8bd;
      letter-spacing: 0.5px;
      text-align: center; }
  .membership .membership-container .subtitle {
    display: grid;
    grid-template-columns: 0.95fr 1fr;
    line-height: 2rem;
    padding: 20px 0px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.2px; }
    @media (max-width: 767px) {
      .membership .membership-container .subtitle {
        padding-right: 0px;
        font-size: 12px; } }
    @media (min-width: 768px) {
      .membership .membership-container .subtitle {
        padding-right: 40px; } }
    @media (min-width: 1200px) {
      .membership .membership-container .subtitle {
        padding-right: 82px; } }
    .membership .membership-container .subtitle .subtitle-list {
      display: flex;
      justify-content: space-between;
      padding-right: 50px;
      color: #0E2538; }
      @media (max-width: 767px) {
        .membership .membership-container .subtitle .subtitle-list {
          padding: 0px 30px; } }
      .membership .membership-container .subtitle .subtitle-list .city {
        height: 15px;
        width: 15px; }
        @media (max-width: 767px) {
          .membership .membership-container .subtitle .subtitle-list .city {
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg); } }
        @media (min-width: 768px) {
          .membership .membership-container .subtitle .subtitle-list .city {
            margin: 0 -31px; } }
      .membership .membership-container .subtitle .subtitle-list .fit {
        height: 15px;
        width: 15px; }
        @media (max-width: 767px) {
          .membership .membership-container .subtitle .subtitle-list .fit {
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg); } }
      .membership .membership-container .subtitle .subtitle-list .life {
        height: 15px;
        width: 15px; }
        @media (max-width: 767px) {
          .membership .membership-container .subtitle .subtitle-list .life {
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg); } }
        @media (min-width: 768px) {
          .membership .membership-container .subtitle .subtitle-list .life {
            margin: 0px -29px; } }
  .membership .accordion-item {
    background-color: #fff;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
    .membership .accordion-item .accordion-header {
      color: black; }
      .membership .accordion-item .accordion-header .accordion-button:not(.collapsed) {
        color: inherit;
        background-color: inherit;
        box-shadow: none; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.membership-main .slick-prev {
  left: -31px !important; }

.membership-main .slick-next {
  right: -31px !important; }

@media (min-width: 992px) {
  .membership-main .mobile-view {
    display: none; }
  .membership-main .desktop-view {
    display: block; } }

@media (min-width: 1440px) {
  .membership-main .mobile-view {
    display: none; }
  .membership-main .desktop-view {
    display: block; } }

@media (min-width: 1800px) {
  .membership-main .mobile-view {
    display: none; }
  .membership-main .desktop-view {
    display: block; } }

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .membership-main .membership-details .membership-stage1-title {
    height: 252px; }
  .membership-main .membership-details .overview-paragraph .border-pad .paragraph {
    font-size: 14px; }
  .membership-main .membership-details .overview-paragraph {
    position: relative;
    top: 32px; } }

@media (max-width: 767px) {
  .membership-main .mobile-view {
    display: none; }
  .membership-main .desktop-view {
    display: none; } }

.membership-deatils {
  font-family: 'Times New Roman', Times, serif;
  padding: 50px; }
  .membership-deatils h2 {
    color: black;
    font-weight: 300;
    letter-spacing: 2px; }
  .membership-deatils table, .membership-deatils th, .membership-deatils tr, .membership-deatils td {
    border: 1px solid;
    border-collapse: collapse;
    text-align: center; }
  .membership-deatils th {
    background-color: #abb8bd; }

.membership-details {
  padding: 4rem 150px; }
  @media (min-width: 768px) {
    .membership-details {
      padding: 4rem 50px; } }
  @media (min-width: 1200px) {
    .membership-details {
      padding: 6rem 150px 0rem 150px; } }
  .membership-details .membership-main-title {
    font-size: 25px;
    color: #abb8bd;
    text-transform: uppercase;
    display: flex;
    padding: 20px 0px 20px 0px;
    justify-content: center;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px; }
  .membership-details .slick-slider .slick-dots {
    bottom: 5px;
    z-index: 9; }
    .membership-details .slick-slider .slick-dots li {
      margin: 0px; }
      .membership-details .slick-slider .slick-dots li.slick-active button::before {
        color: white;
        font-size: 9px;
        opacity: 1; }
    .membership-details .slick-slider .slick-dots li button {
      background: transparent;
      border: 2px solid #fff;
      border-radius: 50%;
      height: 9px;
      line-height: 0;
      opacity: 1;
      padding: 0;
      width: 9px; }
    .membership-details .slick-slider .slick-dots li button:before {
      color: transparent;
      line-height: 11px;
      opacity: 1;
      width: 8px; }
  .membership-details .banner-img {
    position: relative;
    max-width: 90%; }
  .membership-details .membership-stage1-title {
    color: #d6b8b5;
    font-family: 'Times New Roman', Times, serif;
    background: #eef1f2;
    position: absolute;
    left: -39px;
    top: 50px;
    height: 325px; }
    @media (min-width: 768px) {
      .membership-details .membership-stage1-title {
        left: 0px;
        top: 0px; } }
    @media (min-width: 1200px) {
      .membership-details .membership-stage1-title {
        left: -39px;
        top: 50px; } }
  @media (min-width: 1200px) {
    .membership-details .findout-more {
      padding: 10px 0px 0px 0px; } }
  .membership-details .findout-more .btn {
    border-radius: 7px;
    background: #abb8bd;
    color: #0E2538;
    border: 1px solid #abb8bd;
    width: 48%;
    max-width: 200px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    padding: 6px 0px;
    box-shadow: none; }
    @media (min-width: 768px) {
      .membership-details .findout-more .btn {
        font-size: 11px;
        padding: 3px 0px;
        width: 53%;
        margin: 10px 0px 0px 0px; } }
    @media (min-width: 992px) {
      .membership-details .findout-more .btn {
        font-size: 12px;
        padding: 5px 0px;
        width: 60%;
        margin: 0;
        max-width: 180px; } }
    @media (min-width: 1200px) {
      .membership-details .findout-more .btn {
        font-size: 13px;
        width: 48%;
        max-width: 200px; } }
  .membership-details .overview-paragraph {
    position: relative;
    top: 70px;
    right: 145px;
    width: 170%;
    background: white;
    padding: 14px 14px; }
    @media (min-width: 992px) {
      .membership-details .overview-paragraph {
        width: 140%; } }
    .membership-details .overview-paragraph .border-pad {
      padding: 7px 11px;
      border: 1px solid #ABB8BD;
      padding: 25px 35px; }
      .membership-details .overview-paragraph .border-pad .paragraph {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        letter-spacing: 1px; }
        @media (min-width: 992px) {
          .membership-details .overview-paragraph .border-pad .paragraph {
            font-size: 12px; } }
        @media (min-width: 1200px) {
          .membership-details .overview-paragraph .border-pad .paragraph {
            font-size: 14px; } }
      .membership-details .overview-paragraph .border-pad .member-title {
        font-family: 'Times New Roman', Times, serif;
        text-transform: uppercase;
        letter-spacing: 3px;
        text-align: center; }
        @media (min-width: 992px) {
          .membership-details .overview-paragraph .border-pad .member-title {
            font-size: 1.3em; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.membership-main .slick-prev {
  left: -31px !important; }

.membership-main .slick-next {
  right: -31px !important; }

@media (min-width: 992px) {
  .membership-main .mobile-view {
    display: none; }
  .membership-main .desktop-view {
    display: block; } }

@media (min-width: 1440px) {
  .membership-main .mobile-view {
    display: none; }
  .membership-main .desktop-view {
    display: block; } }

@media (min-width: 1800px) {
  .membership-main .mobile-view {
    display: none; }
  .membership-main .desktop-view {
    display: block; } }

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .membership-main .membership-overview .membership-stage1-title {
    height: 252px; }
  .membership-main .membership-overview .overview-paragraph .border-pad .paragraph {
    font-size: 14px; }
  .membership-main .membership-overview .overview-paragraph {
    position: relative;
    top: 32px; } }

@media (max-width: 767px) {
  .membership-main .mobile-view {
    display: none; }
  .membership-main .desktop-view {
    display: none; } }

.membership-overview {
  padding: 4rem 150px; }
  @media (min-width: 768px) {
    .membership-overview {
      padding: 4rem 50px; } }
  @media (min-width: 1200px) {
    .membership-overview {
      padding: 6rem 150px 0rem 150px; } }
  .membership-overview .slick-slider .slick-dots {
    bottom: 5px;
    z-index: 9; }
    .membership-overview .slick-slider .slick-dots li {
      margin: 0px; }
      .membership-overview .slick-slider .slick-dots li.slick-active button::before {
        color: white;
        font-size: 9px;
        opacity: 1; }
    .membership-overview .slick-slider .slick-dots li button {
      background: transparent;
      border: 2px solid #fff;
      border-radius: 50%;
      height: 9px;
      line-height: 0;
      opacity: 1;
      padding: 0;
      width: 9px; }
    .membership-overview .slick-slider .slick-dots li button:before {
      color: transparent;
      line-height: 11px;
      opacity: 1;
      width: 8px; }
  .membership-overview .banner-img {
    position: relative;
    max-width: 90%; }
  .membership-overview .membership-stage1-title {
    color: #d6b8b5;
    font-family: 'Times New Roman', Times, serif;
    background: #eef1f2;
    position: absolute;
    left: -39px;
    top: 50px;
    height: 325px; }
    @media (min-width: 768px) {
      .membership-overview .membership-stage1-title {
        left: 0px;
        top: 0px; } }
    @media (min-width: 1200px) {
      .membership-overview .membership-stage1-title {
        left: -39px;
        top: 50px; } }
    .membership-overview .membership-stage1-title .membership-main-title {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      position: relative;
      top: 125px;
      left: -168px;
      letter-spacing: 4px;
      z-index: 9; }
      @media (min-width: 768px) {
        .membership-overview .membership-stage1-title .membership-main-title {
          top: 125px;
          left: -146px;
          font-size: 35px; } }
      @media (min-width: 1200px) {
        .membership-overview .membership-stage1-title .membership-main-title {
          top: 120px;
          left: -168px;
          font-size: 50px; } }
  @media (min-width: 1200px) {
    .membership-overview .findout-more {
      padding: 10px 0px 0px 0px; } }
  .membership-overview .findout-more .btn {
    border-radius: 7px;
    background: #abb8bd;
    color: #0E2538;
    border: 1px solid #abb8bd;
    width: 48%;
    max-width: 200px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    padding: 6px 0px;
    box-shadow: none; }
    @media (min-width: 768px) {
      .membership-overview .findout-more .btn {
        font-size: 11px;
        padding: 3px 0px;
        width: 53%;
        margin: 10px 0px 0px 0px; } }
    @media (min-width: 992px) {
      .membership-overview .findout-more .btn {
        font-size: 12px;
        padding: 5px 0px;
        width: 60%;
        margin: 0;
        max-width: 180px; } }
    @media (min-width: 1200px) {
      .membership-overview .findout-more .btn {
        font-size: 13px;
        width: 48%;
        max-width: 200px; } }
  .membership-overview .overview-paragraph {
    position: relative;
    top: 70px;
    right: 145px;
    width: 170%;
    background: white;
    padding: 14px 14px; }
    @media (min-width: 992px) {
      .membership-overview .overview-paragraph {
        width: 140%; } }
    .membership-overview .overview-paragraph .border-pad {
      padding: 7px 11px;
      border: 1px solid #ABB8BD;
      padding: 25px 35px; }
      .membership-overview .overview-paragraph .border-pad .paragraph {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        letter-spacing: 1px; }
        @media (min-width: 992px) {
          .membership-overview .overview-paragraph .border-pad .paragraph {
            font-size: 12px; } }
        @media (min-width: 1200px) {
          .membership-overview .overview-paragraph .border-pad .paragraph {
            font-size: 14px; } }
      .membership-overview .overview-paragraph .border-pad .member-title {
        font-family: 'Times New Roman', Times, serif;
        text-transform: uppercase;
        letter-spacing: 3px; }
        @media (min-width: 992px) {
          .membership-overview .overview-paragraph .border-pad .member-title {
            font-size: 1.3em; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
@media (max-width: 767px) {
  .no-posts {
    padding: 28px; }
  .newslist {
    display: none; }
  .news-home {
    display: none; }
  .news-mobile {
    display: block;
    padding: 50px 0px 3px 0px; }
    .news-mobile .show-on {
      display: block; }
    .news-mobile .show-off {
      display: none; }
    .news-mobile .news-updates {
      text-align: center; }
      .news-mobile .news-updates .title {
        font-size: 18px;
        font-family: 'Times New Roman', Times, serif;
        letter-spacing: 2px;
        color: #0E2538;
        text-transform: uppercase; }
      .news-mobile .news-updates .filter-label {
        padding: 10px 0px;
        color: #0E2538; }
      .news-mobile .news-updates .select-date {
        color: #abb8bd;
        width: 65%;
        margin: auto;
        border-radius: 8px;
        height: calc(1.4em + 0.75rem + 2px);
        box-shadow: none; }
    .news-mobile .news-column-mobile {
      padding: 60px 14px 8px 14px; }
    .news-mobile .news-column-left {
      padding: 4px; }
    .news-mobile .news-column-right {
      padding: 4px; }
    .news-mobile .news-bg-image {
      width: 100%;
      height: 120px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover; }
    .news-mobile .card {
      padding: 0px 14px;
      border: none; }
      .news-mobile .card .card-body {
        padding: 13px 0px;
        border: none;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif; }
        .news-mobile .card .card-body .card-title {
          font-weight: 600;
          margin: 0px;
          padding: 6px 0px 4px 0px;
          height: 58px; } }
        @media (max-width: 767px) and (max-width: 767px) {
          .news-mobile .card .card-body .card-title {
            font-size: 10px; } }
        @media (max-width: 767px) and (max-width: 767px) {
          .news-mobile .card .card-body .card-title {
            height: 24px; } }

@media (max-width: 767px) {
        .news-mobile .card .card-body .card-description {
          font-size: 10px;
          margin: 0px;
          padding: 4px 0px;
          height: 50px; }
        .news-mobile .card .card-body .month-show {
          font-weight: 600;
          margin: 0px;
          padding: 6px 0px 4px 0px;
          height: 58px; } }
        @media (max-width: 767px) and (max-width: 767px) {
          .news-mobile .card .card-body .month-show {
            font-size: 10px; } }
        @media (max-width: 767px) and (max-width: 767px) {
          .news-mobile .card .card-body .month-show {
            height: 24px; } }

@media (max-width: 767px) {
        .news-mobile .card .card-body .card-readmore {
          font-size: 11px;
          font-weight: 600;
          color: #ABB8BD;
          margin: 0px;
          padding: 4px 0px; }
    .news-mobile .show-more {
      padding: 10px 0px 0px 0px;
      font-weight: 600;
      font-size: 14px;
      font-family: Arial, Helvetica, sans-serif; } }

@media (min-width: 768px) {
  .news-mobile {
    display: none; }
  .newslist {
    display: block; }
  .news-home {
    display: block; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.newslist {
  padding: 50px 0px 0px 0px; }
  .newslist .container-fluid {
    width: 90%;
    padding: 50px 0px; }
    .newslist .container-fluid .news-left {
      padding: 0px 40px 0px 0px; }
      @media (min-width: 768px) {
        .newslist .container-fluid .news-left {
          padding: 0px 30px 0px 0px; } }
      @media (min-width: 992px) {
        .newslist .container-fluid .news-left {
          padding: 0px 40px 0px 0px; } }
      .newslist .container-fluid .news-left .show-on {
        display: block; }
      .newslist .container-fluid .news-left .show-off {
        display: none; }
      .newslist .container-fluid .news-left .no-posts {
        margin: 0 auto; }
    @media (min-width: 768px) {
      .newslist .container-fluid .news-right {
        padding: 0px 25px 0px 0px; } }
    .newslist .container-fluid .show-more {
      font-weight: 600;
      font-size: 14px;
      font-family: Arial, Helvetica, sans-serif;
      cursor: pointer; }
    .newslist .container-fluid .news-column {
      margin: 0px 0px 41px 0px; }
    .newslist .container-fluid .news-bg-image {
      width: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover; }
      @media (min-width: 768px) {
        .newslist .container-fluid .news-bg-image {
          height: 150px; } }
      @media (min-width: 992px) {
        .newslist .container-fluid .news-bg-image {
          height: 170px; } }
      @media (min-width: 1440px) {
        .newslist .container-fluid .news-bg-image {
          height: 200px; } }
    .newslist .container-fluid .card {
      padding: 0px 14px;
      border: none; }
      .newslist .container-fluid .card .card-body {
        padding: 13px 0px;
        border: none;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif; }
        .newslist .container-fluid .card .card-body .card-title {
          font-size: 14px;
          font-weight: 600;
          margin: 0px;
          padding: 6px 0px 4px 0px; }
          @media (min-width: 768px) {
            .newslist .container-fluid .card .card-body .card-title {
              font-size: 11px; } }
          @media (min-width: 992px) {
            .newslist .container-fluid .card .card-body .card-title {
              font-size: 11x; } }
          @media (min-width: 1200px) {
            .newslist .container-fluid .card .card-body .card-title {
              font-size: 12px; } }
          @media (min-width: 1440px) {
            .newslist .container-fluid .card .card-body .card-title {
              font-size: 14px; } }
        .newslist .container-fluid .card .card-body .card-description {
          font-size: 14px;
          margin: 0px;
          padding: 4px 0px 0px 0px;
          text-transform: capitalize; }
          @media (min-width: 768px) {
            .newslist .container-fluid .card .card-body .card-description {
              font-size: 11px; } }
          @media (min-width: 992px) {
            .newslist .container-fluid .card .card-body .card-description {
              font-size: 11x; } }
          @media (min-width: 1200px) {
            .newslist .container-fluid .card .card-body .card-description {
              font-size: 12px; } }
          @media (min-width: 1440px) {
            .newslist .container-fluid .card .card-body .card-description {
              font-size: 14px; } }
        .newslist .container-fluid .card .card-body .month-show {
          font-size: 13px;
          color: #0E2538;
          font-weight: 600;
          margin: 0px;
          padding: 0px 0px;
          text-transform: capitalize; }
          @media (min-width: 768px) {
            .newslist .container-fluid .card .card-body .month-show {
              font-size: 11px; } }
          @media (min-width: 992px) {
            .newslist .container-fluid .card .card-body .month-show {
              font-size: 11x; } }
          @media (min-width: 1200px) {
            .newslist .container-fluid .card .card-body .month-show {
              font-size: 12px; } }
          @media (min-width: 1440px) {
            .newslist .container-fluid .card .card-body .month-show {
              font-size: 13px; } }
        .newslist .container-fluid .card .card-body .card-readmore {
          font-size: 14px;
          font-weight: 600;
          color: #ABB8BD;
          margin: 0px;
          padding: 4px 0px; }
          @media (min-width: 768px) {
            .newslist .container-fluid .card .card-body .card-readmore {
              font-size: 11px; } }
          @media (min-width: 992px) {
            .newslist .container-fluid .card .card-body .card-readmore {
              font-size: 12px; } }
          @media (min-width: 1200px) {
            .newslist .container-fluid .card .card-body .card-readmore {
              font-size: 12px; } }
          @media (min-width: 1440px) {
            .newslist .container-fluid .card .card-body .card-readmore {
              font-size: 14px; } }
    .newslist .container-fluid .search-news .inside-search .search-close {
      position: absolute;
      top: 7px;
      right: 25px;
      cursor: pointer; }
    .newslist .container-fluid .search-news .inside-search .search-close-on {
      display: block; }
    .newslist .container-fluid .search-news .inside-search .search-close-off {
      display: none; }
    .newslist .container-fluid .search-news .title {
      color: #abb8bd;
      font-family: 'Times New Roman', Times, serif;
      font-size: 22px;
      letter-spacing: 1px;
      margin: 0; }
    .newslist .container-fluid .search-news .search-holder {
      color: #abb8bd;
      box-shadow: none;
      border: 1px solid #abb8bd;
      border-radius: 10px;
      height: calc(1.1em + 0.75rem + 2px);
      margin: 5px 0px; }
    .newslist .container-fluid .search-news .search-holder::-webkit-input-placeholder {
      color: #abb8bd;
      font-size: 14px;
      letter-spacing: 0.5px; }
    .newslist .container-fluid .search-news .search-holder::-moz-placeholder {
      color: #abb8bd;
      font-size: 14px;
      letter-spacing: 0.5px; }
    .newslist .container-fluid .search-news .search-holder::-ms-input-placeholder {
      color: #abb8bd;
      font-size: 14px;
      letter-spacing: 0.5px; }
    .newslist .container-fluid .search-news .search-holder::placeholder {
      color: #abb8bd;
      font-size: 14px;
      letter-spacing: 0.5px; }
    .newslist .container-fluid .search-news .go-search {
      background: #abb8bd;
      border: none;
      padding: 4px 18px;
      margin: 4px -20px;
      border-radius: 9px;
      box-shadow: none;
      color: #0E2538; }
    .newslist .container-fluid .categories .title {
      color: #abb8bd;
      font-family: 'Times New Roman', Times, serif;
      font-size: 22px;
      letter-spacing: 1px;
      margin: 0;
      margin-top: 15px;
      text-transform: uppercase; }
    .newslist .container-fluid .categories ul {
      margin: 0;
      padding: 0px 4px; }
      .newslist .container-fluid .categories ul li {
        font-size: 16px;
        color: #abb8bd;
        list-style: none;
        padding: 1px 0px;
        cursor: pointer; }
      .newslist .container-fluid .categories ul .selected {
        color: #0E2538;
        font-weight: 600; }
    .newslist .container-fluid .older-post {
      padding: 2em 0em; }
      .newslist .container-fluid .older-post .title {
        color: #abb8bd;
        font-family: 'Times New Roman', Times, serif;
        font-size: 22px;
        letter-spacing: 1px;
        margin: 0;
        text-transform: uppercase; }
      .newslist .container-fluid .older-post .show-months ul {
        margin: 0;
        padding: 15px 4px; }
        .newslist .container-fluid .older-post .show-months ul li {
          font-size: 16px;
          color: #abb8bd;
          list-style: none;
          padding: 1px 0px;
          cursor: pointer; }
        .newslist .container-fluid .older-post .show-months ul .selected {
          color: #0E2538;
          font-weight: 600;
          cursor: pointer; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.news-info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0d2233;
  padding: 2.5rem 0 2.5rem 0; }
  @media (min-width: 992px) {
    .news-info-section {
      zoom: 0.850; } }
  @media (min-width: 1200px) {
    .news-info-section {
      zoom: 0.850; } }
  @media (min-width: 1440px) {
    .news-info-section {
      zoom: 1; } }

.news-info-title {
  font-size: 24px;
  text-transform: uppercase;
  color: #abb8bd;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 4rem 4rem 1rem 4rem;
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 2px; }

.news-home {
  background: #abb8bd6e;
  padding: 90px 0px; }
  .news-home .container-fluid {
    width: 90%; }
    .news-home .container-fluid .left-banner {
      padding: 0px 8px 0px 0px; }
      .news-home .container-fluid .left-banner .news-banner {
        width: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        -webkit-transition: all 1000ms ease;
        transition: all 1000ms ease;
        background-size: cover; }
        @media (min-width: 768px) {
          .news-home .container-fluid .left-banner .news-banner {
            height: 340px; } }
        @media (min-width: 992px) {
          .news-home .container-fluid .left-banner .news-banner {
            height: 500px; } }
        @media (min-width: 1200px) {
          .news-home .container-fluid .left-banner .news-banner {
            height: 570px; } }
        @media (min-width: 1440px) {
          .news-home .container-fluid .left-banner .news-banner {
            height: 625px; } }
      .news-home .container-fluid .left-banner .description {
        position: relative;
        top: 81%; }
        @media (min-width: 768px) {
          .news-home .container-fluid .left-banner .description {
            top: 75%; } }
        @media (min-width: 992px) {
          .news-home .container-fluid .left-banner .description {
            top: 80%; } }
        @media (min-width: 1440px) {
          .news-home .container-fluid .left-banner .description {
            top: 81%; } }
        .news-home .container-fluid .left-banner .description .title {
          text-align: center;
          color: white;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-family: 'Times New Roman', Times, serif; }
          @media (min-width: 768px) {
            .news-home .container-fluid .left-banner .description .title {
              font-size: 14px; } }
          @media (min-width: 992px) {
            .news-home .container-fluid .left-banner .description .title {
              font-size: 17px; } }
          @media (min-width: 1200px) {
            .news-home .container-fluid .left-banner .description .title {
              font-size: 18px; } }
          @media (min-width: 1440px) {
            .news-home .container-fluid .left-banner .description .title {
              font-size: 21px; } }
        .news-home .container-fluid .left-banner .description .date {
          font-size: 12px;
          color: white;
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 600; }
    .news-home .container-fluid .right-banner {
      padding: 0px 0px 0px 5px; }
      .news-home .container-fluid .right-banner .news-banner {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-transition: all 1000ms ease;
        transition: all 1000ms ease;
        background-size: cover; }
        @media (min-width: 768px) {
          .news-home .container-fluid .right-banner .news-banner {
            height: 165px; } }
        @media (min-width: 992px) {
          .news-home .container-fluid .right-banner .news-banner {
            height: 244px; } }
        @media (min-width: 992px) {
          .news-home .container-fluid .right-banner .news-banner {
            height: 244px; } }
        @media (min-width: 1200px) {
          .news-home .container-fluid .right-banner .news-banner {
            height: 280px; } }
        @media (min-width: 1440px) {
          .news-home .container-fluid .right-banner .news-banner {
            height: 307px; } }
      .news-home .container-fluid .right-banner .description {
        position: relative; }
        @media (min-width: 768px) {
          .news-home .container-fluid .right-banner .description {
            top: 50%; } }
        @media (min-width: 992px) {
          .news-home .container-fluid .right-banner .description {
            top: 64%; } }
        @media (min-width: 1440px) {
          .news-home .container-fluid .right-banner .description {
            top: 64%; } }
        .news-home .container-fluid .right-banner .description .title {
          text-align: center;
          color: white;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-family: 'Times New Roman', Times, serif; }
          @media (min-width: 768px) {
            .news-home .container-fluid .right-banner .description .title {
              font-size: 14px; } }
          @media (min-width: 992px) {
            .news-home .container-fluid .right-banner .description .title {
              font-size: 17px; } }
          @media (min-width: 1200px) {
            .news-home .container-fluid .right-banner .description .title {
              font-size: 18px; } }
          @media (min-width: 1440px) {
            .news-home .container-fluid .right-banner .description .title {
              font-size: 21px; } }
        .news-home .container-fluid .right-banner .description .date {
          font-size: 12px;
          color: white;
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 600; }
      .news-home .container-fluid .right-banner .second-image-padding {
        padding: 10px 15px; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.news-info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0d2233;
  padding: 2.5rem 0 2.5rem 0; }
  @media (min-width: 992px) {
    .news-info-section {
      zoom: 0.850; } }
  @media (min-width: 1200px) {
    .news-info-section {
      zoom: 0.850; } }
  @media (min-width: 1440px) {
    .news-info-section {
      zoom: 1; } }

.news-info-title {
  font-size: 24px;
  text-transform: uppercase;
  color: #abb8bd;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 4rem 4rem 1rem 4rem;
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 2px; }

.news-home {
  background: #abb8bd6e;
  padding: 90px 0px; }
  .news-home .container-fluid {
    width: 90%; }
    .news-home .container-fluid .left-banner {
      padding: 0px 8px 0px 0px; }
      .news-home .container-fluid .left-banner .news-banner {
        width: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        -webkit-transition: all 1000ms ease;
        transition: all 1000ms ease;
        background-size: cover; }
        @media (min-width: 768px) {
          .news-home .container-fluid .left-banner .news-banner {
            height: 340px; } }
        @media (min-width: 992px) {
          .news-home .container-fluid .left-banner .news-banner {
            height: 500px; } }
        @media (min-width: 1200px) {
          .news-home .container-fluid .left-banner .news-banner {
            height: 570px; } }
        @media (min-width: 1440px) {
          .news-home .container-fluid .left-banner .news-banner {
            height: 625px; } }
      .news-home .container-fluid .left-banner .description {
        position: relative;
        top: 81%; }
        @media (min-width: 768px) {
          .news-home .container-fluid .left-banner .description {
            top: 75%; } }
        @media (min-width: 992px) {
          .news-home .container-fluid .left-banner .description {
            top: 80%; } }
        @media (min-width: 1440px) {
          .news-home .container-fluid .left-banner .description {
            top: 81%; } }
        .news-home .container-fluid .left-banner .description .title {
          text-align: center;
          color: white;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-family: 'Times New Roman', Times, serif; }
          @media (min-width: 768px) {
            .news-home .container-fluid .left-banner .description .title {
              font-size: 14px; } }
          @media (min-width: 992px) {
            .news-home .container-fluid .left-banner .description .title {
              font-size: 17px; } }
          @media (min-width: 1200px) {
            .news-home .container-fluid .left-banner .description .title {
              font-size: 18px; } }
          @media (min-width: 1440px) {
            .news-home .container-fluid .left-banner .description .title {
              font-size: 21px; } }
        .news-home .container-fluid .left-banner .description .date {
          font-size: 12px;
          color: white;
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 600; }
    .news-home .container-fluid .right-banner {
      padding: 0px 0px 0px 5px; }
      .news-home .container-fluid .right-banner .news-banner {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-transition: all 1000ms ease;
        transition: all 1000ms ease;
        background-size: cover; }
        @media (min-width: 768px) {
          .news-home .container-fluid .right-banner .news-banner {
            height: 165px; } }
        @media (min-width: 992px) {
          .news-home .container-fluid .right-banner .news-banner {
            height: 244px; } }
        @media (min-width: 992px) {
          .news-home .container-fluid .right-banner .news-banner {
            height: 244px; } }
        @media (min-width: 1200px) {
          .news-home .container-fluid .right-banner .news-banner {
            height: 280px; } }
        @media (min-width: 1440px) {
          .news-home .container-fluid .right-banner .news-banner {
            height: 307px; } }
      .news-home .container-fluid .right-banner .description {
        position: relative; }
        @media (min-width: 768px) {
          .news-home .container-fluid .right-banner .description {
            top: 50%; } }
        @media (min-width: 992px) {
          .news-home .container-fluid .right-banner .description {
            top: 64%; } }
        @media (min-width: 1440px) {
          .news-home .container-fluid .right-banner .description {
            top: 64%; } }
        .news-home .container-fluid .right-banner .description .title {
          text-align: center;
          color: white;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-family: 'Times New Roman', Times, serif; }
          @media (min-width: 768px) {
            .news-home .container-fluid .right-banner .description .title {
              font-size: 14px; } }
          @media (min-width: 992px) {
            .news-home .container-fluid .right-banner .description .title {
              font-size: 17px; } }
          @media (min-width: 1200px) {
            .news-home .container-fluid .right-banner .description .title {
              font-size: 18px; } }
          @media (min-width: 1440px) {
            .news-home .container-fluid .right-banner .description .title {
              font-size: 21px; } }
        .news-home .container-fluid .right-banner .description .date {
          font-size: 12px;
          color: white;
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 600; }
      .news-home .container-fluid .right-banner .second-image-padding {
        padding: 10px 15px; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.offer-detail-main-container .left-background-image {
  height: 93vh;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  background-size: cover; }
  @media (min-width: 768px) {
    .offer-detail-main-container .left-background-image {
      height: 64vh; } }
  @media (min-width: 992px) {
    .offer-detail-main-container .left-background-image {
      height: 90vh; } }
  @media (min-width: 1200px) {
    .offer-detail-main-container .left-background-image {
      height: 90vh; } }
  @media (min-width: 1440px) {
    .offer-detail-main-container .left-background-image {
      height: 93vh; } }
  @media (max-width: 767px) {
    .offer-detail-main-container .left-background-image {
      height: 35vh; } }

.offer-detail-main-container .right-background-event {
  padding: 5em 4em 2em 5em;
  background: #abb8bd; }
  @media (min-width: 768px) {
    .offer-detail-main-container .right-background-event {
      padding: 1em 2em 1em 2em; } }
  @media (min-width: 1200px) {
    .offer-detail-main-container .right-background-event {
      padding: 2em 2em 2em 3em; } }
  @media (max-width: 767px) {
    .offer-detail-main-container .right-background-event {
      padding: 36px 36px;
      background: none; } }
  .offer-detail-main-container .right-background-event .event-title {
    font-family: 'Times New Roman', Times, serif;
    font-size: 26px;
    letter-spacing: 2px;
    color: #0E2538; }
    @media (max-width: 767px) {
      .offer-detail-main-container .right-background-event .event-title {
        text-align: center; } }
  .offer-detail-main-container .right-background-event .show-mobile-location {
    display: none; }
    @media (max-width: 767px) {
      .offer-detail-main-container .right-background-event .show-mobile-location {
        display: block;
        text-align: center;
        font-family: Arial;
        font-weight: 600;
        letter-spacing: 1px;
        padding: 9px 0px; } }
  .offer-detail-main-container .right-background-event .event-location-time {
    padding: 15px 0px 18px 0px; }
    @media (max-width: 767px) {
      .offer-detail-main-container .right-background-event .event-location-time {
        padding: 0px; } }
    .offer-detail-main-container .right-background-event .event-location-time p {
      color: #0E2538;
      font-size: 14px;
      font-weight: 600;
      font-family: Arial, Helvetica, sans-serif;
      padding: 0px;
      margin: 0;
      letter-spacing: 0.6px; }
      @media (max-width: 767px) {
        .offer-detail-main-container .right-background-event .event-location-time p {
          display: none; } }
  @media (min-width: 768px) {
    .offer-detail-main-container .right-background-event .event-description {
      max-height: 46vh;
      overflow-y: auto; } }
  @media (min-width: 992px) {
    .offer-detail-main-container .right-background-event .event-description {
      max-height: 55vh;
      overflow-y: auto; } }
  @media (min-width: 1200px) {
    .offer-detail-main-container .right-background-event .event-description {
      max-height: 58vh;
      overflow-y: auto; } }
  @media (min-width: 1440px) {
    .offer-detail-main-container .right-background-event .event-description {
      max-height: 63vh;
      overflow-y: auto; } }
  .offer-detail-main-container .right-background-event .event-description::-webkit-scrollbar {
    width: 5px; }
  .offer-detail-main-container .right-background-event .event-description::-webkit-scrollbar-track {
    background: #f1f1f1;
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px; }
  .offer-detail-main-container .right-background-event .event-description::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px; }
  .offer-detail-main-container .right-background-event .event-description .readmore {
    background: #0E2538;
    color: #abb8bd;
    padding: 5px 19px;
    border-radius: 6px;
    font-size: 14px;
    box-shadow: none; }
  .offer-detail-main-container .right-background-event .event-description P {
    color: #0E2538;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 0.5px; }
    @media (max-width: 767px) {
      .offer-detail-main-container .right-background-event .event-description P {
        padding: 0px; } }
    @media (min-width: 768px) {
      .offer-detail-main-container .right-background-event .event-description P {
        padding: 0px; } }
  .offer-detail-main-container .right-background-event .show-mobile-detail {
    display: none; }
    @media (max-width: 767px) {
      .offer-detail-main-container .right-background-event .show-mobile-detail {
        display: block;
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-family: Arial;
        font-weight: 600;
        text-align: center; }
        .offer-detail-main-container .right-background-event .show-mobile-detail p {
          padding: 2px 0px;
          margin: 0;
          color: #0E2538; } }
  .offer-detail-main-container .right-background-event .booknow-button {
    padding: 20px 0px; }
    @media (max-width: 767px) {
      .offer-detail-main-container .right-background-event .booknow-button {
        text-align: center; } }
    .offer-detail-main-container .right-background-event .booknow-button .btn {
      background: #10243c;
      color: #abb8bd;
      border-radius: 0px;
      color: #abb8bd;
      font-weight: 600;
      box-shadow: none;
      padding: 7px 27px; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.cssanimation, .cssanimation span {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.cssanimation span {
  display: inline-block; }

.fadeInBottom {
  -webkit-animation-name: fadeInBottom;
          animation-name: fadeInBottom; }

@-webkit-keyframes fadeInBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  to {
    opacity: 1; } }

@keyframes fadeInBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  to {
    opacity: 1; } }

.in-viewport02.cg-whatwedo {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px); }

.card-list-main {
  -webkit-transform: translateX(-20px);
          transform: translateX(-20px);
  opacity: 0;
  -webkit-transition: -webkit-transform 500ms ease-in;
  transition: -webkit-transform 500ms ease-in;
  transition: transform 500ms ease-in;
  transition: transform 500ms ease-in, -webkit-transform 500ms ease-in; }

.card-parent .card {
  padding: 15px;
  background-color: whitesmoke;
  box-shadow: 0 1px 1px #f7f1f0;
  border-radius: 0px; }
  .card-parent .card .inner-card {
    border: 2px solid darkgray; }
    .card-parent .card .inner-card .image-wrapper {
      width: 100%;
      height: 250px;
      margin-bottom: 10px;
      overflow: hidden; }
      @media (min-width: 1440px) {
        .card-parent .card .inner-card .image-wrapper {
          height: 350px; } }
    .card-parent .card .inner-card .image-wrapper img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease; }
    .card-parent .card .inner-card .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 180px;
      margin-bottom: 20px; }
      .card-parent .card .inner-card .content h1 {
        font-size: 16px;
        font-weight: 900;
        margin-bottom: 10px;
        color: #444; }
      .card-parent .card .inner-card .content p {
        font-size: 14px;
        line-height: 1.5;
        color: #555; }
        @media (min-width: 768px) {
          .card-parent .card .inner-card .content p {
            font-size: 13px; } }
        @media (min-width: 992px) {
          .card-parent .card .inner-card .content p {
            font-size: 11px; } }
        @media (min-width: 1200px) {
          .card-parent .card .inner-card .content p {
            font-size: 14px; } }
        @media (min-width: 1800px) {
          .card-parent .card .inner-card .content p {
            font-size: 16px; } }
      .card-parent .card .inner-card .content .content-title {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 10px 0px 0px;
        font-size: 24px;
        text-transform: uppercase;
        color: #abb8bd;
        font-family: 'Times New Roman', Times, serif;
        letter-spacing: 2px;
        text-align: center;
        height: 100px;
        padding: 50px; }
        @media (min-width: 768px) {
          .card-parent .card .inner-card .content .content-title {
            font-size: 20px; } }
        @media (min-width: 992px) {
          .card-parent .card .inner-card .content .content-title {
            font-size: 20px; } }
        @media (min-width: 1200px) {
          .card-parent .card .inner-card .content .content-title {
            font-size: 24px; } }
      .card-parent .card .inner-card .content .content-description {
        display: flex;
        justify-content: center;
        text-align: center;
        align-content: center;
        padding: 10px 12px;
        font-weight: 600; }
        @media (min-width: 768px) {
          .card-parent .card .inner-card .content .content-description {
            font-size: 13px;
            padding: 10px 10px; } }
        @media (min-width: 992px) {
          .card-parent .card .inner-card .content .content-description {
            font-size: 12px;
            padding: 10px 10px; } }
        @media (min-width: 1200px) {
          .card-parent .card .inner-card .content .content-description {
            font-size: 14px;
            padding: 10px 12px; } }
        .card-parent .card .inner-card .content .content-description p {
          margin-bottom: 0px; }
    .card-parent .card .inner-card .read-more-button {
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
      height: 30px; }
      .card-parent .card .inner-card .read-more-button button {
        background: inherit;
        border: none;
        outline: none;
        font-weight: 700;
        color: #abb8bd; }

.card-parent .inner-card:hover {
  border: 2px solid whitesmoke; }

.card-parent .inner-card::before, .card-parent .inner-card::after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  -webkit-transition: all 0.8s linear;
  transition: all 0.8s linear;
  background: #abb8bd; }

.card-parent .inner-card .content::before, .card-parent .inner-card .content::after {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  -webkit-transition: all 0.8s linear;
  transition: all 0.8s linear;
  background: #abb8bd; }

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .card-parent .inner-card:hover::before, .card-parent .inner-card:hover::after {
    width: calc(97% - 21px); } }

@media screen and (min-width: 1250px) and (max-width: 1350px) {
  .card-parent .inner-card:hover::before, .card-parent .inner-card:hover::after {
    width: calc(97% - 21px); } }

@media screen and (min-width: 1350px) and (max-width: 1600px) {
  .card-parent .inner-card:hover::before, .card-parent .inner-card:hover::after {
    width: calc(97% - 17px); } }

@media screen and (min-width: 1600px) and (max-width: 2200px) {
  .card-parent .inner-card:hover::before, .card-parent .inner-card:hover::after {
    width: calc(97% - 21px); } }

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .card-parent .inner-card:hover .content::before, .card-parent .inner-card:hover .content::after {
    height: calc(97% - 14px); } }

@media screen and (min-width: 1280px) {
  .card-parent .inner-card:hover .content::before, .card-parent .inner-card:hover .content::after {
    height: calc(97% - 14px); } }

@media screen and (min-width: 1550px) {
  .card-parent .inner-card:hover .content::before, .card-parent .inner-card:hover .content::after {
    height: calc(98% - 19px); } }

@media screen and (min-width: 2000px) {
  .card-parent .inner-card:hover .content::before, .card-parent .inner-card:hover .content::after {
    height: calc(98% - 19px); } }

.card-parent .inner-card:after {
  left: 15px;
  bottom: 15px;
  -webkit-transition-delay: 0s;
          transition-delay: 0s; }

.card-parent .inner-card .content::after {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  right: 14.5px;
  bottom: 15px; }

.card-parent .inner-card::before {
  right: 15px;
  top: 14.5px;
  -webkit-transition-delay: 0s;
          transition-delay: 0s; }

.card-parent .inner-card .content::before {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  left: 14.5px;
  top: 15px; }

.card-parent .inner-card:hover::after {
  -webkit-transition-delay: 0s;
          transition-delay: 0s; }

.card-parent .inner-card:hover .content::after {
  -webkit-transition-delay: 0s;
          transition-delay: 0s; }

.card-parent .inner-card:hover::before {
  -webkit-transition-delay: 0s;
          transition-delay: 0s; }

.card-parent .inner-card:hover .content::before {
  -webkit-transition-delay: 0s;
          transition-delay: 0s; }

.card-parent .card:hover .card-image {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.top-level .modal-dialog-centered {
  min-height: calc(100% - 1rem); }

.top-level .lightbox {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }
  .top-level .lightbox .box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 15px; }
    .top-level .lightbox .box-wrapper .box {
      width: 600px;
      height: 400px;
      background-color: darkgrey;
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
      padding: 10px;
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
      position: relative; }
      .top-level .lightbox .box-wrapper .box .close-btn {
        position: relative;
        z-index: 100;
        font-size: 30px;
        color: #ccc;
        left: 100%;
        top: 0;
        border: 2px solid #ccc;
        border-radius: 50%;
        display: block;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 35px;
        margin-left: 10px;
        cursor: pointer;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
        display: none; }

.top-level .lightbox-effect {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 103vh;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  overflow: auto; }
  .top-level .lightbox-effect .box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px; }
    .top-level .lightbox-effect .box-wrapper .box-effect {
      min-width: 400px;
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
      padding: 10px;
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
      position: relative; }
      @media (min-width: 768px) {
        .top-level .lightbox-effect .box-wrapper .box-effect {
          max-width: 100%; } }
      @media (min-width: 992px) {
        .top-level .lightbox-effect .box-wrapper .box-effect {
          max-width: 90%; } }
      @media (min-width: 1200px) {
        .top-level .lightbox-effect .box-wrapper .box-effect {
          max-width: 85%; } }
      @media (min-width: 1440px) {
        .top-level .lightbox-effect .box-wrapper .box-effect {
          max-width: 90%; } }
      .top-level .lightbox-effect .box-wrapper .box-effect .modal-card {
        padding: 10px;
        background: white; }
        .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card {
          border: 2px solid darkgrey; }
          .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-image-wrapper .modal-gradient-image {
            width: 94.7%;
            height: 100%;
            position: absolute;
            z-index: 1; }
          .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-image-wrapper .slick-slider .slick-dots {
            bottom: 5px;
            z-index: 9; }
            .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-image-wrapper .slick-slider .slick-dots li {
              margin: 0px; }
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-image-wrapper .slick-slider .slick-dots li.slick-active button::before {
                color: white;
                font-size: 9px;
                opacity: 1; }
            .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-image-wrapper .slick-slider .slick-dots li button {
              background: transparent;
              border: 2px solid #fff;
              border-radius: 50%;
              height: 9px;
              line-height: 0;
              opacity: 1;
              padding: 0;
              width: 9px; }
            .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-image-wrapper .slick-slider .slick-dots li button:before {
              color: transparent;
              line-height: 11px;
              opacity: 1;
              width: 8px; }
          .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-image-wrapper .modal-image {
            object-fit: cover; }
            @media (min-width: 768px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-image-wrapper .modal-image {
                height: 400px; } }
            @media (min-width: 992px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-image-wrapper .modal-image {
                height: 510px; } }
            @media (min-width: 1200px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-image-wrapper .modal-image {
                height: 550px; } }
            @media (min-width: 1440px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-image-wrapper .modal-image {
                height: 550px; } }
          .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-title {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 20px 0px;
            font-size: 24px;
            font-weight: 600;
            text-transform: uppercase;
            color: #abb8bd; }
            @media (min-width: 768px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-title {
                font-size: 16px;
                padding: 10px 0px; } }
            @media (min-width: 992px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-title {
                font-size: 16px;
                padding: 18px 0px; } }
            @media (min-width: 1200px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-title {
                font-size: 18px;
                padding: 18px 0px; } }
            @media (min-width: 1440px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-title {
                font-size: 20px;
                padding: 16px 0px; } }
          .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .findout-more .btn {
            border-radius: 10px;
            background: #abb8bd;
            color: #0E2538;
            border: 1px solid #abb8bd;
            width: 48%;
            max-width: 150px;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 600;
            padding: 6px 0px;
            box-shadow: none; }
            @media (min-width: 768px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .findout-more .btn {
                font-size: 11px;
                padding: 3px 0px;
                width: 40%;
                margin: 10px 0px 0px 0px; } }
            @media (min-width: 992px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .findout-more .btn {
                font-size: 12px;
                padding: 5px 0px;
                width: 48%;
                margin: 0; } }
            @media (min-width: 1200px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .findout-more .btn {
                font-size: 13px;
                width: 48%; } }
          .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .findout-more-wide .btn {
            border-radius: 10px;
            background: #abb8bd;
            color: #0E2538;
            border: 1px solid #abb8bd;
            width: 48%;
            max-width: 190px;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 600;
            padding: 6px 0px;
            box-shadow: none; }
            @media (min-width: 768px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .findout-more-wide .btn {
                font-size: 11px;
                padding: 3px 0px;
                width: 55%;
                margin: 10px 0px 0px 0px; } }
            @media (min-width: 992px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .findout-more-wide .btn {
                font-size: 12px;
                padding: 5px 0px;
                width: 48%;
                margin: 0; } }
            @media (min-width: 1200px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .findout-more-wide .btn {
                font-size: 13px;
                width: 48%; } }
          .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-info-description-with-findmore {
            margin-left: auto;
            margin-right: auto;
            overflow-y: auto;
            padding: 0px 22px;
            font-size: 16px; }
            @media (min-width: 768px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-info-description-with-findmore {
                height: 242px; } }
            @media (min-width: 992px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-info-description-with-findmore {
                height: 350px; } }
            @media (min-width: 1200px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-info-description-with-findmore {
                height: 386px; } }
            .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-info-description-with-findmore::-webkit-scrollbar {
              width: 5px; }
            .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-info-description-with-findmore::-webkit-scrollbar-track {
              background: #f1f1f1;
              box-shadow: inset 0 0 5px grey;
              border-radius: 10px; }
            .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-info-description-with-findmore::-webkit-scrollbar-thumb {
              background: #888;
              border-radius: 5px; }
            @media (min-width: 768px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-info-description-with-findmore {
                font-size: 11px; } }
            @media (min-width: 992px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-info-description-with-findmore {
                font-size: 13px; } }
            @media (min-width: 1200px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-info-description-with-findmore {
                font-size: 14px; } }
            @media (min-width: 1440px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-info-description-with-findmore {
                font-size: 16px; } }
            .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-info-description-with-findmore p {
              margin: 0px;
              margin: 0px 0px 10px 0px; }
          .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item-findmore {
            font-family: 'Times New Roman', Times, serif;
            display: flex;
            justify-content: space-between;
            padding: 14px 12px 0px 12px;
            font-weight: 600;
            font-size: 14px; }
            @media (min-width: 768px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item-findmore {
                font-size: 10px;
                padding: 30px 0px 0px 0px; } }
            @media (min-width: 992px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item-findmore {
                font-size: 12px;
                padding: 14px 12px 0px 12px; } }
            @media (min-width: 1200px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item-findmore {
                font-size: 13px; } }
            @media (min-width: 1440px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item-findmore {
                font-size: 14px; } }
            .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item-findmore p {
              cursor: pointer;
              letter-spacing: 0.5px;
              margin: 0; }
            .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item-findmore .arrow-icon {
              margin-bottom: 2px;
              cursor: pointer;
              font-size: 1.5rem; }
          .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description {
            justify-content: center;
            text-align: center;
            align-content: center;
            padding: 1px 12px; }
            @media (max-width: 767px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description {
                padding: 20px; } }
            .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-info-description {
              margin-left: auto;
              margin-right: auto;
              overflow-y: auto;
              padding: 0px 22px;
              font-size: 16px; }
              @media (min-width: 768px) {
                .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-info-description {
                  height: 298px; } }
              @media (min-width: 992px) {
                .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-info-description {
                  height: 393px; } }
              @media (min-width: 1200px) {
                .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-info-description {
                  height: 430px; } }
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-info-description::-webkit-scrollbar {
                width: 5px; }
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-info-description::-webkit-scrollbar-track {
                background: #f1f1f1;
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px; }
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-info-description::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 5px; }
              @media (min-width: 768px) {
                .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-info-description {
                  font-size: 11px; } }
              @media (min-width: 992px) {
                .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-info-description {
                  font-size: 13px; } }
              @media (min-width: 1200px) {
                .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-info-description {
                  font-size: 14px; } }
              @media (min-width: 1440px) {
                .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-info-description {
                  font-size: 16px; } }
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-info-description p {
                margin: 0px;
                margin: 0px 0px 10px 0px; }
            .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-overview {
              text-align: center; }
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-overview .modal-overview-title {
                margin-top: 20px;
                font-weight: bold; }
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .modal-content-description .modal-overview .overview-point-list {
                list-style: none; }
          .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item {
            font-family: 'Times New Roman', Times, serif;
            display: flex;
            justify-content: space-between;
            padding: 14px 12px 0px 12px;
            font-weight: 600;
            font-size: 14px; }
            @media (min-width: 768px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item {
                font-size: 10px;
                padding: 22px 0px 0px 0px; } }
            @media (min-width: 992px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item {
                font-size: 12px;
                padding: 14px 12px 0px 12px; } }
            @media (min-width: 1200px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item {
                font-size: 13px; } }
            @media (min-width: 1440px) {
              .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item {
                font-size: 14px; } }
            .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item p {
              cursor: pointer;
              letter-spacing: 0.5px;
              margin: 0; }
            .top-level .lightbox-effect .box-wrapper .box-effect .modal-card .modal-inner-card .modal-content-new .next-item .arrow-icon {
              margin-bottom: 2px;
              cursor: pointer;
              font-size: 1.5rem; }
      .top-level .lightbox-effect .box-wrapper .box-effect .close-btn {
        z-index: 100;
        font-size: 25px;
        color: #abb8bd;
        border-radius: 50%;
        display: block;
        text-align: center;
        line-height: 24px;
        cursor: pointer;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
        position: absolute;
        right: 3px; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.cardlist-container {
  background: #fff;
  margin: 0px; }
  @media (min-width: 768px) {
    .cardlist-container {
      padding: 50px; } }
  @media (min-width: 1200px) {
    .cardlist-container {
      padding: 5rem 80px 100px 80px; } }
  @media (max-width: 767px) {
    .cardlist-container {
      display: none; } }
  .cardlist-container .title {
    font-size: 25px;
    color: #abb8bd;
    text-transform: uppercase;
    display: flex;
    padding: 20px 0px 20px 0px;
    justify-content: center;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px; }
  .cardlist-container .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    align-items: stretch; }
    @media (min-width: 768px) {
      .cardlist-container .grid {
        grid-gap: 40px; } }
    @media screen and (min-width: 768px) and (max-width: 1280px) {
      .cardlist-container .grid {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.arrow-icon {
  margin-bottom: 2px;
  cursor: pointer;
  font-size: 1.5rem; }

.mobile-offer-card-wrapper {
  background-color: #dee2e6;
  padding: 4rem 20px;
  display: block; }
  @media (min-width: 768px) {
    .mobile-offer-card-wrapper {
      display: none; } }
  @media (min-width: 1200px) {
    .mobile-offer-card-wrapper {
      display: none; } }
  .mobile-offer-card-wrapper .slick-prev {
    left: -3% !important;
    z-index: 1;
    margin-top: 80px; }
    @media (min-width: 360px) and (max-width: 376px) {
      .mobile-offer-card-wrapper .slick-prev {
        left: -6% !important; } }
    .mobile-offer-card-wrapper .slick-prev img {
      width: 100%;
      height: 100%; }
  .mobile-offer-card-wrapper .slick-next {
    right: -3% !important;
    z-index: 1;
    margin-top: 60px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
    @media (min-width: 360px) and (max-width: 376px) {
      .mobile-offer-card-wrapper .slick-next {
        right: -6% !important; } }
    .mobile-offer-card-wrapper .slick-next img {
      width: 100%;
      height: 100%; }
  .mobile-offer-card-wrapper .offer-card-title {
    background-color: inherit;
    font-size: 24px;
    color: #0E2538;
    display: flex;
    justify-content: center;
    padding: 30px 0px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 600; }
    @media (max-width: 767px) {
      .mobile-offer-card-wrapper .offer-card-title {
        font-weight: 500;
        font-size: 20px; } }
  .mobile-offer-card-wrapper .offer-card-main-wrapper {
    padding: 0px 20px; }
  .mobile-offer-card-wrapper .offer-card-image {
    -webkit-transition: opacity 500ms ease 0s, visibility 500ms ease 0s;
    transition: opacity 500ms ease 0s, visibility 500ms ease 0s; }
    .mobile-offer-card-wrapper .offer-card-image .card-image {
      width: 100%;
      object-fit: cover;
      height: 250px; }
  .mobile-offer-card-wrapper .offer-card-content {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 10px;
    position: relative;
    margin-top: -50px;
    display: flex; }
    @media (min-width: 360px) and (max-width: 376px) {
      .mobile-offer-card-wrapper .offer-card-content {
        width: 280px; } }
    .mobile-offer-card-wrapper .offer-card-content .offer-inner-card {
      border: 1px solid darkgray; }
      .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-title {
        display: flex;
        justify-content: center;
        padding: 20px 0px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-family: 'Times New Roman', Times, serif;
        text-align: center; }
      .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description {
        display: flex;
        justify-content: center;
        padding: 0px 20px;
        text-align: center;
        -webkit-transition: .25s ease;
        transition: .25s ease; }
        .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .findout-more .btn {
          border-radius: 10px;
          background: #abb8bd;
          color: #0E2538;
          border: 1px solid #abb8bd;
          width: 60%;
          max-width: 150px;
          text-transform: uppercase;
          font-size: 13px;
          font-weight: 600;
          padding: 6px 0px;
          box-shadow: none; }
          @media (min-width: 768px) {
            .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .findout-more .btn {
              font-size: 11px;
              padding: 3px 0px;
              width: 40%;
              margin: 10px 0px 0px 0px; } }
          @media (min-width: 992px) {
            .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .findout-more .btn {
              font-size: 12px;
              padding: 5px 0px;
              width: 48%;
              margin: 0; } }
          @media (min-width: 1200px) {
            .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .findout-more .btn {
              font-size: 13px;
              width: 48%; } }
        .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore {
          position: relative;
          max-height: 170px;
          overflow: hidden;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 14px;
          margin-bottom: 0px;
          -webkit-transition: max-height 0.15s ease-out;
          transition: max-height 0.15s ease-out; }
          .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore .modal-overview {
            text-align: center; }
            .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore .modal-overview .modal-overview-title {
              margin-top: 20px;
              font-weight: bold; }
            .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore .modal-overview .overview-point-list {
              list-style: none;
              padding: 0px; }
        .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore.expand {
          max-height: 100% !important;
          -webkit-transition: max-height 0.35s ease-in-out;
          transition: max-height 0.35s ease-in-out; }
          .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore.expand .modal-overview {
            text-align: center; }
            .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore.expand .modal-overview .modal-overview-title {
              margin-top: 20px;
              font-weight: bold; }
            .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore.expand .modal-overview .overview-point-list {
              list-style: none;
              padding: 0px; }
        .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore-link {
          position: absolute;
          bottom: 0;
          right: 0;
          display: block;
          width: 100%;
          height: 60px;
          text-align: center;
          color: darkgray;
          font-weight: bold;
          font-size: 16px;
          padding-top: 14px;
          background-image: -webkit-gradient(linear, left top, left bottom, from(white), to(white));
          background-image: linear-gradient(to bottom, white, white);
          cursor: pointer;
          color: #12293b;
          font-size: 14px;
          letter-spacing: 0.5px; }
          .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore-link::after {
            content: "Read more"; }
        .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore-links {
          position: absolute;
          bottom: 0;
          right: 0;
          display: block;
          width: 100%;
          height: 60px;
          text-align: center;
          color: darkgray;
          font-weight: bold;
          font-size: 16px;
          padding-top: 14px;
          background-image: -webkit-gradient(linear, left top, left bottom, from(white), to(white));
          background-image: linear-gradient(to bottom, white, white);
          cursor: pointer;
          color: #12293b;
          font-size: 14px;
          letter-spacing: 0.5px; }
          .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore-links::after {
            content: "Read more"; }
        .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore-link.expand {
          position: relative;
          background-image: none;
          padding-top: 0px;
          height: 30px;
          color: #12293b;
          font-size: 14px;
          letter-spacing: 0.5px;
          margin: 9px 0px; }
          .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore-link.expand::after {
            content: "Read less"; }
        .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore-links.expands {
          position: relative;
          background-image: none;
          padding-top: 0px;
          height: 30px;
          color: #12293b;
          font-size: 14px;
          letter-spacing: 0.5px;
          margin: 9px 0px; }
          .mobile-offer-card-wrapper .offer-card-content .offer-inner-card .offer-description .readmore-links.expands::after {
            content: "Read less"; }
  .mobile-offer-card-wrapper .offer-read-more {
    display: flex;
    padding: 20px;
    justify-content: center;
    -webkit-transition: .25s ease;
    transition: .25s ease; }
    .mobile-offer-card-wrapper .offer-read-more .read-more {
      border: none;
      background: inherit;
      font-size: 16px;
      color: darkgray; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
@media (max-width: 767px) {
  .arrow-settings-otherclub {
    display: block; } }

@media (min-width: 768px) {
  .arrow-settings-otherclub {
    display: none; } }

@media (min-width: 992px) {
  .arrow-settings-otherclub {
    display: none; } }

.otherclub-container {
  background: white; }
  @media (max-width: 767px) {
    .otherclub-container {
      padding: 50px 50px 25px 50px; } }
  @media (min-width: 768px) {
    .otherclub-container {
      padding: 50px 50px 50px 50px; } }
  @media (min-width: 992px) {
    .otherclub-container {
      padding: 50px 50px 50px 50px; } }
  @media (min-width: 768px) {
    .otherclub-container .hotel-card {
      padding: 0px 40px; } }
  @media (min-width: 992px) {
    .otherclub-container .hotel-card {
      padding: 0px 60px; } }
  @media (min-width: 1200px) {
    .otherclub-container .hotel-card {
      align-items: center;
      display: flex !important;
      flex-direction: column; } }
  @media (min-width: 992px) {
    .otherclub-container .slick-slider {
      padding: 0em 2em; } }
  @media (min-width: 1200px) {
    .otherclub-container .slick-slider {
      padding: 0em 2em; } }
  @media (min-width: 1440px) {
    .otherclub-container .slick-slider {
      padding: 0em 6em; } }
  .otherclub-container .otherclubs-title {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.5px;
    margin: 50px 0px 60px 0px;
    color: #0E2538;
    font-family: 'Times New Roman', Times, serif; }
    @media (max-width: 767px) {
      .otherclub-container .otherclubs-title {
        font-size: 22px; } }
    @media (min-width: 768px) {
      .otherclub-container .otherclubs-title {
        font-size: 24px; } }
    @media (max-width: 767px) {
      .otherclub-container .otherclubs-title {
        margin: 20px 0px 40px 0px; } }
    @media (min-width: 768px) {
      .otherclub-container .otherclubs-title {
        margin: 50px 0px 60px 0px; } }
    @media (min-width: 1200px) {
      .otherclub-container .otherclubs-title {
        font-size: 24px;
        letter-spacing: 2.5px; } }
  .otherclub-container .top-div {
    display: flex;
    justify-content: space-between;
    min-width: 250px;
    align-items: center; }
    .otherclub-container .top-div .middle {
      width: 100%;
      height: 300px; }
      @media (min-width: 768px) {
        .otherclub-container .top-div .middle {
          width: 400px;
          height: 300px; } }
      @media (min-width: 1200px) {
        .otherclub-container .top-div .middle {
          width: 400px;
          height: 300px; } }
      .otherclub-container .top-div .middle .club-image {
        object-fit: cover;
        width: 100%; }
  .otherclub-container .arrow-settings-otherclub .common-row-relative {
    position: relative;
    top: -380px; }
  .otherclub-container .arrow-settings-otherclub .common-relative.text-left {
    position: relative;
    left: -50px; }
  .otherclub-container .arrow-settings-otherclub .common-relative.text-right {
    position: relative;
    right: -50px; }
  .otherclub-container .arrow-settings-otherclub .left-arrow {
    width: 30px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .otherclub-container .arrow-settings-otherclub .right-arrow {
    width: 30px; }
  .otherclub-container .slick-prev {
    margin-top: -114px;
    margin-left: -10px;
    z-index: 1;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
    .otherclub-container .slick-prev img {
      width: 100%;
      height: 100%; }
  .otherclub-container .slick-next {
    z-index: 1;
    margin-top: -115px;
    margin-right: -10px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
    .otherclub-container .slick-next img {
      width: 100%;
      height: 100%; }
  .otherclub-container .slick-slide img {
    width: 100%;
    height: 100%; }
  .otherclub-container .bottom-div {
    font-family: 'Times New Roman', Times, serif;
    text-align: center; }
    @media (min-width: 768px) {
      .otherclub-container .bottom-div {
        max-width: 400px; } }
    @media (min-width: 1200px) {
      .otherclub-container .bottom-div {
        align-items: center;
        display: flex;
        flex-direction: column;
        max-width: 400px; } }
    .otherclub-container .bottom-div div {
      margin: 20px 0px 0px 0px;
      align-items: center; }
    .otherclub-container .bottom-div .hotel-title {
      font-size: 14px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 600;
      padding: 19px 0px 0px 0px; }
    .otherclub-container .bottom-div .hotel-description {
      font-size: 13px;
      margin: 3px 67px;
      font-family: Arial, Helvetica, sans-serif;
      align-items: center; }
      @media (max-width: 767px) {
        .otherclub-container .bottom-div .hotel-description {
          height: auto;
          margin: auto !important; } }
      @media (min-width: 768px) {
        .otherclub-container .bottom-div .hotel-description {
          height: 75px;
          margin: 3px 10px; } }
      @media (min-width: 992px) {
        .otherclub-container .bottom-div .hotel-description {
          height: auto;
          margin: 3px 67px; } }
      @media (max-width: 767px) {
        .otherclub-container .bottom-div .hotel-description {
          margin: 0px 50px; } }
    .otherclub-container .bottom-div .link {
      font-size: 14px;
      font-family: Arial, Helvetica, sans-serif;
      text-decoration: underline;
      color: #abb8bd; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.footer-section {
  border-top: 8px solid #abb8bd; }
  @media (min-width: 992px) {
    .footer-section {
      zoom: 0.880; } }
  @media (min-width: 1200px) {
    .footer-section {
      zoom: 0.880; } }
  @media (min-width: 1440px) {
    .footer-section {
      zoom: 1; } }
  .footer-section .container-fluid {
    width: 100%; }
    @media (min-width: 1200px) {
      .footer-section .container-fluid {
        padding: 50px 35px; } }
  .footer-section .footer-logo {
    padding-left: 20px; }
    .footer-section .footer-logo .logo-image {
      width: 200px;
      height: 200px; }
    @media (max-width: 767px) {
      .footer-section .footer-logo .logo-image {
        width: 120px;
        height: 120px; } }
  .footer-section .mobile-view-padding {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important; }
    @media (max-width: 767px) {
      .footer-section .mobile-view-padding {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important; } }
    @media (min-width: 768px) {
      .footer-section .mobile-view-padding {
        padding-bottom: 2rem !important;
        padding-top: 3rem !important; } }
    @media (min-width: 992px) {
      .footer-section .mobile-view-padding {
        padding-bottom: 2rem !important;
        padding-top: 3rem !important; } }
    @media (min-width: 1200px) {
      .footer-section .mobile-view-padding {
        padding-bottom: 0.5rem !important;
        padding-top: 0.5rem !important; } }
  .footer-section .footer-menuitems {
    list-style: none;
    color: #0e2538;
    font-size: 14px;
    text-transform: uppercase;
    font-family: "Times New Roman", Times, serif;
    letter-spacing: 1px;
    padding: 0px; }
    @media (max-width: 767px) {
      .footer-section .footer-menuitems {
        font-size: 12px;
        text-align: right;
        padding: 0px 21px; } }
    .footer-section .footer-menuitems a {
      color: #0e2538; }
    .footer-section .footer-menuitems li {
      padding: 4px 0px; }
    @media (max-width: 767px) {
      .footer-section .footer-menuitems li {
        padding: 0px 0px; } }
    .footer-section .footer-menuitems .icons-size {
      font-size: 25px;
      padding: 0px;
      color: #ABB8BD; }
      .footer-section .footer-menuitems .icons-size .tagclass {
        font-size: 25px;
        padding: 0px;
        color: #ABB8BD;
        text-decoration: none; }
    .footer-section .footer-menuitems .social-media {
      margin: -9px 0px; }

.footer-mobile-view {
  display: flex;
  flex-direction: column; }
  .footer-mobile-view .newsletter-mobile-view {
    display: none; }
    @media screen and (max-width: 760px) {
      .footer-mobile-view .newsletter-mobile-view {
        display: flex;
        justify-content: center;
        background-color: #0d2233; } }
    .footer-mobile-view .newsletter-mobile-view .newsletter {
      display: flex;
      justify-content: center; }
      .footer-mobile-view .newsletter-mobile-view .newsletter .newsletter-form {
        justify-content: center;
        align-items: center;
        display: grid; }
        @media screen and (max-width: 450px) {
          .footer-mobile-view .newsletter-mobile-view .newsletter .newsletter-form .newsletter-row-1 input {
            width: 160px; } }
  .footer-mobile-view .footer {
    background-color: #fff;
    border-top: 8px solid #abb8bd;
    color: #0d2233;
    line-height: 1.5;
    padding: 1.25rem 1.875rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
    .footer-mobile-view .footer .footer-logo {
      left: 1.5rem;
      background: transparent;
      font-size: 2rem;
      cursor: pointer;
      outline: none;
      color: #abb8bd; }
      .footer-mobile-view .footer .footer-logo .logo-image {
        width: 100px;
        height: 100px; }
    @media screen and (max-width: 760px) {
      .footer-mobile-view .footer .footer-newsletter {
        display: none; } }
    .footer-mobile-view .footer .footer-menuitems {
      list-style: none;
      color: #0d2233;
      font-size: 14px;
      text-transform: uppercase;
      padding: 10px; }
      @media screen and (min-width: 760px) and (max-width: 864px) {
        .footer-mobile-view .footer .footer-menuitems {
          display: none; } }
      @media screen and (max-width: 424px) {
        .footer-mobile-view .footer .footer-menuitems {
          display: none; } }
    .footer-mobile-view .footer .footer-items {
      list-style: none;
      color: #0d2233;
      font-size: 14px;
      padding: 10px;
      text-transform: uppercase; }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .newsletter-mobileview {
    display: block; }
  .newsletter-desktopview {
    display: none; }
  .footer-menuitems1 {
    display: none; } }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .newsletter-mobileview {
    display: block; }
  .newsletter-desktopview {
    display: none; }
  .footer-menuitems1 {
    display: none; } }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .newsletter-mobileview {
    display: block; }
  .newsletter-desktopview {
    display: none; }
  .footer-menuitems1 {
    display: block; } }

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .newsletter-mobileview {
    display: none; }
  .newsletter-desktopview {
    display: block; }
  .footer-menuitems1 {
    display: block; } }

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .newsletter-mobileview {
    display: none; }
  .newsletter-desktopview {
    display: block; }
  .footer-menuitems1 {
    display: block; } }

#CookieReportsPanel #CookieReportsBanner .wscrHeading {
  font-family: 'Times New Roman', Times, serif;
  color: #0E2538; }
  @media (min-width: 992px) {
    #CookieReportsPanel #CookieReportsBanner .wscrHeading {
      font-size: 18px; } }
  @media (min-width: 1200px) {
    #CookieReportsPanel #CookieReportsBanner .wscrHeading {
      font-size: 18px; } }
  @media (min-width: 1440px) {
    #CookieReportsPanel #CookieReportsBanner .wscrHeading {
      font-size: 20px; } }

#CookieReportsPanel #CookieReportsBanner #CookieReportsBannerDescription {
  font-family: Arial, Helvetica, sans-serif;
  color: #abb8bd; }
  #CookieReportsPanel #CookieReportsBanner #CookieReportsBannerDescription p {
    font-size: 13px; }

#CookieReportsPanel #CookieReportsBanner .wscrBannerContentInner {
  font-family: Arial, Helvetica, sans-serif; }
  #CookieReportsPanel #CookieReportsBanner .wscrBannerContentInner .wscrOk {
    font-size: 13px;
    background: #0E2538; }
  #CookieReportsPanel #CookieReportsBanner .wscrBannerContentInner .wscrOk2 {
    font-size: 13px;
    background: #0E2538; }

#CookieReportsPanel #CookieReportsBanner .wscrBannerContentInner2 {
  font-family: Arial, Helvetica, sans-serif; }
  #CookieReportsPanel #CookieReportsBanner .wscrBannerContentInner2 .wscrBannerLink {
    font-size: 12px; }

#CookieReportsPanel #CookieReportsAccordion {
  width: 102%; }
  #CookieReportsPanel #CookieReportsAccordion #wscrAccordionInjectContent span {
    color: #abb8bd !important;
    font-size: 12px !important; }
  #CookieReportsPanel #CookieReportsAccordion #wscrAccordionInjectContent a {
    color: #abb8bd !important;
    font-size: 12px; }
    #CookieReportsPanel #CookieReportsAccordion #wscrAccordionInjectContent a span {
      color: #abb8bd !important; }
  #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections .CookieReportsAccordionHeading {
    background: #0E2538; }
  #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections .CookieReportsAccordionHeading::after {
    color: white; }
  #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionCount {
    min-width: 25px; }
    @media (max-width: 767px) {
      #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionCount {
        min-width: 12px; } }
  #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle {
    padding: 0px; }
    #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle .wscrSectionTitleText {
      color: white;
      letter-spacing: 1.5px;
      font-size: 19px;
      cursor: pointer;
      font-family: 'Times New Roman', Times, serif;
      text-transform: uppercase; }
      @media (max-width: 767px) {
        #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle .wscrSectionTitleText {
          font-size: 11px; } }
      @media (min-width: 768px) {
        #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle .wscrSectionTitleText {
          font-size: 16px; } }
      @media (min-width: 1200px) {
        #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle .wscrSectionTitleText {
          font-size: 16px; } }
  #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitleConsent .wscrSwitchParts {
    border: 2px solid #0E2538; }
    #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitleConsent .wscrSwitchParts .wscrSwitchLabel:before {
      padding-left: 10px;
      background-color: white;
      color: #0E2538;
      border: none; }
    #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitleConsent .wscrSwitchParts .wscrSwitchLabel:after {
      content: "\2716";
      padding-right: 10px;
      background-color: white;
      text-align: right;
      color: #0E2538; }
  #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitleConsent .wscrSwitchToggle {
    background: #0E2538; }
  #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections dd .wscrSectionDescription {
    font-family: Arial, Helvetica, sans-serif;
    padding: 11px 14px;
    color: #0E2538;
    font-size: 12px; }
    #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections dd .wscrSectionDescription p {
      font-size: 13px;
      font-family: Arial, Helvetica, sans-serif;
      color: #0E2538;
      letter-spacing: 1px;
      line-height: 1.5; }
  #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table th {
    border-bottom: 1px solid #ccc;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase; }
  #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table td {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px; }
    @media (max-width: 767px) {
      #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table td {
        font-size: 10px; } }
    @media (min-width: 768px) {
      #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table td {
        font-size: 13px; } }
  #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table .wscrField-description {
    font-size: 12px; }
    @media (max-width: 767px) {
      #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table .wscrField-description {
        padding: 10px 0px 20px 0px; } }
    @media (min-width: 768px) {
      #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table .wscrField-description {
        padding: 10px 10px 20px 10px; } }
    #CookieReportsPanel #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table .wscrField-description .wscrCompanyPrivacy li a {
      color: #abb8bd;
      font-size: 12px; }

@media (max-width: 767px) {
  #CookieReportsPanel .wscrSwitch {
    zoom: 0.7;
    position: relative;
    left: 29px; } }

.newsletter-desktopview input {
  color: #abb8bd;
  border: 1px solid;
  border-radius: 9px;
  font-size: 12px;
  padding: 5px 0.75rem;
  height: auto; }

.newsletter-desktopview input::-webkit-input-placeholder {
  opacity: 0.5;
  letter-spacing: 0.8px; }

.newsletter-desktopview input::-moz-placeholder {
  opacity: 0.5;
  letter-spacing: 0.8px; }

.newsletter-desktopview input::-ms-input-placeholder {
  opacity: 0.5;
  letter-spacing: 0.8px; }

.newsletter-desktopview input::placeholder {
  opacity: 0.5;
  letter-spacing: 0.8px; }

.newsletter-desktopview input:focus {
  color: #abb8bd;
  border: 1px solid;
  border-radius: 9px;
  box-shadow: none; }

.newsletter-desktopview button {
  background-color: #abb8bd;
  color: #0e2538;
  padding: 2px 33px;
  border-radius: 9px;
  font-weight: 500;
  box-shadow: none;
  font-size: 14px; }

.newsletter-desktopview .lastname-padding {
  padding: 0px 15px 0px 7px; }

.newsletter-desktopview .firstname-padding {
  padding: 0px 0px 0px 15px; }

.newsletter-desktopview .error {
  color: #dc3545;
  font-size: 12px;
  padding-left: 12px; }

.newsletter {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }
  .newsletter .newsletter-title {
    margin: 6px 4px;
    color: #0e2538;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    font-family: "Times New Roman", Times, serif;
    letter-spacing: 1px; }
  .newsletter .myform {
    display: contents; }
  .newsletter .spinner {
    margin-right: 5px;
    -webkit-animation: spin infinite 5s linear;
            animation: spin infinite 5s linear;
    /*You can increase or decrease the timer (5s) to 
       increase or decrease the speed of the spinner*/ }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .newsletter .newsletter-form {
    display: flex;
    flex-direction: column; }
    .newsletter .newsletter-form .newsletter-row-1 input {
      padding: 5px 5px;
      border-radius: 10px;
      border: 1px solid darkgrey;
      margin: 5px;
      font-size: 15px;
      background: transparent;
      max-width: calc(100% - 60px); }
    .newsletter .newsletter-form .newsletter-row-1 input:focus {
      outline: none; }
    .newsletter .newsletter-form .newsletter-row-1 input::-webkit-input-placeholder {
      padding-left: 5px;
      font-size: 15px;
      color: darkgrey; }
    .newsletter .newsletter-form .newsletter-row-2 input {
      padding: 5px 5px;
      border-radius: 10px;
      border: 1px solid darkgrey;
      margin: 5px;
      font-size: 15px;
      background: transparent; }
    .newsletter .newsletter-form .newsletter-row-2 input:focus {
      outline: none; }
    .newsletter .newsletter-form .newsletter-row-2 input::-webkit-input-placeholder {
      padding-left: 5px;
      font-size: 15px;
      color: darkgrey; }
    .newsletter .newsletter-form .newsletter-row-3 input {
      padding: 5px 5px;
      border-radius: 10px;
      border: 1px solid darkgrey;
      margin: 5px;
      font-size: 15px;
      background: transparent; }
    .newsletter .newsletter-form .newsletter-row-3 input[type=submit] {
      background-color: darkgrey;
      width: 100px; }
    .newsletter .newsletter-form .newsletter-row-3 input:focus {
      outline: none; }
    .newsletter .newsletter-form .newsletter-row-3 input::-webkit-input-placeholder {
      padding-left: 5px;
      font-size: 15px;
      color: darkgrey; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.newsletter {
  background-size: cover;
  min-height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.newslettermobileview-title {
  color: #abb8bd;
  text-align: center;
  padding-bottom: 20px;
  /* color: #0e2538; */
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  font-family: "Times New Roman", Times, serif;
  letter-spacing: 1px; }

@media (max-width: 767px) {
  .newsletter-mobileview {
    background-repeat: no-repeat;
    background-position: 15% center;
    zoom: 0.8; } }
  @media screen and (max-width: 767px) and (min-width: 576px) and (max-width: 768px) {
    .newsletter-mobileview {
      zoom: 0.9; } }

@media (max-width: 767px) {
    .newsletter-mobileview .container {
      padding: 0px 16% 0px 9%; } }
    @media screen and (max-width: 767px) and (min-width: 576px) and (max-width: 768px) {
      .newsletter-mobileview .container {
        padding: 0px; } }

.newsletter-mobileview input {
  background: transparent;
  color: #abb8bd;
  border: 1px solid;
  border-radius: 9px;
  font-size: 13px;
  box-shadow: none; }

.newsletter-mobileview input::-webkit-input-placeholder {
  letter-spacing: 0.8px;
  opacity: 1; }

.newsletter-mobileview input::-moz-placeholder {
  letter-spacing: 0.8px;
  opacity: 1; }

.newsletter-mobileview input::-ms-input-placeholder {
  letter-spacing: 0.8px;
  opacity: 1; }

.newsletter-mobileview input::placeholder {
  letter-spacing: 0.8px;
  opacity: 1; }

.newsletter-mobileview input:focus {
  background: transparent;
  color: #abb8bd;
  border: 2px solid;
  border-radius: 9px;
  font-size: 13px;
  box-shadow: none; }

.newsletter-mobileview button {
  background-color: #abb8bd;
  color: #0e2538;
  padding: 5px 55px;
  border-radius: 7px;
  font-weight: 500;
  box-shadow: none;
  font-size: 14px;
  height: calc(1.5em + 0.75rem + 2px);
  text-align: center; }

.newsletter-mobileview .lastname-padding {
  padding: 0px 15px 0px 7px; }

.newsletter-mobileview .firstname-padding {
  padding: 0px 0px 0px 15px; }

.newsletter-mobileview .submit-button-newsletter {
  color: #abb8bd;
  text-align: center; }

.newsletter-mobileview .error {
  color: #dc3545;
  font-size: 10px;
  padding-left: 12px; }

.newsletter-mobileview .spinner {
  margin-right: 5px;
  -webkit-animation: spin infinite 5s linear;
          animation: spin infinite 5s linear;
  /*You can increase or decrease the timer (5s) to 
          increase or decrease the speed of the spinner*/ }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.navbar-container .navlist {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.navbar-container .navitem {
  color: #fff;
  display: block;
  float: left;
  padding: 1rem;
  position: relative;
  text-decoration: none;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s; }

.navbar-container .navitem a {
  color: #fff; }

.navbar-container .navitem:hover {
  cursor: pointer; }

.navbar-container .navlist {
  /* stroke */ }
  .navbar-container .navlist .menulist .secondlist {
    display: none;
    width: 220px;
    background: #102638;
    position: absolute;
    top: 40px;
    font-size: 14px;
    list-style-type: none;
    padding: 10px;
    text-align: left; }
  .navbar-container .navlist .menulist .secondlist-color {
    top: 60px; }
  .navbar-container .navlist .menulist a,
  .navbar-container .navlist .menulist a:after,
  .navbar-container .navlist .menulist a:before {
    -webkit-transition: all .5s;
    transition: all .5s; }
  .navbar-container .navlist .menulist a:hover {
    color: white;
    text-decoration: none; }
  .navbar-container .navlist .menulist a {
    position: relative; }
  .navbar-container .navlist .menulist a:after {
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: white;
    height: 2px;
    border-radius: 10px; }
  .navbar-container .navlist .menulist a:hover:after {
    width: 100%; }
  .navbar-container .navlist .menulist a:after {
    text-align: left;
    content: '.';
    margin: 0;
    opacity: 0; }
  .navbar-container .navlist .menulist a:hover {
    color: white;
    z-index: 1; }
  .navbar-container .navlist .menulist a:hover:after {
    z-index: -10;
    animation: fill 1s forwards;
    -webkit-animation: fill 1s forwards;
    -moz-animation: fill 1s forwards;
    opacity: 1; }
  .navbar-container .navlist .menulist:hover .secondlist {
    display: block; }
  .navbar-container .navlist .selected:after,
  .navbar-container .navlist .selected:before {
    -webkit-transition: all .5s !important;
    transition: all .5s !important; }
  .navbar-container .navlist .selected:hover {
    color: white !important;
    text-decoration: none !important; }
  .navbar-container .navlist .selected {
    position: relative !important; }
  .navbar-container .navlist .selected:after {
    position: absolute !important;
    bottom: -2px !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    width: 0% !important;
    content: '.';
    color: transparent !important;
    background: white !important;
    height: 2px !important;
    border-radius: 10px !important; }
  .navbar-container .navlist .selected:after {
    width: 100% !important; }
  .navbar-container .navlist .selected:after {
    text-align: left !important;
    content: '.';
    margin: 0 !important;
    opacity: 0 !important; }
  .navbar-container .navlist .selected {
    color: white !important;
    z-index: 1 !important; }
  .navbar-container .navlist .selected:after {
    z-index: -10 !important;
    animation: fill 1s forwards !important;
    -webkit-animation: fill 1s forwards !important;
    -moz-animation: fill 1s forwards !important;
    opacity: 1 !important; }

.navbar-container .navlist .navitem .navlist {
  visibility: hidden;
  opacity: 0;
  min-width: 5rem;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-top: 1rem;
  left: -60px;
  display: none; }

.navbar-container .navlist .navitem:hover > .navlist,
.navbar-container .navlist .navitem .navlist:hover {
  margin-top: 160px;
  visibility: visible;
  opacity: 1;
  display: block; }

.navbar-container .navlist .navitem .navlist .navitem {
  clear: both;
  min-width: 300px;
  font-size: 14px;
  padding: 11px; }

.navbar-container .navlist .navitem .navlist .navitem:hover {
  background: #0d2233c9; }

.navbar-container a.box-border {
  font-weight: 600; }

.navbar-container a.join-now {
  font-weight: 600; }

.navbar-container a.box-border:hover {
  background: #fff;
  color: black !important;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease; }

.navigation__item .dropdown-toggle:after {
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none; }

.navigation__item .dropdown-toggle-down::after {
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: .3em solid transparent;
  border-left: 0.3em solid transparent;
  position: relative;
  top: 6px;
  right: 5px;
  margin-left: 8px; }

.navigation__item .dropdown-toggle-up::after {
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.navbar-transparent {
  height: 100px;
  margin-top: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  color: #abb8bd;
  background-color: transparent;
  -webkit-transition: 0.8s all ease;
  transition: 0.8s all ease; }
  .navbar-transparent .navigation__icon,
  .navbar-transparent .navigation__icon::before,
  .navbar-transparent .navigation__icon::after {
    display: inline-block;
    width: 2.2rem;
    height: 2px;
    background-color: white; }
    @media (max-width: 767px) {
      .navbar-transparent .navigation__icon,
      .navbar-transparent .navigation__icon::before,
      .navbar-transparent .navigation__icon::after {
        width: 2rem; } }
  @media screen and (max-width: 960px) {
    .navbar-transparent {
      -webkit-transition: 0.8s all ease;
      transition: 0.8s all ease; } }
  .navbar-transparent .navbar-container {
    display: flex;
    justify-content: flex-start;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0px 8px; }
    .navbar-transparent .navbar-container .logo {
      justify-self: flex-start;
      cursor: pointer;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      margin-left: 0px;
      font-weight: bold;
      text-decoration: none; }
      @media (min-width: 1200px) {
        .navbar-transparent .navbar-container .logo {
          padding-left: 40px; } }
      .navbar-transparent .navbar-container .logo .logo-image {
        width: 170px;
        height: 170px;
        margin-top: 50%;
        -webkit-transition: 0.8s all ease;
        transition: 0.8s all ease; }
        @media (min-width: 992px) {
          .navbar-transparent .navbar-container .logo .logo-image {
            width: 155px;
            height: 155px; } }
        @media (min-width: 1200px) {
          .navbar-transparent .navbar-container .logo .logo-image {
            width: 160px;
            height: 160px; } }
        @media (min-width: 1440px) {
          .navbar-transparent .navbar-container .logo .logo-image {
            width: 170px;
            height: 170px; } }
    .navbar-transparent .navbar-container .mobile-icon {
      display: none; }
    .navbar-transparent .navbar-container .navlist {
      display: flex;
      align-items: center;
      list-style: none;
      text-align: center;
      font-family: 'Times New Roman', Times, serif;
      margin: 5px 40px 0px auto; }
      @media (max-width: 767px) {
        .navbar-transparent .navbar-container .navlist {
          margin: 5px 22px 0px auto; } }
      .navbar-transparent .navbar-container .navlist .navitem {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 0 0.7rem;
        height: 100%;
        cursor: pointer;
        height: 48px;
        color: whitesmoke;
        text-transform: uppercase;
        letter-spacing: 1px; }
        .navbar-transparent .navbar-container .navlist .navitem .box-border {
          color: #fff;
          border: 1px solid #fff;
          padding: 0px 5px;
          text-decoration: none; }
        @media (min-width: 992px) {
          .navbar-transparent .navbar-container .navlist .navitem {
            font-size: 14px;
            padding: 0 0.5rem; } }
        @media (min-width: 1200px) {
          .navbar-transparent .navbar-container .navlist .navitem {
            font-size: 14px;
            padding: 0 0.5rem;
            font-weight: 400; } }
        @media (min-width: 1440px) {
          .navbar-transparent .navbar-container .navlist .navitem {
            font-size: 16px;
            padding: 0 0.7rem; } }

.burger-menu-li {
  width: 39px; }

.whats-app-icon {
  position: fixed;
  bottom: 1.25rem;
  z-index: 20;
  height: 3rem;
  left: 1.4rem; }

.navbar-black {
  height: 60px;
  margin-top: -60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  color: #abb8bd;
  background-color: #0E2538; }
  @media (max-width: 767px) {
    .navbar-black {
      height: 60px; } }
  @media (min-width: 768px) {
    .navbar-black {
      height: 60px; } }
  .navbar-black .navigation__icon,
  .navbar-black .navigation__icon::before,
  .navbar-black .navigation__icon::after {
    display: inline-block;
    width: 2.2rem;
    height: 2px;
    background-color: #abb8bd; }
    @media (max-width: 767px) {
      .navbar-black .navigation__icon,
      .navbar-black .navigation__icon::before,
      .navbar-black .navigation__icon::after {
        width: 1.7rem; } }
  .navbar-black .navigation__button {
    top: 0.5rem; }
  @media screen and (max-width: 960px) {
    .navbar-black {
      -webkit-transition: 0.8s all ease;
      transition: 0.8s all ease; } }
  .navbar-black .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 60px;
    z-index: 1;
    width: 90%;
    padding: 0px; }
    @media (min-width: 992px) {
      .navbar-black .navbar-container {
        padding: 0px 44px 0px 0px; } }
    @media (min-width: 1200px) {
      .navbar-black .navbar-container {
        padding: 0px; } }
    .navbar-black .navbar-container .logo {
      justify-self: flex-start;
      cursor: pointer;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      margin-left: 0px;
      font-weight: bold;
      text-decoration: none; }
      @media (max-width: 767px) {
        .navbar-black .navbar-container .logo {
          position: relative;
          right: 30px; } }
      @media (min-width: 768px) {
        .navbar-black .navbar-container .logo {
          position: relative;
          right: 30px; } }
      @media (min-width: 1200px) {
        .navbar-black .navbar-container .logo {
          padding-left: 18px; } }
      .navbar-black .navbar-container .logo .logo-image {
        width: 115px;
        height: 100px;
        -webkit-transform: scale(0.6);
                transform: scale(0.6);
        -webkit-transition: 0.8s all ease;
        transition: 0.8s all ease; }
    .navbar-black .navbar-container .mobile-icon {
      display: none; }
    .navbar-black .navbar-container .navlist {
      display: flex;
      align-items: center;
      list-style: none;
      text-align: center;
      margin: 5px 0px 0px auto; }
      @media (max-width: 767px) {
        .navbar-black .navbar-container .navlist {
          margin: 5px 8px 0px auto; } }
      .navbar-black .navbar-container .navlist .navitem {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 0 0.7rem;
        height: 100%;
        cursor: pointer;
        height: 80px;
        color: whitesmoke;
        font-family: 'Times New Roman', Times, serif;
        text-transform: uppercase;
        -webkit-transition: auto;
        transition: auto; }
        .navbar-black .navbar-container .navlist .navitem .box-border {
          color: #fff;
          border: 1px solid #fff;
          padding: 0px 5px;
          text-decoration: none; }
        @media (min-width: 992px) {
          .navbar-black .navbar-container .navlist .navitem {
            font-size: 13px;
            padding: 0 0.5rem; } }
        @media (min-width: 1200px) {
          .navbar-black .navbar-container .navlist .navitem {
            font-size: 13px;
            padding: 0 0.5rem; } }
        @media (min-width: 1440px) {
          .navbar-black .navbar-container .navlist .navitem {
            font-size: 16px;
            padding: 0 0.7rem; } }

@media (max-width: 767px) {
  .navigation-main {
    display: block; } }

@media (min-width: 768px) {
  .navigation-main {
    display: block; } }

@media (min-width: 992px) {
  .navigation-main {
    display: block; } }

@media (min-width: 1200px) {
  .navigation-main {
    display: block; } }

@media (max-width: 767px) {
  .navbar-transparent .navbar-container .navigation__nav {
    height: 100%; }
    .navbar-transparent .navbar-container .navigation__nav .logo-image {
      width: 140px; }
  .navbar-transparent .navbar-container .navlist .navigation .navigation__nav .social-burger {
    margin: auto;
    margin-top: 82vh; }
  .navbar-transparent .navbar-container .navlist .navigation .navigation__list {
    width: 100%;
    -webkit-transform: translate(-50%, 22%);
            transform: translate(-50%, 22%); }
  .navbar-transparent .navbar-container .navlist .navigation .navigation__item {
    margin: 0;
    -webkit-transition: auto;
    transition: auto; }
    .navbar-transparent .navbar-container .navlist .navigation .navigation__item ul li {
      padding: 4px 0px; } }
    @media (max-width: 767px) and (min-width: 360px) and (max-width: 376px) {
      .navbar-transparent .navbar-container .navlist .navigation .navigation__item ul li {
        padding: 2px 0px; } }

@media (max-width: 767px) {
      .navbar-transparent .navbar-container .navlist .navigation .navigation__item ul li .atag {
        font-size: 18px; } }
      @media (max-width: 767px) and (min-width: 360px) and (max-width: 376px) {
        .navbar-transparent .navbar-container .navlist .navigation .navigation__item ul li .atag {
          font-size: 14px; } }

@media (max-width: 767px) {
      .navbar-transparent .navbar-container .navlist .navigation .navigation__item ul li .dropdown-menus li {
        font-size: 13px;
        padding: 3px 0px; } }
      @media (max-width: 767px) and (min-width: 360px) and (max-width: 376px) {
        .navbar-transparent .navbar-container .navlist .navigation .navigation__item ul li .dropdown-menus li {
          padding: 2px 0px; } }

@media (max-width: 767px) {
  .navbar-transparent .navbar-container .navlist .navigation .navigation__button {
    z-index: 999;
    position: relative !important; }
  .navbar-black .navbar-container .navigation__nav {
    height: 100%; }
    .navbar-black .navbar-container .navigation__nav .logo-image {
      width: 140px; }
  .navbar-black .navbar-container .navlist .burger-menu-li {
    width: 39px; }
  .navbar-black .navbar-container .navlist .navigation .navigation__nav .social-burger {
    margin: auto;
    margin-top: 82vh; }
  .navbar-black .navbar-container .navlist .navigation .navigation__list {
    width: 100%;
    -webkit-transform: translate(-50%, 22%);
            transform: translate(-50%, 22%); }
  .navbar-black .navbar-container .navlist .navigation .navigation__item {
    margin: 0;
    -webkit-transition: auto;
    transition: auto; }
    .navbar-black .navbar-container .navlist .navigation .navigation__item ul li {
      padding: 4px 0px; } }
    @media (max-width: 767px) and (min-width: 360px) and (max-width: 376px) {
      .navbar-black .navbar-container .navlist .navigation .navigation__item ul li {
        padding: 1px 0px; } }

@media (max-width: 767px) {
      .navbar-black .navbar-container .navlist .navigation .navigation__item ul li .atag {
        font-size: 18px; } }
      @media (max-width: 767px) and (min-width: 360px) and (max-width: 376px) {
        .navbar-black .navbar-container .navlist .navigation .navigation__item ul li .atag {
          font-size: 14px; } }

@media (max-width: 767px) {
      .navbar-black .navbar-container .navlist .navigation .navigation__item ul li .dropdown-menus li {
        font-size: 12px;
        padding: 3px 0px; } }
      @media (max-width: 767px) and (min-width: 360px) and (max-width: 376px) {
        .navbar-black .navbar-container .navlist .navigation .navigation__item ul li .dropdown-menus li {
          padding: 2px 0px; } }

@media (max-width: 767px) {
  .navbar-black .navbar-container .navlist .navigation .navigation__button {
    position: relative !important;
    top: -7px;
    left: 14px; } }

@media (max-width: 767px) {
  .navitem {
    display: none !important; } }

@media (min-width: 768px) {
  .navitem {
    display: none !important; } }

@media (min-width: 992px) {
  .navitem {
    display: none !important; }
  .navbar-transparent .navlist {
    margin: auto 70% !important; } }

@media (min-width: 1200px) {
  .navitem {
    display: flex !important; }
  .navbar-transparent .navlist {
    margin: 5px 40px 0px auto !important; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.navbar-transparent .navigation__checkbox:checked ~ .navigation__button {
  position: fixed; }
  @media (min-width: 768px) {
    .navbar-transparent .navigation__checkbox:checked ~ .navigation__button {
      top: 1rem;
      right: 3rem; } }
  @media (min-width: 992px) {
    .navbar-transparent .navigation__checkbox:checked ~ .navigation__button {
      top: 1.1rem;
      right: 7rem; } }
  @media (min-width: 1200px) {
    .navbar-transparent .navigation__checkbox:checked ~ .navigation__button {
      top: 1.2rem;
      right: 3.2rem; } }
  @media (min-width: 1440px) {
    .navbar-transparent .navigation__checkbox:checked ~ .navigation__button {
      top: 1.2rem;
      right: 3.7rem; } }

.dropdown-menus li:hover {
  color: white !important; }

.navbar-black .navigation__checkbox:checked ~ .navigation__button {
  position: fixed; }
  @media (min-width: 768px) {
    .navbar-black .navigation__checkbox:checked ~ .navigation__button {
      top: 0.5rem;
      right: 1.7rem; } }
  @media (min-width: 992px) {
    .navbar-black .navigation__checkbox:checked ~ .navigation__button {
      top: 0.5rem;
      right: 7rem; } }
  @media (min-width: 1200px) {
    .navbar-black .navigation__checkbox:checked ~ .navigation__button {
      top: 0.6rem;
      right: 4.7rem; } }
  @media (min-width: 1440px) {
    .navbar-black .navigation__checkbox:checked ~ .navigation__button {
      top: 0.5rem;
      right: 5.2rem; } }

.navigation .navigation__nav .logo-image {
  width: 150px;
  height: 140px;
  margin: 0px 25%; }
  @media (min-width: 768px) {
    .navigation .navigation__nav .logo-image {
      position: relative;
      left: 1rem; } }
  @media (min-width: 1440px) {
    .navigation .navigation__nav .logo-image {
      position: relative;
      left: 1.5rem; } }
  @media (min-width: 1200px) {
    .navigation .navigation__nav .logo-image {
      position: relative;
      left: 1.5rem; } }
  @media (min-width: 992px) {
    .navigation .navigation__nav .logo-image {
      position: relative;
      left: 1.5rem; } }

.navigation .burger-menu-icon {
  font-size: 20px;
  position: fixed;
  top: 91%;
  left: 49%;
  list-style: none; }

.navigation .navigation__item ul a {
  text-decoration: none; }

.navigation .navigation__item ul li {
  list-style: none;
  text-transform: uppercase;
  color: #abb8bd;
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 3px;
  font-size: 18px;
  padding: 8px 0px 6px 0;
  -webkit-transition: 0.5s linear;
  transition: 0.5s linear; }
  @media (min-width: 992px) {
    .navigation .navigation__item ul li {
      font-size: 12px;
      padding: 4px 0px 4px 0; } }
  @media (min-width: 1200px) {
    .navigation .navigation__item ul li {
      font-size: 16px;
      padding: 5px 0px 5px 0; } }
  @media (min-width: 1440px) {
    .navigation .navigation__item ul li {
      font-size: 18px;
      padding: 8px 0px 6px 0; } }
  .navigation .navigation__item ul li .atag {
    color: rgba(218, 216, 216, 0.87);
    text-decoration: none; }
  .navigation .navigation__item ul li .atag:hover {
    color: white;
    cursor: pointer; }
  .navigation .navigation__item ul li ul {
    -webkit-transition: visibility 0s linear  0ms, opacity 0ms;
    transition: visibility 0s linear  0ms, opacity 0ms;
    cursor: pointer; }
    .navigation .navigation__item ul li ul li {
      padding: 2px 0px; }
      @media (max-width: 767px) {
        .navigation .navigation__item ul li ul li {
          font-size: 10px; } }
      @media (min-width: 768px) {
        .navigation .navigation__item ul li ul li {
          font-size: 10px; } }
      @media (min-width: 992px) {
        .navigation .navigation__item ul li ul li {
          font-size: 10px; } }
      @media (min-width: 1200px) {
        .navigation .navigation__item ul li ul li {
          font-size: 12px; } }
      @media (min-width: 1440px) {
        .navigation .navigation__item ul li ul li {
          font-size: 14px; } }

.navigation .dropdown-toggle {
  color: unset;
  text-decoration: none; }

.navigation .navigation__checkbox {
  display: none; }

.navigation .navigation__button {
  text-align: center;
  color: white;
  border-radius: 50%;
  z-index: 300;
  cursor: pointer;
  margin-bottom: 0.6rem; }

.navigation .margin-4 {
  margin-top: 3rem !important; }

.navigation .navigation__nav {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  opacity: 0;
  width: 0;
  visibility: hidden;
  z-index: 200;
  -webkit-transition: all 800ms ease-in-out;
  transition: all 800ms ease-in-out;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.navigation .navigation__list {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  text-align: center;
  width: 50%;
  list-style: none; }
  @media (max-width: 767px) {
    .navigation .navigation__list {
      -webkit-transform: translate(-50%, 20%);
              transform: translate(-50%, 20%); } }
  @media (min-width: 768px) {
    .navigation .navigation__list {
      -webkit-transform: translate(-50%, 20%);
              transform: translate(-50%, 20%); } }
  @media (min-width: 992px) {
    .navigation .navigation__list {
      -webkit-transform: translate(-50%, 0%);
              transform: translate(-50%, 0%); } }
  @media (min-width: 1200px) {
    .navigation .navigation__list {
      -webkit-transform: translate(-50%, 25%);
              transform: translate(-50%, 25%); } }

.navigation .navigation__item {
  margin: 0rem 1rem; }

.navigation .navigation__link:link,
.navigation .navigation__link:visited {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: #f4f4f4;
  font-size: 2.4rem;
  text-decoration: none;
  -webkit-transition: all .2s;
  transition: all .2s; }

@media (min-width: 768px) {
  .navigation .navigation__link:link,
  .navigation .navigation__link:visited {
    font-size: 3.8rem; } }

.navigation .navigation__link span {
  margin-right: 1.5rem;
  display: inline-block; }

.navigation .navigation__link:hover {
  color: #C1D0D0;
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.navigation .navigation__checkbox:checked ~ .navigation__nav {
  width: 100%;
  visibility: visible;
  opacity: 1; }

.navigation .navigation__icon {
  position: relative;
  margin-top: 2.5rem; }
  @media (min-width: 992px) {
    .navigation .navigation__icon {
      left: 80px; } }
  @media (min-width: 1200px) {
    .navigation .navigation__icon {
      left: 0; } }

@media screen and (min-width: 768px) {
  .navigation .navigation__icon {
    margin-top: 2rem; } }

@media (min-width: 768px) {
  .navigation .navigation__icon,
  .navigation .navigation__icon::before,
  .navigation .navigation__icon::after {
    width: 1.7rem; } }

.navigation .navigation__icon::before,
.navigation .navigation__icon::after {
  content: '';
  position: absolute;
  left: 0;
  -webkit-transition: all 200ms;
  transition: all 200ms; }

.navigation .navigation__icon::before {
  top: -0.6rem; }

.navigation .navigation__icon::after {
  top: 0.6rem; }

.navigation .navigation__button:hover .navigation__icon::before {
  top: -0.6rem; }

.navigation .navigation__button:hover .navigation__icon::after {
  top: 0.6rem; }

.navigation .navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent; }

.navigation .navigation__checkbox:checked + .navigation__button .navigation__icon::before {
  top: 0;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg); }

.navigation .navigation__checkbox:checked + .navigation__button .navigation__icon::after {
  top: 0;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg); }

.social-burger {
  margin: auto;
  margin-top: 87vh;
  font-size: 18px; }
  @media (min-width: 992px) {
    .social-burger {
      margin-top: 85vh; } }
  @media (min-width: 1200px) {
    .social-burger {
      margin-top: 85vh; } }
  @media (min-width: 1440px) {
    .social-burger {
      margin-top: 87vh; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.personal-trainers-profile {
  background: #eae3de;
  padding-bottom: 3rem;
  padding-top: 2.5rem; }
  @media (max-width: 767px) {
    .personal-trainers-profile {
      background-color: white; } }
  @media (max-width: 767px) {
    .personal-trainers-profile .mobile-p-0-and-m-0 {
      padding: 0px !important;
      margin: 0px  !important; } }
  .personal-trainers-profile .left-sub-parent .location-profile {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 600; }
  @media (max-width: 767px) {
    .personal-trainers-profile .left-sub-parent {
      text-align: center; }
      .personal-trainers-profile .left-sub-parent .mobile-view {
        padding: 0px 0px 64px 0px; }
        .personal-trainers-profile .left-sub-parent .mobile-view .club-location {
          display: block;
          margin: 0px;
          padding: 0px !important; }
        .personal-trainers-profile .left-sub-parent .mobile-view .email-address {
          display: block; } }
  @media (min-width: 1200px) {
    .personal-trainers-profile .left-sub-parent .mobile-view {
      display: none; } }
  @media (min-width: 768px) {
    .personal-trainers-profile .left-sub-parent .mobile-view {
      display: none; } }
  .personal-trainers-profile .left-sub-parent .profile-name {
    font-family: 'Times New Roman', Times, serif;
    text-transform: uppercase;
    font-size: 24px;
    letter-spacing: 3px;
    padding: 10px 0px;
    color: #0E2538; }
    @media (max-width: 767px) {
      .personal-trainers-profile .left-sub-parent .profile-name {
        padding: 10px 29%;
        font-size: 18px;
        letter-spacing: 1px; } }
  .personal-trainers-profile .left-sub-parent .club-location {
    font-weight: 700;
    color: #0E2538;
    font-size: 14px;
    padding: 0;
    margin: 0; }
    @media (max-width: 767px) {
      .personal-trainers-profile .left-sub-parent .club-location {
        display: none; } }
  .personal-trainers-profile .left-sub-parent .email-address {
    font-weight: 700;
    font-size: 14px;
    color: #adb9be; }
    @media (max-width: 767px) {
      .personal-trainers-profile .left-sub-parent .email-address {
        display: none; } }
  .personal-trainers-profile .right-sub-parent {
    padding-top: 2.6rem;
    margin-top: 3rem;
    padding-bottom: 3rem; }
  @media (max-width: 767px) {
    .personal-trainers-profile .profile-paragraph-adjust {
      padding: 38px 0px 0px 0px; } }
  .personal-trainers-profile .profile-paragraph-adjust .profile-heading {
    font-family: 'Times New Roman', Times, serif;
    text-transform: uppercase;
    padding: 0px;
    margin: 0px;
    letter-spacing: 1px;
    font-size: 16px; }
    @media (max-width: 767px) {
      .personal-trainers-profile .profile-paragraph-adjust .profile-heading {
        text-align: center; } }
  @media (max-width: 767px) {
    .personal-trainers-profile .profile-paragraph-adjust .qualification-margin-adjust {
      margin: 30px 0px; } }
  .personal-trainers-profile .profile-paragraph-adjust .profile-paragraph {
    font-size: 14px;
    color: #0E2538;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    padding: 0px;
    margin: 0px;
    padding: 4px 0px;
    font-size: 14px; }
    .personal-trainers-profile .profile-paragraph-adjust .profile-paragraph ul {
      padding: 4px 16px; }
      .personal-trainers-profile .profile-paragraph-adjust .profile-paragraph ul li {
        padding: 2px 0px; }
        @media (max-width: 767px) {
          .personal-trainers-profile .profile-paragraph-adjust .profile-paragraph ul li {
            list-style-position: inside; } }
  @media (max-width: 767px) {
    .personal-trainers-profile .profile-paragraph-adjust .profile-paragraph {
      text-align: center;
      padding: 2px 21px; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.testimonial-main {
  padding: 50px 0px; }
  .testimonial-main .slick-prev {
    left: -31px !important; }
  .testimonial-main .slick-next {
    right: -31px !important; }
  @media (min-width: 992px) {
    .testimonial-main .mobile-view {
      display: none; }
    .testimonial-main .desktop-view {
      display: block; } }
  @media (min-width: 1440px) {
    .testimonial-main .mobile-view {
      display: none; }
    .testimonial-main .desktop-view {
      display: block; } }
  @media (min-width: 1800px) {
    .testimonial-main .mobile-view {
      display: none; }
    .testimonial-main .desktop-view {
      display: block; } }
  @media (max-width: 767px) {
    .testimonial-main .mobile-view {
      display: block; }
    .testimonial-main .desktop-view {
      display: none; } }
  @media (min-width: 768px) {
    .testimonial-main .testimonial-overview {
      zoom: 0.9; } }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .testimonial-main .testimonial-overview .overview-paragraph {
      width: 160%; }
    .testimonial-main .testimonial-overview .testimonial-stage1-title {
      height: 252px; }
    .testimonial-main .testimonial-overview .overview-paragraph .border-pad .paragraph {
      font-size: 14px; }
    .testimonial-main .testimonial-overview .overview-paragraph {
      position: relative;
      top: 84px; } }
  @media screen and (min-width: 992px) and (max-width: 1150px) {
    .testimonial-main .testimonial-overview-paragraph {
      max-width: 45%; } }

.testimonial-overview .background-img {
  width: 590px;
  height: 570px;
  position: relative;
  right: 5px;
  background-repeat: no-repeat;
  background-position: left;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  background-size: cover; }

.testimonial-overview .banner-img {
  position: relative;
  max-width: 90%; }

.testimonial-overview .testimonial-stage1-title {
  color: #d6b8b5;
  font-family: 'Times New Roman', Times, serif;
  background: #eef1f2;
  position: absolute;
  left: -39px;
  top: 50px;
  height: 298px; }
  .testimonial-overview .testimonial-stage1-title .testimonial-main-title {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    font-size: 50px;
    position: relative;
    top: 220px;
    left: -154px;
    letter-spacing: 4px;
    z-index: 9; }
    @media (min-width: 768px) {
      .testimonial-overview .testimonial-stage1-title .testimonial-main-title {
        top: 93px;
        left: -103px;
        font-size: 36px; } }
    @media (min-width: 1200px) {
      .testimonial-overview .testimonial-stage1-title .testimonial-main-title {
        top: 238px;
        font-size: 50px;
        left: -161px; } }

.testimonial-overview .overview-paragraph {
  position: relative;
  top: 110px;
  right: 145px;
  width: 137%;
  background: white;
  padding: 14px 14px; }
  .testimonial-overview .overview-paragraph .border-pad {
    padding: 7px 11px;
    border: 1px solid #ABB8BD;
    padding: 25px 35px 0px 35px; }
    .testimonial-overview .overview-paragraph .border-pad .arrow-settings .left-arrow {
      width: 28px;
      cursor: pointer; }
    .testimonial-overview .overview-paragraph .border-pad .arrow-settings .right-arrow {
      width: 28px;
      cursor: pointer; }
    .testimonial-overview .overview-paragraph .border-pad .paragraph {
      height: auto;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      z-index: 99;
      max-height: 120px;
      padding: 0px 12px 0px 0px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      letter-spacing: 1px; }
      .testimonial-overview .overview-paragraph .border-pad .paragraph::-webkit-scrollbar {
        width: 5px; }
      .testimonial-overview .overview-paragraph .border-pad .paragraph::-webkit-scrollbar-track {
        background: #f1f1f1;
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px; }
      .testimonial-overview .overview-paragraph .border-pad .paragraph::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px; }
      @media (min-width: 992px) {
        .testimonial-overview .overview-paragraph .border-pad .paragraph {
          font-size: 12px; } }
      @media (min-width: 1200px) {
        .testimonial-overview .overview-paragraph .border-pad .paragraph {
          font-size: 14px; } }
    .testimonial-overview .overview-paragraph .border-pad .member-title {
      font-family: 'Times New Roman', Times, serif;
      text-transform: uppercase;
      letter-spacing: 2px; }
      @media (min-width: 992px) {
        .testimonial-overview .overview-paragraph .border-pad .member-title {
          font-size: 1.3em; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
@media (max-width: 767px) {
  .testimonial-main {
    padding: 0px 0px 70px 0px; }
    .testimonial-main .mobile-offer-card-wrapper {
      padding: 0px;
      background-color: #fff;
      font-size: 14px; }
      .testimonial-main .mobile-offer-card-wrapper .offer-title {
        color: #abb8bd;
        font-size: 16px;
        letter-spacing: 1px; }
      .testimonial-main .mobile-offer-card-wrapper .offer-description {
        padding: 20px !important; }
      .testimonial-main .mobile-offer-card-wrapper .slick-prev {
        left: -10px !important; }
        .testimonial-main .mobile-offer-card-wrapper .slick-prev img {
          left: 24px;
          position: relative; }
      .testimonial-main .mobile-offer-card-wrapper .slick-next {
        right: -10px !important; }
        .testimonial-main .mobile-offer-card-wrapper .slick-next img {
          right: 24px;
          position: relative; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.personal-trainer-main-profile {
  padding: 50px 35px 30px 35px; }
  .personal-trainer-main-profile .personal-trainer {
    padding: 32px 0px 0px 0px; }
    .personal-trainer-main-profile .personal-trainer .title {
      color: #abb8bd;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 28px;
      text-align: center;
      font-family: 'Times New Roman', Times, serif; }
    .personal-trainer-main-profile .personal-trainer .filters .filter-label {
      font-size: 14px; }
    .personal-trainer-main-profile .personal-trainer .filters .mobile-filter-adjust {
      margin: 0px 10px; }
    .personal-trainer-main-profile .personal-trainer .filters span {
      color: #0E2538;
      font-weight: 600; }
    .personal-trainer-main-profile .personal-trainer .filters select {
      display: inline;
      width: 33%;
      height: 24px;
      padding: 0px 7px;
      text-transform: uppercase;
      font-size: 0.9rem;
      font-weight: 400;
      box-shadow: none;
      color: #abb8bd; }

@media (max-width: 767px) {
  .personal-slider-main-location {
    padding-top: 1.8rem !important; } }

@media (min-width: 768px) {
  .personal-slider-main-location {
    padding: 2rem 0rem 2rem 0rem !important; } }

@media (min-width: 992px) {
  .personal-slider-main-location {
    padding: 0rem 2rem 0rem 2rem !important; } }

@media (min-width: 1200px) {
  .personal-slider-main-location {
    padding: 0rem 4rem 0rem 4rem !important; } }

@media (min-width: 1440px) {
  .personal-slider-main-location {
    padding: 0rem 4rem 0rem 4rem !important; } }

.personal-slider-main-location .slick-prev {
  left: -30px;
  top: 35%; }
  @media (max-width: 767px) {
    .personal-slider-main-location .slick-prev {
      left: -30px; } }
  .personal-slider-main-location .slick-prev img {
    width: 30px; }

.personal-slider-main-location .slick-next {
  top: 35%;
  right: -30px; }
  @media (max-width: 767px) {
    .personal-slider-main-location .slick-next {
      right: -30px; } }
  .personal-slider-main-location .slick-next img {
    width: 30px;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1); }

.personal-slider-main-location .personal-slider .outline-none {
  outline: none; }

@media (min-width: 992px) {
  .personal-slider-main-location .personal-slider {
    padding: 3.5em 1.5em; } }

@media (min-width: 1200px) {
  .personal-slider-main-location .personal-slider {
    padding: 2.3em 1em 3.5em 1em; } }

@media (min-width: 1440px) {
  .personal-slider-main-location .personal-slider {
    padding: 2.8em 2.5em 3.5em 2.5em; } }

.personal-slider-main-location .personal-slider img {
  height: 240px;
  width: 340px;
  object-fit: cover; }
  @media (min-width: 360px) and (max-width: 376px) {
    .personal-slider-main-location .personal-slider img {
      height: 180px; } }
  @media (max-width: 767px) {
    .personal-slider-main-location .personal-slider img {
      height: 200px; } }
  @media (min-width: 768px) {
    .personal-slider-main-location .personal-slider img {
      height: 340px; } }

.personal-slider-main-location .profile .title {
  font-weight: 600;
  text-align: center;
  padding: 20px 0px 0px 0px;
  margin: 0;
  font-size: 15px;
  font-family: Arial;
  color: #0E2538; }
  @media (max-width: 767px) {
    .personal-slider-main-location .profile .title {
      height: 40px;
      font-size: 12px; } }
  @media (min-width: 360px) and (max-width: 376px) {
    .personal-slider-main-location .profile .title {
      height: 58px; } }

.personal-slider-main-location .profile .description {
  text-align: center;
  font-family: Arial;
  color: #0E2538;
  font-size: 14px; }
  @media (max-width: 767px) {
    .personal-slider-main-location .profile .description {
      height: 25px;
      font-size: 12px;
      margin: 0; } }

.personal-slider-main-location .profile .Learn-more {
  font-weight: 600;
  color: #abb8bd;
  text-decoration: underline;
  text-align: center;
  font-size: 12px; }
  @media (max-width: 767px) {
    .personal-slider-main-location .profile .Learn-more {
      font-size: 12px; } }
  @media (min-width: 768px) {
    .personal-slider-main-location .profile .Learn-more {
      font-size: 14px; } }

@media screen and (min-width: 300px) and (max-width: 1100px) {
  .personal-slider-main-location .personal-slider {
    padding: 0.5em; } }

@media screen and (min-width: 300px) and (max-width: 1100px) and (min-width: 992px) {
  .personal-slider-main-location .personal-slider {
    padding: 4em 2em; } }

@media screen and (min-width: 300px) and (max-width: 821px) {
  .personal-trainer-main-profile {
    padding: 55px 35px 30px 35px !important; }
  .personal-trainer .title {
    font-size: 18px !important; }
  .personal-trainer .filter-label {
    font-size: 14px !important; }
  .mobile-filter-adjust {
    text-align: center;
    padding: 0px 0px 34px 0px; }
    .mobile-filter-adjust select {
      margin: 15px 0px 35px 0px !important;
      width: 35% !important;
      height: 24px !important; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.personal-trainer-main-home {
  padding: 5em 35px 0px 35px; }
  .personal-trainer-main-home .personal-trainer {
    padding: 6px 0px; }
    .personal-trainer-main-home .personal-trainer .title {
      color: #abb8bd;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 28px;
      text-align: center;
      font-family: 'Times New Roman', Times, serif; }
    .personal-trainer-main-home .personal-trainer .filters span {
      color: #0E2538;
      font-weight: 600; }
    .personal-trainer-main-home .personal-trainer .filters .mobile-filter-adjust {
      margin: 0px 10px; }
    .personal-trainer-main-home .personal-trainer .filters select {
      display: inline;
      width: 33%;
      height: 24px;
      font-size: 12px;
      padding: 0px 7px;
      text-transform: uppercase;
      font-size: 0.9rem;
      font-weight: 400;
      box-shadow: none;
      color: #abb8bd; }

@media (max-width: 767px) {
  .personal-slider-main-home {
    padding: 1rem 0rem 1rem 0rem; } }

@media (min-width: 768px) {
  .personal-slider-main-home {
    padding: 0px; } }

@media (min-width: 992px) {
  .personal-slider-main-home {
    padding: 1.5rem 1rem 2rem 1rem; } }

@media (min-width: 1200px) {
  .personal-slider-main-home {
    padding: 2rem 4rem 2rem 4rem; } }

@media (min-width: 768px) {
  .personal-slider-main-home .padding-adjust {
    margin: 0rem 0rem 0.4rem 0rem; } }

@media (min-width: 1200px) {
  .personal-slider-main-home .padding-adjust {
    margin: 0rem 0rem 0.4rem 0rem; } }

@media (min-width: 992px) {
  .personal-slider-main-home .padding-adjust {
    margin: 0rem 0rem 1.5rem 0rem; } }

@media (max-width: 767px) {
  .personal-slider-main-home .padding-adjust {
    padding-left: 0px;
    margin: 5px 0px; }
    .personal-slider-main-home .padding-adjust .Learn-more {
      text-decoration: none !important;
      font-size: 14px;
      padding: 0px 5px; } }

.personal-slider-main-home .card {
  border: none;
  border-radius: 0px; }

.personal-slider-main-home .slick-prev {
  left: -31px !important;
  top: 44% !important; }
  .personal-slider-main-home .slick-prev img {
    width: 35px; }

.personal-slider-main-home .slick-next {
  right: -31px !important;
  top: 44% !important; }
  .personal-slider-main-home .slick-next img {
    width: 35px;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1); }

.personal-slider-main-home .personal-slider .outline-none {
  outline: none; }

@media (min-width: 1200px) {
  .personal-slider-main-home .personal-slider {
    padding: 1em; } }

@media (min-width: 1440px) {
  .personal-slider-main-home .personal-slider {
    padding: 2em; } }

.personal-slider-main-home .personal-slider .profile-bg-image {
  width: 100%;
  height: 325px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  background-size: cover; }
  @media (max-width: 767px) {
    .personal-slider-main-home .personal-slider .profile-bg-image {
      height: 221px; } }

.personal-slider-main-home .profile .title {
  font-weight: 600;
  text-align: center;
  padding: 22px 0px 0px 0px;
  margin: 0;
  font-size: 16px;
  font-family: Arial;
  color: #0E2538; }
  @media (max-width: 767px) {
    .personal-slider-main-home .profile .title {
      height: 70px;
      color: #0E2538; } }

.personal-slider-main-home .profile .description {
  text-align: center;
  font-family: Arial;
  color: #0E2538;
  font-size: 15px; }

.personal-slider-main-home .profile .Learn-more-head .Learn-more {
  font-weight: 600;
  color: #abb8bd;
  text-decoration: none;
  text-align: center; }

@media screen and (min-width: 300px) and (max-width: 1100px) {
  .personal-slider-main-home .personal-slider {
    padding: 0.5em 0 2em 13px; } }

@media screen and (min-width: 300px) and (max-width: 767px) {
  .personal-trainer .title {
    font-size: 18px !important; }
  .personal-trainer .filter-label {
    font-size: 14px !important; }
  .mobile-filter-adjust {
    text-align: center;
    margin: 13px 0px 20px 0px;
    padding: 0px 0px 20px 0px; } }

.sitemap-container {
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial";
  margin-top: 80px; }
  .sitemap-container a {
    color: #06c;
    text-decoration: none; }
  .sitemap-container .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 20px; }
  .sitemap-container .grid-item {
    /*border: 1px solid rgba(0, 0, 0, 0.8);*/
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    text-align: left; }
  .sitemap-container .title-container {
    display: flex;
    justify-content: left;
    border-bottom: 1px solid grey;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 30px; }
  .sitemap-container .title {
    color: black;
    font-size: 20px;
    padding-bottom: 20px;
    text-decoration: none; }
  .sitemap-container .list-wrapper {
    list-style: none;
    text-align: left;
    padding: 0px; }
  .sitemap-container .list-indiviual {
    padding: 0px 17px 7px 0px; }
  .sitemap-container .list-indiviual:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline; }
  .sitemap-container .list-title {
    color: black;
    /* padding: 0px 17px 0px 7px; */
    font-size: 20px;
    text-decoration: none; }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.timetable-main {
  background-color: #10243c;
  background-size: cover;
  background-position: center center;
  height: 140px; }
  @media (max-width: 767px) {
    .timetable-main .timetable-desktop-section {
      display: none; } }
  @media (min-width: 768px) {
    .timetable-main {
      height: 260px; } }
  @media (min-width: 1200px) {
    .timetable-main {
      height: 260px; } }
  .timetable-main .mobile-timetable {
    text-transform: uppercase;
    color: #abb8bd;
    position: relative;
    text-align: center;
    top: 33%;
    font-size: 24px;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 3px; }
    @media (min-width: 1200px) {
      .timetable-main .mobile-timetable {
        display: none; } }
    @media (min-width: 768px) {
      .timetable-main .mobile-timetable {
        display: none; } }
  .timetable-main .timetable-desktop-section {
    color: #abb8bd;
    text-align: center; }
    .timetable-main .timetable-desktop-section svg {
      font-size: 60px;
      padding: 10px 0px;
      margin: 10x 0px; }
    .timetable-main .timetable-desktop-section .title {
      font-family: 'Times New Roman', Times, serif;
      letter-spacing: 3px;
      padding: 30px 0px 10px; }
    .timetable-main .timetable-desktop-section .timetable-icon {
      height: 68px;
      width: 85px;
      margin-bottom: 20px; }
    .timetable-main .timetable-desktop-section .first-p {
      font-family: Arial, Helvetica, sans-serif; }
    .timetable-main .timetable-desktop-section .second-p {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 600;
      color: #abb8bd;
      text-decoration: none; }

